import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';

import { BackDrop, CardModal, HeaderBar, CardName, MainBody } from './EditCardModal.styled';
import { ButtonsContainer, Button1, Button2 } from '../../../../../../../assets/buttons';

const EditCardModal = ({ onCloseModal, title, children, handleSubmit }) => {
  return (
    <Fragment>
      {createPortal(<BackDrop onClick={onCloseModal}></BackDrop>, document.getElementById('backdrop-root'))}
      <CardModal>
        <HeaderBar>
          <CardName>{title}</CardName>
          <ButtonsContainer>
            <Button2 onClick={onCloseModal}>Cancel</Button2>
            <Button1 type="submit" onClick={handleSubmit}>
              Save
            </Button1>
          </ButtonsContainer>
        </HeaderBar>
        <MainBody>{children}</MainBody>
      </CardModal>
    </Fragment>
  );
};

export default EditCardModal;
