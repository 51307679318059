import React from 'react';
import moment from 'moment/moment';

import { PrintFooterContainer, PoweredByContainer, DatePrintedContaier } from './Footer.styled';

function Footer() {
  return (
    <PrintFooterContainer>
      <PoweredByContainer>Powered By: ClinicApp.online </PoweredByContainer>
      <DatePrintedContaier>{`Date and time printed: ${moment().format('MMM DD, yyyy h:mm:ss a')}`}</DatePrintedContaier>
    </PrintFooterContainer>
  );
}

export default Footer;
