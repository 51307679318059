import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PatientPaginatedBarContainer, PaginationTextContainer, PaginationLimitContainer, PaginationText, PaginationClickableText, PaginationSelectLimit } from './PatientPaginatedBar.styled';

const PatientPaginatedBar = ({ page, limit, setPage, setLimit }) => {
  const [editLimit, setEditLimit] = useState(false);
  const [pageArray, setPageArray] = useState([]);
  const numberOfPages = useSelector((state) => state.patient && state.patient.numberOfPages);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < numberOfPages; i++) {
      array.push(i + 1);
    }
    setPageArray(array);
  }, [numberOfPages]);

  return (
    <PatientPaginatedBarContainer>
      <PaginationTextContainer>
        <PaginationText> Page: </PaginationText>
        {pageArray &&
          pageArray.map((value, index) => {
            return (
              <PaginationClickableText key={index} onClick={() => setPage(value)} active={value === page}>
                {value}
              </PaginationClickableText>
            );
          })}
      </PaginationTextContainer>
      <PaginationLimitContainer>
        <PaginationText>Limit:</PaginationText>
        {editLimit ? (
          <PaginationSelectLimit
            value={limit}
            onChange={(e) => {
              setEditLimit(false);
              setLimit(e.target.value);
              setPage(1);
            }}
          >
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </PaginationSelectLimit>
        ) : (
          <PaginationClickableText active={true} onClick={() => setEditLimit(true)}>
            {limit}
          </PaginationClickableText>
        )}
      </PaginationLimitContainer>
    </PatientPaginatedBarContainer>
  );
};

export default PatientPaginatedBar;
