import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPatientById } from '../../../../store/storeSlices/patients';

import { ViewPatientContainer } from './ViewPatient.styled';

import ViewPatientData from './ViewPatientComponents/ViewPatientData';
import EditPatientData from './ViewPatientComponents/EditPatientData';
import { ModalLoader } from '../../../../assets/loader';

const ViewPatient = ({ patientId, onCloseModal, isNew, onViewPatient, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const patientData = useSelector((state) => state.patient.patientData);
  const getPatientStatus = useSelector((state) => state.patient.getPatientStatus);

  useEffect(() => {
    !isNew && dispatch(getPatientById({ method: 'get', url: `patient/${patientId}`, token }));
  }, [patientId, dispatch, token, isNew]);

  return (
    <ViewPatientContainer>
      {getPatientStatus === 'loading' ? (
        <ModalLoader />
      ) : isEdit || isNew ? (
        <EditPatientData patientData={isNew ? {} : patientData} onCloseModal={onCloseModal} onViewPatient={onViewPatient} setIsEdit={setIsEdit} patientId={patientId} isEdit={isEdit} isNew={isNew} />
      ) : (
        <ViewPatientData patientData={patientData} onCloseModal={onCloseModal} setIsEdit={setIsEdit} />
      )}
    </ViewPatientContainer>
  );
};

export default ViewPatient;
