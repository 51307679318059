import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

import { PatientInfoContainer, PatientInfoRow, PatientDetails } from './PatientInfo.styled';

function PatientsInfo() {
  const patientData = useSelector((state) => state.patient.patientData.personalInfo);
  const date = useSelector((state) => state.chart.chartData.appointmentDate);
  let name = patientData ? (patientData.middleName ? (patientData.firstName + ' ' + patientData.middleName + ' ' + patientData.lastName).toUpperCase() : (patientData.firstName + ' ' + patientData.lastName).toUpperCase()) : '';

  const getAge = (dob) => {
    let dateNow = moment();
    let dateOfBirth = moment(dob);
    let years = moment.duration(dateNow.diff(dateOfBirth)).years();
    let months = moment.duration(dateNow.diff(dateOfBirth)).months();
    let days = moment.duration(dateNow.diff(dateOfBirth)).days();
    return `${years} years, ${months} months, ${days} days`;
  };

  return (
    <PatientInfoContainer>
      <PatientInfoRow>
        <PatientDetails>
          {`Name: `} <b>{name}</b>
        </PatientDetails>
        <PatientDetails>{`Date: ${moment(date).format('MMM DD, yyyy')}`}</PatientDetails>
      </PatientInfoRow>
      <PatientInfoRow>
        <PatientDetails>{`Date of Birth: ${moment(patientData && patientData.dateOfBirth).format('MMM DD, yyyy')}`}</PatientDetails>
        <PatientDetails>{`Age: ${getAge(patientData && patientData.dateOfBirth)}`}</PatientDetails>
        <PatientDetails>{`Sex: ${patientData && patientData.sex}`}</PatientDetails>
      </PatientInfoRow>
    </PatientInfoContainer>
  );
}

export default PatientsInfo;
