import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { CardContainer } from '../CardResources/Card.styled';
import { CardHeaderBarContainer, TextContainer, TitleText } from '../CardResources/CardHeaderBar.styled';
import { HideCardIcon, IconsContainer, UnhideCardIcon } from '../../../../../../../assets/icons';
import { CardBodyContainer, PersonalProfileContainer, ImageContainer, ProfileImage, PatientName, ChartIdContainer } from './PatientCard.styled';
import { CardDataContainer, CardDataRow, PersonalInfoContainer, PersonalInfo } from './PatientCard.styled';

import SelectDoctorList from '../../../../ChartResources/SelectDoctorList';
import ChartListStatus from '../../../../ChartResources/ChartListStatus';
import AppointmentDate from '../../../../ChartResources/AppointmentDate';

const PatientCard = ({ cardData }) => {
  const [isHidden, setIsHidden] = useState(false);
  const patientData = useSelector((state) => state.patient.patientData);
  const chartStatus = useSelector((state) => state.dataList && state.dataList.chartStatus);

  const getAge = (dob) => {
    let dateNow = moment();
    let dateOfBirth = moment(dob);
    let years = moment.duration(dateNow.diff(dateOfBirth)).years();
    let months = moment.duration(dateNow.diff(dateOfBirth)).months();
    let days = moment.duration(dateNow.diff(dateOfBirth)).days();
    return `${years} years, ${months} months, ${days} days`;
  };

  return (
    <CardContainer>
      <CardHeaderBarContainer>
        <TextContainer>
          <TitleText>Patient's Data</TitleText>
        </TextContainer>
        <IconsContainer>
          {isHidden ? (
            <div onClick={() => setIsHidden(false)} title={'show'}>
              <UnhideCardIcon />
            </div>
          ) : (
            <>
              <div onClick={() => setIsHidden(true)} title={'hide'}>
                <HideCardIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </CardHeaderBarContainer>
      <CardBodyContainer isHidden={isHidden}>
        <PersonalProfileContainer>
          <ImageContainer>
            <ProfileImage src={patientData.personalInfo && patientData.personalInfo.image} />
            <PatientName>
              {patientData && patientData.personalInfo && patientData.personalInfo.firstName && patientData.personalInfo.firstName}{' '}
              {patientData && patientData.personalInfo && patientData.personalInfo.middleName && patientData.personalInfo.middleName}{' '}
              {patientData && patientData.personalInfo && patientData.personalInfo.lastName && patientData.personalInfo.lastName && patientData.personalInfo.lastName}
            </PatientName>
          </ImageContainer>
          <PersonalInfoContainer>
            <PersonalInfo>
              <b>AGE: </b> {patientData && patientData.personalInfo && patientData.personalInfo.dateOfBirth && getAge(patientData.personalInfo.dateOfBirth)}
            </PersonalInfo>
            <PersonalInfo>
              <b>SEX: </b> {patientData && patientData.personalInfo && patientData.personalInfo.sex && patientData.personalInfo.sex}
            </PersonalInfo>
            <PersonalInfo>
              <b>CIVIL STATUS: </b> {patientData && patientData.personalInfo && patientData.personalInfo.civilStatus && patientData.personalInfo.civilStatus}
            </PersonalInfo>
          </PersonalInfoContainer>
        </PersonalProfileContainer>
        <CardDataContainer>
          <CardDataRow>
            <b>CHART ID: </b>
            <ChartIdContainer>{cardData && cardData._id}</ChartIdContainer>
          </CardDataRow>
          <CardDataRow>
            <b>DATE: </b>
            <AppointmentDate chart={cardData} />
          </CardDataRow>
          <CardDataRow>
            <b>STATUS: </b>
            <ChartListStatus chartStatus={chartStatus} chart={cardData} removeBar={true} />
          </CardDataRow>
          <CardDataRow>
            <b>DOCTOR: </b> <SelectDoctorList chart={cardData} removeBar={true} />
          </CardDataRow>
        </CardDataContainer>
      </CardBodyContainer>
    </CardContainer>
  );
};

export default PatientCard;
