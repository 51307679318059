import styled from 'styled-components/macro';

export const DiagnosisDataContainer = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin: 5px 0px;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  height: auto;
  justify-content: space-between;
  box-shadow: 1px 1px 3px #ccc;
  border-radius: 2px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InlineInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const InlineInput = styled.input`
  margin: 5px;
  padding: 10px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 2px;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 40px;
`;

export const InlineMultiLineInput = styled.textarea`
  margin: 2px;
  padding: 10px;
  width: 600px;
`;

export const InlineSelect = styled.select`
  margin: 5px;
  padding: 10px;
  height: 40px;
  width: 150px;
  border: 1px solid gray;
  border-radius: 2px;
`;

export const InfoData = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  border: ${(props) => (props.border ? props.border : `1px solid ${props.theme.CardBorder}`)};
  background-color: white;
  min-height: 24px;
  padding-left: 10px;
  font-size: 0.8rem;
  white-space: pre-wrap;
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }
`;

export const DiagnosisListContainer = styled.div`
  padding: 10px 15px;
`;

export const DiagnosisAddDataContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  border-radius: 2px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const AddOthersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & div {
    display: flex;
    flex-direction: row;
    padding: 5 px;

    & div {
      padding: 10px;
    }
  }
`;

export const ChartCardInputContainer = styled.div`
  background-color: ${(props) => (props.background ? props.background : props.theme.CardBackground)};
  margin: 2px;
  padding: 5px;
  width: ${(props) => (props.width ? props.width : '24%')};
  display: ${(props) => (props.display ? props.display : 'block')};
  box-shadow: 1px 1px 3px #ccc;
  border: 1px solid ${({ theme }) => theme.CardBorder};

  @media (max-width: 576px) {
    width: 100%;
    overflow-x: scroll;
  }
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.8rem')};
  padding: ${(props) => (props.padding ? props.padding : '2px')};
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding-left: 6px;
  font-size: 1rem;
`;

export const DiagnosisContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const SelectInputDiagnosis = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px 3px;
  font-size: 1rem;
  margin-right: 3px;
  width: 600px;
`;
