import styled from 'styled-components/macro';

export const ChartDataContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: calc(100vw - 400px);
  flex-direction: column;
  background-color: beige;
  border: 1px solid beige;
  border-top: none;

  @media (max-width: 1100px) {
    width: 85%;
  }
`;

export const ChartDataHeader = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  background-color: ${({ theme }) => theme.CardHeader};
  border-bottom: 1px solid ${({ theme }) => theme.CardHeader};
`;

export const ChartDataBody = styled.div`
  padding: 10px;
  overflow-y: auto;
`;
