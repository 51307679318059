import styled from 'styled-components/macro';

export const PatientInfoBarLabel = styled.label`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 4px 0px 4px 8px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  font-weight: bold;

  /* @media (max-width: 1195px) {
    display: ${(props) => (props.label === 'DATE' || props.label === 'TIMEIN' || props.label === 'IMAGE') && 'none'};
  }

  @media (max-width: 1050px) {
    display: ${(props) => props.label === 'DOCTOR' && 'none'};
  }

  @media (max-width: 800px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
  }

  @media (max-width: 630px) {
    display: ${(props) => props.label === 'SEX' && 'none'};
  }

  @media (max-width: 550px) {
    display: ${(props) => props.label === 'STATUS' && 'none'};
  } */
`;
