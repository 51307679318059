import styled from 'styled-components/macro';

export const ChartActionsContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.CardBorder};

  @media (max-width: 1100px) {
    width: 15%;
  }
`;

export const ChartActionsHeader = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  background-color: ${({ theme }) => theme.CardHeader};
`;

export const ChartActionsScrollableContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActionListContainer = styled.div`
  padding: 4px;
  width: 100%;
  min-height: 80px;
`;

export const ActionList = styled.div`
  text-align: left;
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  padding: 10px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;
