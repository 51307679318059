import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { rescheduleChart } from '../../../../store/storeSlices/charts';

import { ChartInfoLabelEditable, DateInput, ChartInfoLabelEditableText, IconsContainer, IconContainer } from './AppointmentDate.styled';
import { CancelIcon, SaveIcon } from '../../../../assets/icons';

const AppointmentDate = ({ chart }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const [isEdit, setIsEdit] = useState(false);
  const [newAppointmentDate, setNewAppointmentDate] = useState(chart.appointmentDate);

  const onSaveHandler = (e) => {
    dispatch(rescheduleChart({ method: 'patch', url: `chart/reschedule/${chart._id}`, token: token, data: { AppointmentDate: newAppointmentDate } }));
  };

  return (
    <ChartInfoLabelEditable>
      {isEdit ? (
        <DateInput type={'date'} value={newAppointmentDate} onChange={(e) => setNewAppointmentDate(e.target.value)} />
      ) : (
        <ChartInfoLabelEditableText onClick={() => setIsEdit(true)}>{moment(chart.appointmentDate).format('MMM DD, yyyy')}</ChartInfoLabelEditableText>
      )}
      {isEdit && (
        <IconsContainer>
          <IconContainer onClick={() => setIsEdit(false)}>
            <CancelIcon />
          </IconContainer>
          <IconContainer onClick={onSaveHandler}>
            <SaveIcon />
          </IconContainer>
        </IconsContainer>
      )}
    </ChartInfoLabelEditable>
  );
};

export default AppointmentDate;
