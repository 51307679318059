import styled from 'styled-components/macro';

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: relative;
`;

export const TopNav = styled.div`
  cursor: pointer;
  padding: 10px 10px 5px 10px;
  border-bottom: 5px solid ${(props) => (props.active ? 'orange' : props.theme.TopBar)};
  margin-right: 10px;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.CardFontLight};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: orange;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

export const TopNavMobile = styled.div`
  display: none;
  cursor: pointer;
  padding: 0px 3px 5px 8px;
  border-bottom: 5px solid ${(props) => (props.active ? 'orange' : props.theme.TopBar)};
  font-size: 0.9rem;
  color: ${({ theme }) => theme.CardFontLight};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: orange;
  }

  @media (max-width: 576px) {
    display: block;
  }
`;
