import styled from 'styled-components/macro';

export const MainHeader = styled.header`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.TopBar};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  height: 50px;
  margin-bottom: 5px;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 20;
`;

export const Container = styled.div`
  width: 1400px;
  display: flex;
  justify-content: space-between;
  padding: 5px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: relative;
`;

export const UserName = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  color: ${({ theme }) => theme.HeaderFont};
  font-weight: bold;
  border-color: transparent;
  border-radius: 0px;
  font-size: 1rem;
  padding: 7px 15px 3px 15px;
  margin: 3px;
  margin-left: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const AvatarContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin: 0px 5px;
  overflow: hidden;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const NoAvatar = styled.div`
  color: ${({ theme }) => theme.FontColor};
  padding-top: 4px;
  padding-left: 0px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

export const MainNavContainer = styled.nav`
  display: block;
  width: 200px;
  overflow: hidden;
  position: absolute;
  background-color: ${({ theme }) => theme.MainNav};
  z-index: 10;
  top: 45px;
  right: -5px;
  max-height: ${(props) => props.maxHeight || '300px'};
  transition: max-height 0.5s ease-in-out;
  font-size: 1rem;
`;
