import React from 'react';
import { PatientInfoBarLabel } from './PatientsListHeader.styled';
import { SecondaryBar } from '../../../../../assets/containers';

const PatientsListHeader = () => {
  return (
    <SecondaryBar>
      <PatientInfoBarLabel width={'45px'} label={'IMAGE'}>
        Img
      </PatientInfoBarLabel>
      <PatientInfoBarLabel width={'300px'}>Complete Name</PatientInfoBarLabel>
      <PatientInfoBarLabel width={'120px'} label={'BIRTHDATE'}>
        Birth Date
      </PatientInfoBarLabel>
      <PatientInfoBarLabel width={'80px'} label={'SEX'}>
        Sex
      </PatientInfoBarLabel>
      <PatientInfoBarLabel width={'200px'} label={'AGE'}>
        Age
      </PatientInfoBarLabel>
    </SecondaryBar>
  );
};

export default PatientsListHeader;
