import React from 'react';
import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { updatePatientById, createNewPatient } from '../../../../../store/storeSlices/patients';
import { setCurrentPage } from '../../../../../store/storeSlices/settings';

import PatientFormSchema from './PatientFormSchema';
import InitialValues from './InitialValues';
import PatientForm from './PatientForm';

const EditProfileData = ({ patientId, patientData, setIsEdit, isEdit, onCloseModal, onViewPatient, isNew }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const clinicSite = useSelector((state) => state.user.clinicSite);
  const initialValues = InitialValues(patientData);

  const onSubmit = (values, actions) => {
    if (isNew) {
      dispatch(createNewPatient({ method: 'post', url: `patient/add`, token: token, data: { patient: values, clinicSite: clinicSite } }));
      actions.resetForm();
      onCloseModal();
      dispatch(setCurrentPage('ChartList'));
    } else {
      dispatch(updatePatientById({ method: 'post', url: `patient/update/${patientId}`, token: token, data: values }));
      actions.resetForm();
      onCloseModal();
      onViewPatient(patientId);
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema: PatientFormSchema,
    onSubmit,
  });

  return (
    <PatientForm
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      errors={errors}
      touched={touched}
      isEdit={isEdit}
      isNew={isNew}
      setIsEdit={setIsEdit}
      onCloseModal={onCloseModal}
      patientData={patientData}
    />
  );
};

export default EditProfileData;
