import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { searchPatient, updatePatientListSilently } from '../../../../store/storeSlices/patients';

import PatientsListMainBar from './PatientListComponents/PatientsListMainBar';
import PatientsListHeader from './PatientListComponents/PatientsListHeader';
import PatientsListBar from './PatientListComponents/PatientsListBar';
import PatientPaginatedBar from './PatientListComponents/PatientPaginatedBar';

const PatientList = ({ onCreateNewPatient, onViewPatient, onViewChart }) => {
  const dispatch = useDispatch();
  const patientsList = useSelector((state) => state.patient.searchResult);
  const userToken = useSelector((state) => state.user.userData.userToken);
  const refreshRate = useSelector((state) => state.settings.refreshRate);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    dispatch(searchPatient({ method: 'get', url: `patient/search?page=${page}&limit=${limit}&firstName=${firstName}&middleName=&lastName=${lastName}`, token: userToken }));
  }, [dispatch, firstName, lastName, page, limit, userToken, refreshTrigger]);

  useEffect(() => {
    let refreshTimer = setTimeout(() => {
      dispatch(updatePatientListSilently({ method: 'get', url: `patient/search?page=${page}&limit=${limit}&firstName=${firstName}&middleName=&lastName=${lastName}`, token: userToken }));
      setRefreshCounter(refreshCounter + 1);
    }, refreshRate);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, [dispatch, firstName, lastName, page, limit, userToken, refreshTrigger, refreshCounter, refreshRate]);

  const handleRefreshData = () => {
    setRefreshTrigger(refreshTrigger + 1);
  };

  return (
    <Fragment>
      <PatientsListMainBar onCreateNewPatient={onCreateNewPatient} onRefreshData={handleRefreshData} setFirstName={setFirstName} setLastName={setLastName} />
      <PatientsListHeader />
      <PatientsListBar patientsList={patientsList} onViewPatient={onViewPatient} onViewChart={onViewChart} />
      <PatientPaginatedBar page={page} limit={limit} setPage={setPage} setLimit={setLimit} />
    </Fragment>
  );
};

export default PatientList;
