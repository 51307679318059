import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios/axios';
import { toast } from 'react-toastify';
import moment from 'moment';

export const getSettings = createAsyncThunk('settings/getSettings', async (action) => {
  return await apiRequest(action);
});

const filterSettings = localStorage.getItem('clearVisionFilterSettings') ? JSON.parse(localStorage.getItem('clearVisionFilterSettings')) : null;

const initialState = {
  tokenExpired: false,
  rolesSettings: {},
  companySettings: {},
  themeSettings: {},
  activeTheme: localStorage.getItem('clinicAppActiveTheme') ? JSON.parse(localStorage.getItem('clinicAppActiveTheme')) : {},
  refreshRate: 20,
  currentPage: 'ChartList',
  filterSettings: {
    statusList: filterSettings ? filterSettings.statusList : ['Registered', 'Optometrist', 'Doctor', 'Laboratory', 'Payment', 'Completed'],
    doctorFilter: filterSettings ? filterSettings.doctorFilter : 'all',
    isToday: filterSettings ? filterSettings.isToday : true,
    dateFrom: filterSettings ? (filterSettings.isToday ? moment().startOf('day') : filterSettings.dateFrom) : moment().startOf('day'),
    dateTo: filterSettings ? (filterSettings.isToday ? moment().endOf('day') : filterSettings.dateTo) : moment().endOf('day'),
    filterIsHidden: filterSettings ? filterSettings.filterIsHidden : false,
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilterSettings: (state, action) => {
      state.filterSettings = action.payload;
      localStorage.setItem('clearVisionFilterSettings', JSON.stringify(action.payload));
    },
    setSettingsTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // get settings
      .addCase(getSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          localStorage.setItem('clinicAppCompanySettings', JSON.stringify(data.companySettings));
          localStorage.setItem('clinicAppActiveTheme', JSON.stringify(data.themeSettings.themeList.filter((theme) => theme.name === data.themeSettings.activeTheme)[0].themeColors));
          state.rolesSettings = data.rolesSettings;
          state.companySettings = data.companySettings;
          state.themeSettings = data.themeSettings;
          state.refreshRate = data.refreshRate;
          state.companyName = data.companySettings.companyName;
          state.companyText = data.companySettings.companyText;
          state.backgroundColor = data.companySettings.backgroundColor;
          state.companyImages = { faviconURL: data.companySettings.faviconURL, logoURL: data.companySettings.logoURL, backgroundURL: data.companySettings.backgroundURL };
          state.activeTheme = data.themeSettings.themeList.filter((theme) => theme.name === data.themeSettings.activeTheme)[0].themeColors;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      });
  },
});

export const { setCurrentPage, setFilterSettings, setSettingsTokenExpired } = settingsSlice.actions;

export default settingsSlice.reducer;
