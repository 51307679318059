import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userLogOut } from '../../../../store/storeSlices/user';
import { setCurrentPage } from '../../../../store/storeSlices/settings';
import { RiProfileLine, RiSettings5Line, RiStackLine, RiFileUserLine, RiLogoutCircleRLine } from 'react-icons/ri';
import { MdOutlineInventory } from 'react-icons/md';

import { NavButtons } from './MainNav.styled';

function MainNav({ currentUser, hideNav }) {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.settings.currentPage);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(userLogOut({ method: 'post', url: `user/logout/${currentUser.userId}`, token: currentUser.userToken }));
  };

  return (
    <Fragment>
      <NavButtons
        active={currentPage === 'Profile'}
        onClick={() => {
          hideNav();
          dispatch(setCurrentPage('Profile'));
        }}
      >
        Profile <RiProfileLine />
      </NavButtons>

      {currentUser.userAccess === 'admin' && (
        <NavButtons
          active={currentPage === 'DataList'}
          onClick={() => {
            hideNav();
            dispatch(setCurrentPage('DataList'));
          }}
        >
          Data List <RiStackLine />
        </NavButtons>
      )}
      {(currentUser.userAccess === 'admin' || currentUser.userAccess === 'supervisor') && (
        <NavButtons
          active={currentPage === 'Users'}
          onClick={() => {
            hideNav();
            dispatch(setCurrentPage('Users'));
          }}
        >
          Users <RiFileUserLine />
        </NavButtons>
      )}
      {currentUser.userAccess === 'admin' && (
        <NavButtons
          active={currentPage === 'Settings'}
          onClick={() => {
            hideNav();
            dispatch(setCurrentPage('Settings'));
          }}
        >
          Settings <RiSettings5Line />
        </NavButtons>
      )}

      {currentUser.userAccess === 'admin' && (
        <NavButtons
          active={currentPage === 'Inventory'}
          onClick={() => {
            hideNav();
            dispatch(setCurrentPage('Inventory'));
          }}
        >
          Inventory <MdOutlineInventory />
        </NavButtons>
      )}

      <NavButtons onClick={handleLogout}>
        Logout <RiLogoutCircleRLine />
      </NavButtons>
    </Fragment>
  );
}

export default MainNav;
