import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDataList, setDataListTokenExpired } from '../../store/storeSlices/dataList';
import { getSettings, setCurrentPage, setSettingsTokenExpired } from '../../store/storeSlices/settings';
import { setCardTokenExpired } from '../../store/storeSlices/cards';
import { clearChartData, setChartTokenExpired } from '../../store/storeSlices/charts';
import { clearPatientData, setPatientTokenExpired } from '../../store/storeSlices/patients';
import { setMedicineTokenExpired } from '../../store/storeSlices/medicine';
import { logUserOut } from '../../store/storeSlices/user';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import MainBody from './Body/Body';

function LoggedIn() {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user && state.user.userData && state.user.userData.userToken && state.user.userData.userToken);
  const cardTokenExpired = useSelector((state) => state.card.tokenExpired);
  const chartTokenExpired = useSelector((state) => state.chart.tokenExpired);
  const dataListTokenExpired = useSelector((state) => state.dataList.tokenExpired);
  const patientTokenExpired = useSelector((state) => state.patient.tokenExpired);
  const settingTokenExpired = useSelector((state) => state.settings.tokenExpired);
  const medicineTokenExpired = useSelector((state) => state.medicine.tokenExpired);

  useEffect(() => {
    if (cardTokenExpired || chartTokenExpired || dataListTokenExpired || patientTokenExpired || settingTokenExpired || medicineTokenExpired) {
      dispatch(clearPatientData());
      dispatch(clearChartData());
      dispatch(logUserOut());
    }
  }, [cardTokenExpired, chartTokenExpired, dataListTokenExpired, patientTokenExpired, settingTokenExpired, medicineTokenExpired, dispatch]);

  useEffect(() => {
    dispatch(getDataList({ method: 'get', url: 'datalist/all', token: userToken }));
    dispatch(getSettings({ method: 'get', url: 'settings', token: userToken }));
    dispatch(setCurrentPage('ChartList'));
    dispatch(setDataListTokenExpired(false));
    dispatch(setSettingsTokenExpired(false));
    dispatch(setCardTokenExpired(false));
    dispatch(setChartTokenExpired(false));
    dispatch(setPatientTokenExpired(false));
    dispatch(setMedicineTokenExpired(false));
  }, [userToken, dispatch]);

  return (
    <Fragment>
      <Header />
      <MainBody />
      <Footer />
    </Fragment>
  );
}

export default LoggedIn;
