import styled, { keyframes } from 'styled-components/macro';

const SearchProfileModal = keyframes`
    0% {
      opacity: 0;
      width: 0px;
      height: 0px;
    }

    100% {
      opacity: 1;
      width: 1000px;
      height: 650px;
    }
`;

export const SearchPatientContainer = styled.div`
  width: 100%;
  margin: 0px;
  height: auto;
  z-index: 1020;
  animation-name: ${SearchProfileModal};
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;
  padding: 10px;
  position: fixed;
  overflow: hidden;
`;

export const TopContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: right;
  padding: 5px 13px;
  align-self: flex-end;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 70%;
`;
export const InputContainer = styled.div`
  margin: 3px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  width: 50%;

  & label {
    padding: 2px 5px;
  }

  & input {
    padding: 3px 7px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 24px;
  }
`;

export const SearchResultContainer = styled.div`
  height: 540px;
  margin: 20px 0px;
  background-color: white;
  padding-top: 3px;
  overflow-y: scroll;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  border-radius: 4px;
`;

export const HeaderBarContainer = styled.div`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
`;

export const HeaderBarInnerContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const HeaderBarLabel = styled.label`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 4px 0px 4px 8px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  font-weight: bold;
`;

const loadInfoBar = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-100%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

export const PatientInfoBar = styled.li`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  transition: all 0.2s ease-in-out;
  animation-name: ${loadInfoBar};
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;

  &:hover {
    border: 1px solid ${({ theme }) => theme.CardHeader};
  }
`;

export const PatientInfoContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const PatientInfoLabel = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 0px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  display: flex;
  align-items: center;
`;
