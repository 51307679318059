import styled from 'styled-components/macro';

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  transition: max-height 0.5s ease-in;
  margin-bottom: ${(props) => (props.hide ? '0px' : '3px')};
`;

export const FilterBox = styled.div`
  width: 100%;
  padding: ${(props) => (props.hide ? '0px' : '5px 10px')};
  margin: 1px;
  background-color: ${({ theme }) => theme.CardBackground};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  transition: padding 0.5s ease-in;
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const FilterGroup = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  padding: 5px 8px;
  width: ${(props) => (props.isToday ? '100px' : '27%')};

  @media (max-width: 800px) {
    width: 48%;
    margin-top: 5px;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 5px;
  }
`;

export const FilterGroup2 = styled.div`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  padding: 5px 8px;
  margin: 0px 2px;
  width: 16%;

  @media (max-width: 1190px) {
    width: 32%;
    margin-top: 5px;
  }

  @media (max-width: 800px) {
    width: 48%;
  }
`;

export const LabelContainer = styled.div`
  display: flex;

  @media (max-width: 1190px) {
    width: 80px;
  }

  @media (max-width: 605px) {
    width: 100px;
  }

  @media (max-width: 400px) {
    width: 80px;
  }
`;

export const FilterLabel = styled.label`
  font-size: 0.7rem;
  padding: 5px 10px 0px 10px;
`;
