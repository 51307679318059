import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { getClinicSiteList } from '../../store/storeSlices/dataList';
import { setClinicSite, userLogIn } from '../../store/storeSlices/user';

import mainLogo from '../../assets/images/mainLogo.png';
import { ModalLoader } from '../../assets/loader';

import { Container, Modal, LogoContainer, Logo, CompanyName, FormContainer, InputContainer, LoginBtn, ErrorMessage, FetchText, FetchTextError } from './LoggedOut.styled';

function LoggedOut() {
  const dispatch = useDispatch();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const clinicSiteList = useSelector((state) => state.dataList.clinicSiteList);
  const status = useSelector((state) => state.user.status);
  const getClinicListStatus = useSelector((state) => state.dataList.status);

  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      if (getClinicListStatus === 'failed') {
        dispatch(getClinicSiteList({ method: 'get', url: 'datalist/clinicList' }));
        setRefreshCounter((prevVal) => prevVal + 1);
      }
    }, 10000);
    return () => {
      clearTimeout(refreshTimer);
    };
  }, [dispatch, refreshCounter, getClinicListStatus]);

  useEffect(() => {
    dispatch(getClinicSiteList({ method: 'get', url: 'datalist/clinicList' }));
  }, [dispatch]);

  const loginSchema = yup.object().shape({
    emailAddress: yup.string().email('Invalid email address').required('Email address is required'),
    password: yup.string().required('Password is required'),
    clinicSite: yup.string().required('Please select a clinic site'),
  });

  const onSubmit = (values) => {
    localStorage.setItem('clinicAppDefaultSite', values.clinicSite);
    dispatch(userLogIn({ method: 'post', url: 'user/login', data: { emailAddress: values.emailAddress, password: values.password } }));
    dispatch(setClinicSite(values.clinicSite));
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, isValid } = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
      clinicSite: localStorage.getItem('clinicAppDefaultSite') || '',
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  return (
    <Container>
      <Modal>
        <LogoContainer>
          <Logo src={mainLogo} alt="logo" />
          <CompanyName>The Clinic App</CompanyName>
        </LogoContainer>
        {getClinicListStatus === 'loading' && (
          <FormContainer>
            <ModalLoader />
            <FetchText>Fetching clinic site list</FetchText>
          </FormContainer>
        )}
        {getClinicListStatus === 'failed' && (
          <FormContainer>
            <FetchTextError>
              Unable to fetch clinic list. <br /> Please check your internet connection.
            </FetchTextError>
          </FormContainer>
        )}
        {getClinicListStatus === 'succeeded' && (
          <FormContainer autoComplete="on" onSubmit={handleSubmit}>
            <InputContainer>
              <label htmlFor="emailAddress">Email Address:</label>
              <input value={values.emailAddress} onChange={handleChange} onBlur={handleBlur} name="emailAddress" id="emailAddress" type="text" placeholder="Email Address" />
              {errors.emailAddress && touched.emailAddress && <ErrorMessage>{errors.emailAddress}</ErrorMessage>}
            </InputContainer>
            <InputContainer>
              <label htmlFor="emailAddress">Password:</label>
              <input value={values.password} onChange={handleChange} onBlur={handleBlur} name="password" id="password" type="password" placeholder="Password" />
              {errors.password && touched.password && <ErrorMessage>{errors.password}</ErrorMessage>}
            </InputContainer>
            <InputContainer>
              <label htmlFor="clinicSite">Select Site:</label>
              <select value={values.clinicSite} onChange={handleChange} onBlur={handleBlur} name="clinicSite" id="clinicSite">
                <option value="">Select Clinic Site</option>
                {clinicSiteList &&
                  clinicSiteList.map((site, index) => {
                    return (
                      <option key={index} value={site.id}>
                        {site.name}
                      </option>
                    );
                  })}
              </select>
              {values.clinicSite === '' && touched.clinicSite && <ErrorMessage>{errors.clinicSite}</ErrorMessage>}
            </InputContainer>
            <LoginBtn type="submit" disabled={!isValid || status === 'loading'}>
              {status === 'loading' ? 'Signing in...' : 'Sign In'}
            </LoginBtn>
          </FormContainer>
        )}
      </Modal>
    </Container>
  );
}

export default LoggedOut;
