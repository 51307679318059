import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { MainContainer, ProcedureListContainer, ProcedureInfoContainer, InlineInputContainer, InlineInput, InlineMultiLineInput, InlineSelect, InfoData } from './ProcedureList.styled';
import { AddOtherProcedureInputContainer, ChartCardInputContainer, InfoLabel, InfoInput, InfoSelect, InfoInputTextArea } from './ProcedureList.styled';
import { SearchInputFieldContainer, SearchInputField, SearchResultContainer, SearchResult, SearchResultButtonContainer } from './ProcedureList.styled';
import { IconsContainer, SaveIcon, CancelIcon, EditIcon, DeleteIcon } from '../../../../../../../assets/icons';
import { ButtonsContainer, Button1, Button2, Button3 } from '../../../../../../../assets/buttons';

const ProcedureList = ({ procedureList, onUpdate }) => {
  const ListContainer = useRef();
  const SearchDatabase = useSelector((state) => state.dataList.procedureList);
  const [lineIsEdit, setLineIsEdit] = useState(false);
  const [lineEditIndex, setLineEditIndex] = useState(null);
  const [lineIsEditRVSCode, setLineIsEditRVSCode] = useState('');
  const [lineIsEditProcedure, setLineIsEditProcedure] = useState('');
  const [lineIsEditEyes, setLineIsEditEyes] = useState('');
  const [addOthers, setAddOthers] = useState(false);
  const [otherProcedure, setOtherProcedure] = useState('');
  const [otherRVSCode, setOtherRVSCode] = useState('');
  const [otherEye, setOtherEye] = useState('');
  const [searchTextValue, setSearchTextValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTextValue.length >= 1) {
        setSearchResult(SearchDatabase.filter((procedure) => procedure.Description.toLowerCase().includes(searchTextValue.toLowerCase())));
      } else {
        setSearchResult(SearchDatabase);
      }
    }, 100);

    return () => clearTimeout(delay);
  }, [searchTextValue, SearchDatabase]);

  const handleSaveLine = (index) => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    let newData = procedureList.map((data, i) => {
      if (i === index) {
        return { Description: lineIsEditProcedure, RVScode: lineIsEditRVSCode, eye: lineIsEditEyes, price: data.price };
      } else {
        return data;
      }
    });
    onUpdate(newData);
    setLineIsEditRVSCode('');
    setLineIsEditProcedure('');
    setLineIsEditEyes('');
  };

  const handleEditLine = (index) => {
    setLineIsEdit(true);
    setLineEditIndex(index);
    setLineIsEditRVSCode(procedureList[index].RVScode);
    setLineIsEditProcedure(procedureList[index].Description);
    setLineIsEditEyes(procedureList[index].eye);
  };

  const handleCancelLine = () => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditRVSCode('');
    setLineIsEditProcedure('');
    setLineIsEditEyes('');
  };

  const handleDeleteLine = (data) => {
    onUpdate(procedureList.filter((procedure) => procedure !== data));
  };

  const handleAddDiagnosis = (data, eye) => {
    eye && onUpdate([...procedureList, { ...data, eye }]);
    setSearchTextValue('');
    setSearchResult(SearchDatabase);
    setAddOthers(false);
    ListContainer.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <MainContainer>
      <div ref={ListContainer}></div>
      {procedureList.map((data, index) => {
        return (
          <ProcedureListContainer key={index}>
            <ProcedureInfoContainer>
              {lineIsEdit && lineEditIndex === index ? (
                <InlineInputContainer>
                  <InlineInput value={lineIsEditRVSCode} onChange={(e) => setLineIsEditRVSCode(e.target.value)} />
                  <InlineMultiLineInput value={lineIsEditProcedure} onChange={(e) => setLineIsEditProcedure(e.target.value)} rows={4} />
                  <InlineSelect value={lineIsEditEyes} onChange={(e) => setLineIsEditEyes(e.target.value)}>
                    <option>Left Eye</option>
                    <option>Right Eye</option>
                    <option>Both Eyes</option>
                  </InlineSelect>
                </InlineInputContainer>
              ) : (
                <InfoData border={'none'}>
                  {`[ ${data.RVScode} ] ${data.Description} - `} {`${data.eye}`}
                </InfoData>
              )}
            </ProcedureInfoContainer>
            <IconsContainer>
              {lineIsEdit && lineEditIndex === index ? (
                <>
                  <div onClick={() => handleSaveLine(index)}>
                    <SaveIcon />
                  </div>
                  <div onClick={() => handleCancelLine()}>
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <>
                  <div onClick={() => handleEditLine(index)}>
                    <EditIcon />
                  </div>
                  <div onClick={() => handleDeleteLine(data)}>
                    <DeleteIcon />
                  </div>
                </>
              )}
            </IconsContainer>
          </ProcedureListContainer>
        );
      })}
      {addOthers ? (
        <>
          <SearchInputFieldContainer>
            <ButtonsContainer>
              <Button1 onClick={() => handleAddDiagnosis({ RVScode: otherRVSCode, Description: otherProcedure }, otherEye)}>Add</Button1>
              <Button2 onClick={() => setAddOthers(false)}>Cancel</Button2>
            </ButtonsContainer>
          </SearchInputFieldContainer>
          <AddOtherProcedureInputContainer>
            <ChartCardInputContainer>
              <InfoLabel>RVScode:</InfoLabel>
              <InfoInput onChange={(e) => setOtherRVSCode(e.target.value)} autoFocus={true} />
            </ChartCardInputContainer>
            <ChartCardInputContainer>
              <InfoLabel>Diagnosis:</InfoLabel>
              <InfoInputTextArea onChange={(e) => setOtherProcedure(e.target.value)} />
            </ChartCardInputContainer>
            <ChartCardInputContainer>
              <InfoLabel>Diagnosis:</InfoLabel>
              <InfoSelect onChange={(e) => setOtherEye(e.target.value)}>
                <option>Left Eye</option>
                <option>Right Eye</option>
                <option>Both Eyes</option>
              </InfoSelect>
            </ChartCardInputContainer>
          </AddOtherProcedureInputContainer>
        </>
      ) : (
        <>
          <SearchInputFieldContainer>
            <SearchInputField value={searchTextValue} onChange={(e) => setSearchTextValue(e.target.value)} placeholder={'Search procedure'} autoFocus={true} />
            <ButtonsContainer>
              <Button2
                onClick={() => {
                  setSearchTextValue('');
                  setSearchResult(SearchDatabase);
                }}
              >
                Clear search
              </Button2>
              <Button1 onClick={() => setAddOthers(true)}>Add not listed</Button1>
            </ButtonsContainer>
          </SearchInputFieldContainer>
          {searchResult.length > 0 && (
            <SearchResultContainer>
              {searchResult.map((result, index) => {
                return (
                  <SearchResult key={index}>
                    {result.Description}
                    <SearchResultButtonContainer>
                      <div onClick={() => handleAddDiagnosis(result, 'Left Eye')}>
                        <Button1>Left</Button1>
                      </div>
                      <div onClick={() => handleAddDiagnosis(result, 'Right Eye')}>
                        <Button2>Right</Button2>
                      </div>
                      <div onClick={() => handleAddDiagnosis(result, 'Both Eyes')}>
                        <Button3>Both</Button3>
                      </div>
                    </SearchResultButtonContainer>
                  </SearchResult>
                );
              })}
            </SearchResultContainer>
          )}
        </>
      )}
    </MainContainer>
  );
};

export default ProcedureList;
