import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { ChartInfoBarContainer, ChartInfoBar, ChartInfoContainer, ChartInfoLabel, PatientAvatarContainer, PatientAvatar, ChartInfoPatientName } from './PatientChartList.styled';
import { ButtonsContainer, Button1 } from '../../../../../assets/buttons';
import ChartListStatus from '../../ChartResources/ChartListStatus';
import SelectDoctorList from '../../ChartResources/SelectDoctorList';
import { MainLoader } from '../../../../../assets/loader';
import femaleImage from '../../../../../assets/images/femaleImagePlaceholder.jpeg';
import maleImage from '../../../../../assets/images/maleImagePlaceholder.png';

const PatientChartList = ({ chartList, onViewPatient, onViewChart, hideFilter }) => {
  const chartStatus = useSelector((state) => state.dataList && state.dataList.chartStatus);
  const loadingStatus = useSelector((state) => state.chart.status);

  const getStatusColor = (status) => {
    let resultColor;
    chartStatus &&
      chartStatus.forEach((a) => {
        if (a.status === status) {
          resultColor = a.color;
        }
      });
    return resultColor;
  };

  const getAge = (dob) => {
    let dateNow = moment();
    let dateOfBirth = moment(dob);
    let years = moment.duration(dateNow.diff(dateOfBirth)).years();
    let months = moment.duration(dateNow.diff(dateOfBirth)).months();
    let days = moment.duration(dateNow.diff(dateOfBirth)).days();
    return `${years}y - ${months}m - ${days}d`;
  };

  return (
    <ChartInfoBarContainer hideFilter={hideFilter}>
      {loadingStatus === 'loading' ? (
        <MainLoader />
      ) : (
        <>
          {chartList &&
            chartList.map((chart, index) => (
              <ChartInfoBar key={index} status={`${getStatusColor(chart.status)}`}>
                <ChartInfoContainer>
                  <ChartInfoLabel width={'50px'} label={'NUMBER'}>
                    {index + 1}
                  </ChartInfoLabel>
                  <ChartInfoLabel width={'70px'} label={'DATE'}>
                    {moment(chart.createdAt).format('MMM DD')}
                  </ChartInfoLabel>
                  <ChartInfoLabel width={'90px'} label={'TIMEIN'}>
                    {moment(chart.createdAt).format('h:mm a')}
                  </ChartInfoLabel>
                  <PatientAvatarContainer label={'IMAGE'}>
                    {chart.patientData[0].personalInfo.image ? (
                      <PatientAvatar src={chart.patientData[0].personalInfo.image} />
                    ) : (
                      <>{chart.patientData[0].personalInfo.sex === 'Female' ? <PatientAvatar src={femaleImage} /> : <PatientAvatar src={maleImage} />}</>
                    )}
                  </PatientAvatarContainer>
                  <ChartInfoPatientName width={'240px'} label={'NAME'} pointer={true} onClick={() => onViewPatient(chart.patientId)}>
                    <div>
                      {chart.patientData[0].personalInfo.firstName} {chart.patientData[0].personalInfo.lastName}
                    </div>
                  </ChartInfoPatientName>
                  <ChartInfoLabel width={'70px'} label={'SEX'}>
                    {chart.patientData[0].personalInfo.sex}
                  </ChartInfoLabel>
                  <ChartInfoLabel width={'130px'} label={'AGE'}>
                    {getAge(chart.patientData[0].personalInfo.dateOfBirth)}
                  </ChartInfoLabel>
                  <SelectDoctorList chart={chart} index={index} />
                  <ChartListStatus chartStatus={chartStatus} chart={chart} index={index} />
                </ChartInfoContainer>
                <ButtonsContainer>
                  <Button1 onClick={() => onViewChart(chart._id, chart.patientId)}>View Chart</Button1>
                </ButtonsContainer>
              </ChartInfoBar>
            ))}
        </>
      )}
    </ChartInfoBarContainer>
  );
};

export default PatientChartList;
