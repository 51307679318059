import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';

import { updateCardInChart, addCardToChart, setIsCardEdit, setIsNewCard, setNewCardType, setNewCardData } from '../../../../../../../store/storeSlices/cards';

import { CardContainer } from '../CardResources/Card.styled';
import CardHeaderBar from '../CardResources/CardHeaderBar';

import ViewPrescriptionCard from './ViewPrescriptionCard';
import Printer from '../CardResources/Printer/Printer';
import EditCardModal from '../CardResources/EditCardModal';
import MedicineList from '../CardResources/MedicineList';

const PrescriptionCard = ({ cardData, cardIndex, isNew }) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const chartId = useSelector((state) => state.chart.chartData._id);
  const currentUser = useSelector((state) => state.user.userData);
  const [isEdit, setIsEdit] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [prescriptList, setPrescriptionList] = useState(cardData.cardData);

  const handleBeforeGetContent = () => {
    setIsPrint(true);
    return Promise.resolve();
  };

  const onPrintHandler = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `patient_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const clearAddNewHandler = () => {
    dispatch(setIsNewCard(false));
    dispatch(setNewCardType(''));
    dispatch(setNewCardData({}));
  };

  const onSubmitHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    isNew
      ? dispatch(addCardToChart({ method: 'post', url: `card/add/${chartId}`, token, data: { cardType: cardData.cardType, cardData: prescriptList, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` } }))
      : dispatch(updateCardInChart({ method: 'patch', url: `card/update/${chartId}?index=${cardIndex}`, token, data: { cardData: prescriptList } }));
    clearAddNewHandler();
  };

  const onUpdateHandler = (data) => {
    setPrescriptionList(data);
  };

  const onEditHandler = () => {
    dispatch(setIsCardEdit(isEdit));
    setIsEdit(!isEdit);
    isEdit && setPrescriptionList(cardData.cardData);
    clearAddNewHandler();
  };

  const onCloseModalHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };

  return (
    <CardContainer>
      <CardHeaderBar title={'Prescription Card'} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isPrint={true} onPrint={onPrintHandler} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
      {isEdit || isNew ? (
        <EditCardModal onCloseModal={onCloseModalHandler} title={'Prescription Card'} handleSubmit={onSubmitHandler}>
          <MedicineList prescriptList={prescriptList} onUpdate={onUpdateHandler} />
        </EditCardModal>
      ) : (
        <>
          {isPrint ? (
            <div ref={componentRef}>
              <Printer title={'PRESCRIPTION'} includeDoctor={true}>
                <ViewPrescriptionCard setIsEdit={setIsEdit} cardData={cardData.cardData} />
              </Printer>
            </div>
          ) : (
            <ViewPrescriptionCard cardData={cardData.cardData} isHidden={isHidden} />
          )}
        </>
      )}
    </CardContainer>
  );
};

export default PrescriptionCard;
