import styled from 'styled-components/macro';

export const PatientSearchContainer = styled.div`
  padding: 2px 20px 2px 0px;
  background-color: ${({ theme }) => theme.CardBackground};
  display: flex;
  justify-content: space-between;
`;

export const PatientSearchLabel = styled.label`
  padding: 10px 15px 0px 15px;
`;

export const PatientSearchInput = styled.input`
  padding: 5px 15px;
  width: 200px;
`;
