import styled, { keyframes } from 'styled-components/macro';

const loadInfoBar = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;
export const ChartInfoBar = styled.li`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  border-left: 5px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
  transition: all 0.2s ease-in-out;
  animation-name: ${loadInfoBar};
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;

  &:hover {
    border: 1px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
    border-left: 5px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
  }
`;

export const ChartInfoBarContainer = styled.ul`
  height: ${(props) => (props.hideFilter ? 'calc(100vh - 155px)' : 'calc(100vh - 255px)')};
  padding: 0px 5px 0px 0px;
  margin: 0px;
  overflow-y: scroll;
  margin-bottom: 5px;
  transition: height 0.5s ease-in-out;
`;

export const ChartInfoContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const ChartInfoPatientName = styled.div`
  padding: 2px 5px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  display: flex;
  align-items: center;

  @media (max-width: 1050px) {
    display: ${(props) => props.label === 'DOCTOR' && 'none'};
  }

  @media (max-width: 500px) {
    width: ${(props) => props.label === 'NAME' && '200px'};
  }

  & div {
    cursor: pointer;
    color: ${({ theme }) => theme.FontColor};
    font-size: 0.8rem;
    padding: 8px;
    width: 230px;
    border: 1px solid ${({ theme }) => theme.CardBorder};
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    &:hover {
      color: ${(props) => props.theme.MainNav};
      background-color: white;
    }
  }
`;

export const ChartInfoLabel = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.8rem;
  padding: 5px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  text-align: center;
  line-height: 26px;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }

  @media (max-width: 1195px) {
    display: ${(props) => (props.label === 'DATE' || props.label === 'TIMEIN') && 'none'};
  }

  @media (max-width: 1050px) {
    display: ${(props) => props.label === 'DOCTOR' && 'none'};
  }

  @media (max-width: 800px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
    display: ${(props) => props.label === 'SEX' && 'none'};
  }

  @media (max-width: 550px) {
    display: ${(props) => props.label === 'STATUS' && 'none'};
  }
`;

export const PatientAvatarContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  width: 45px;
  padding-top: 2px;

  @media (max-width: 1195px) {
    display: ${(props) => props.label === 'IMAGE' && 'none'};
  }
`;

export const PatientAvatar = styled.img`
  width: 35px;
  height: 35px;
  padding: 0px;
  margin: 0px 4px 0px 4px;
`;

export const ChartInfoLabelEditable = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 0px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  display: flex;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }

  @media (max-width: 440px) {
    width: ${(props) => props.label === 'STATUS' && '120px'};
  }
`;

export const ChartInfoLabelEditableText = styled.div`
  cursor: pointer;
  padding-top: 9px;
  width: 100px;

  &:hover {
    color: ${(props) => props.theme.MainNav};
  }
`;

export const SelectInputStatus = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 1px 3px;
  font-size: 0.8rem;
  margin-right: 3px;
  width: 97px;
`;

export const SelectInputOption = styled.option`
  color: ${({ theme }) => theme.FontColor};
  padding: 1px 3px;
  font-size: 0.8rem;
  margin-right: 3px;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
`;
