import React from 'react';
import { FooterContainer, FooterText } from './Footer.styled';

function Footer() {
  return (
    <FooterContainer>
      <FooterText>Copyright &copy; 2023 ClinicApp.online All rights reserved.</FooterText>
    </FooterContainer>
  );
}

export default Footer;
