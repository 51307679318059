import React from 'react';
import { useSelector } from 'react-redux';

import { DoctorInfoContainer, DoctorInfoDetails, DoctorName, DoctorInfo } from './DoctorDetails.styled';

function DoctorDetails() {
  const doctorList = useSelector((state) => state.dataList.doctorList);
  const assignedDoctor = useSelector((state) => state.chart.assignedDoctor);
  const doctor = doctorList.filter((doctor) => doctor._id === assignedDoctor)[0];

  const name = doctor
    ? `${doctor.personalInfo.namePrefix ? doctor.personalInfo.namePrefix : ''} ${doctor.personalInfo.firstName} ${doctor.personalInfo.middleName.charAt(0)}. ${doctor.personalInfo.lastName} ${
        doctor.personalInfo.nameSuffix ? doctor.personalInfo.nameSuffix : ''
      }`
    : '';
  const prc = doctor ? doctor.employmentRecord.prcNumber : '';
  const ptr = doctor ? doctor.employmentRecord.ptrNumber : '';

  return (
    <DoctorInfoContainer>
      <DoctorInfoDetails>
        <DoctorName>{name}</DoctorName>
        {prc && <DoctorInfo>{`License No. ${prc}`}</DoctorInfo>}
        {ptr && <DoctorInfo>{`PTR No. ${ptr}`}</DoctorInfo>}
      </DoctorInfoDetails>
    </DoctorInfoContainer>
  );
}

export default DoctorDetails;
