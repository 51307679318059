import styled, { keyframes } from 'styled-components/macro';

export const Container = styled.div`
  padding-top: 48px;
  width: 1440px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eff3f5;
  background-color: ${({ theme }) => theme.MainBackground};
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px 2px 0px 0px;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const MainBar = styled.div`
  padding: 3px;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;

export const SecondaryBar = styled.div`
  width: auto;
  margin: 0px 10px 1px 5px;
  display: flex;
  justify-content: left;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.CardHeader};
  border-left: 5px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
  transition: all 0.2s ease-in-out;
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 0px 5px;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 1.2rem;
  padding: 5px 20px;
  display: flex;
  align-items: center;
`;

export const MainBodyContainer = styled.div`
  padding-top: 48px;
  width: 1440px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.MainBackground};
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px 2px 0px 0px;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const BackDrop = styled.div`
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
`;

const loadModal = keyframes`
    0% {
      opacity: 0;
      width: 0px;
      height: 0px;
    }

    100% {
      opacity: 1;
      width: 1000px;
      height: 650px;
    }
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 650px;
  background-color: ${({ theme }) => theme.CardBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  animation-name: ${loadModal};
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;
  border-radius: 3px;
  box-shadow: 2px 2px 10px;
  position: fixed;
  z-index: 1000;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 95%;
    margin: auto;
  }
`;
