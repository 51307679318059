import React, { Fragment } from 'react';

import RichTextView from './RichTextView';
import RichTextEditor from './RichTextEditor';

const RichText = ({ data, isView, isEdit, onUpdateContent }) => {
  return (
    <Fragment>
      {isView && <RichTextView storedState={JSON.parse(data)} />}
      {isEdit && <RichTextEditor storedState={JSON.parse(data)} setData={onUpdateContent} />}
    </Fragment>
  );
};

export default RichText;
