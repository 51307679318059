import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ChartActionsContainer, ChartActionsHeader, ChartActionsScrollableContainer, ActionListContainer, ActionList } from './ViewChartActions.styled';
import { setIsNewCard, setNewCardType, setNewCardData } from '../../../../../store/storeSlices/cards';

const ViewChartActions = ({ onClose, onViewPatient, patientId }) => {
  const dispatch = useDispatch();
  const cardList = useSelector((state) => state.dataList.cardTypes);
  let sortedCardList = cardList.slice().sort((a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });

  const onAddCardHandler = (type, data) => {
    dispatch(setIsNewCard(true));
    dispatch(setNewCardType(type));
    dispatch(setNewCardData({ cardType: type, cardData: data }));
  };
  return (
    <ChartActionsContainer>
      <ChartActionsHeader>Chart Actions</ChartActionsHeader>
      <ChartActionsScrollableContainer>
        <ActionListContainer>
          <ActionList onClick={onClose}>Close Chart</ActionList>
          <ActionList onClick={() => onViewPatient(patientId)}>View Patient Data</ActionList>
        </ActionListContainer>
      </ChartActionsScrollableContainer>
      <ChartActionsHeader>Add Card</ChartActionsHeader>
      <ChartActionsScrollableContainer>
        <ActionListContainer>
          {sortedCardList.map((card) => {
            return (
              <ActionList key={card.id} onClick={() => onAddCardHandler(card.id, card.cardData)}>
                {card.name}
              </ActionList>
            );
          })}
        </ActionListContainer>
      </ChartActionsScrollableContainer>
    </ChartActionsContainer>
  );
};

export default ViewChartActions;
