import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';

import { BillingCardHeader, BillingCardHeaderItem, BillingCardItemRow, BillingCardRowItem, BillingCardItemTotal } from './BillingCard.styled';

const ViewBillingCard = ({ cardData, isHidden }) => {
  const getTotal = (data) => {
    let total = 0.0;
    data.forEach((item) => {
      total = total + parseFloat(item.amount);
    });
    return total;
  };
  return (
    <CardBodyContainer isHidden={isHidden}>
      <BillingCardHeader>
        <BillingCardHeaderItem width={'8%'}>Qty</BillingCardHeaderItem>
        <BillingCardHeaderItem width={'12%'}>Unit</BillingCardHeaderItem>
        <BillingCardHeaderItem width={'50%'}>Description</BillingCardHeaderItem>
        <BillingCardHeaderItem width={'15%'}>Price</BillingCardHeaderItem>
        <BillingCardHeaderItem width={'15%'}>Amount</BillingCardHeaderItem>
      </BillingCardHeader>
      {cardData &&
        cardData.map((item, index) => {
          return (
            <BillingCardItemRow key={index}>
              <BillingCardRowItem width={'8%'}>{item.qty}</BillingCardRowItem>
              <BillingCardRowItem width={'12%'}>{item.unit}</BillingCardRowItem>
              <BillingCardRowItem width={'50%'}>{item.description}</BillingCardRowItem>
              <BillingCardRowItem width={'15%'}>
                {parseFloat(item.price)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </BillingCardRowItem>
              <BillingCardRowItem width={'15%'}>
                {parseFloat(item.amount)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </BillingCardRowItem>
            </BillingCardItemRow>
          );
        })}
      <BillingCardItemTotal>
        <span>Total</span>
        <span>
          {'₱ '}
          {getTotal(cardData)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      </BillingCardItemTotal>
    </CardBodyContainer>
  );
};

export default ViewBillingCard;
