import styled, { keyframes } from 'styled-components/macro';

export const BackDrop = styled.div`
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
`;

const loadModal = keyframes`
    0% {
      opacity: 0;
      width: 0px;
      height: 0px;
    }

    100% {
      opacity: 1;
      width: 1200px;
      height: auto;
    }
`;

export const CardModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  max-height: 650px;
  background-color: ${({ theme }) => theme.CardBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  animation-name: ${loadModal};
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;
  border-radius: 3px;
  box-shadow: 2px 2px 10px;
  position: fixed;
  z-index: 1000;
  overflow: hidden;
  padding: 10px;

  @media (max-width: 1000px) {
    width: 95%;
    margin: auto;
  }
`;

export const HeaderBar = styled.div`
  width: 100%;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

export const CardName = styled.div`
  font-size: 1.2rem;
  padding: 5px;
`;

export const MainBody = styled.div`
  background-color: white;
  width: 100%;
  margin: 10px 0px;
  overflow-y: auto;
  border-radius: 3px;
  padding: 20px 10px;
  max-height: 550px;
  overflow-y: auto;
`;
