import React from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

class RichTextView extends React.Component {
  render() {
    const contentState = convertFromRaw(this.props.storedState);
    const editorState = EditorState.createWithContent(contentState);
    return (
      <div className="RichEditor-app">
        <Editor editorState={editorState} readOnly={true} />
      </div>
    );
  }
}

export default RichTextView;
