import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { CardContainer } from '../CardResources/Card.styled';
import CardHeaderBar from '../CardResources/CardHeaderBar';

import ViewBillingCard from './ViewBillingCard';
import Printer from '../CardResources/Printer/Printer';

const BillingCard = ({ cardData }) => {
  const componentRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  const handleBeforeGetContent = () => {
    setIsPrint(true);
    return Promise.resolve();
  };

  const onPrintHandler = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `patient_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const onEditHandler = () => {
    setIsEdit(!isEdit);
  };

  return (
    <CardContainer>
      <CardHeaderBar title={'Billing Card'} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isPrint={true} onPrint={onPrintHandler} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
      {isEdit ? (
        <h1>Edit Billing Card</h1>
      ) : (
        <>
          {isPrint ? (
            <div ref={componentRef}>
              <Printer title={'BILLING INFORMATION'} includeDoctor={false}>
                <ViewBillingCard cardData={cardData.cardData} />
              </Printer>
            </div>
          ) : (
            <ViewBillingCard cardData={cardData.cardData} isHidden={isHidden} />
          )}
        </>
      )}
    </CardContainer>
  );
};

export default BillingCard;
