import styled from 'styled-components/macro';

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
`;

export const PatientInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PatientDetails = styled.div`
  padding: 2px;
  font-size: 0.7rem;
`;
