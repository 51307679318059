import React from 'react';
import { useSelector } from 'react-redux';

import { MainBar, TitleContainer, HeaderTitle } from '../../../../../assets/containers';
import { ButtonsContainer, Button1, Button2, Button3 } from '../../../../../assets/buttons';

const ChartListMainBar = ({ onHideFilter, onRefreshData, onSearchPatient, hideFilter }) => {
  const loadingStatus = useSelector((state) => state.chart.status);
  return (
    <MainBar>
      <TitleContainer>
        <HeaderTitle>Charts</HeaderTitle>
      </TitleContainer>

      <ButtonsContainer>
        <Button1 onClick={onRefreshData} disabled={loadingStatus === 'loading' ? true : false} hideOnMobile={true}>
          {loadingStatus === 'loading' ? 'Fetching Data' : 'Refresh Data'}
        </Button1>
        <Button2 onClick={onHideFilter}>{hideFilter ? 'Show Filter' : 'Hide Filter'}</Button2>
        <Button3 onClick={onSearchPatient}>Search Patient</Button3>
      </ButtonsContainer>
    </MainBar>
  );
};

export default ChartListMainBar;
