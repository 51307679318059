import styled, { keyframes } from 'styled-components/macro';

export const MainContainer = styled.div`
  height: 500px;
`;
export const ProcedureListContainer = styled.div`
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ProcedureInfoContainer = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const InlineInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const InlineInput = styled.input`
  margin: 5px;
  padding: 10px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 2px;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 40px;
`;

export const InlineMultiLineInput = styled.textarea`
  margin: 2px;
  padding: 10px;
  width: 700px;
  border: 1px solid gray;
  border-radius: 2px;
`;

export const InlineSelect = styled.select`
  margin: 5px;
  padding: 10px;
  height: 40px;
  width: 150px;
  border: 1px solid gray;
  border-radius: 2px;
`;

export const InfoData = styled.div`
  color: ${({ theme }) => theme.FontColor};
  border: ${(props) => (props.border ? props.border : `1px solid ${props.theme.CardBorder}`)};
  background-color: white;
  padding-left: 10px;
  font-size: 0.8rem;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }
`;

export const AddOtherProcedureInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const ChartCardInputContainer = styled.div`
  background-color: ${(props) => (props.background ? props.background : props.theme.CardBackground)};
  margin: 2px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  padding: 2px;
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  font-size: 1rem;
  width: 200px;
`;

export const InfoSelect = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  font-size: 1rem;
  width: 200px;
`;

export const InfoInputTextArea = styled.textarea`
  width: 100%;
  padding: 5px;
  height: 100px;
  margin-bottom: 10px;
`;

export const SearchInputFieldContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;
export const SearchInputField = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 10px 15px;
  font-size: 1.2rem;
  margin-right: 3px;
  width: 500px;
`;

const loadInfoBar = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0%);
    }
`;

export const SearchResultContainer = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  width: 100%;
  height: auto;
  padding-bottom: 2px;
  transition: all 0.2s ease-in-out;
  animation-name: ${loadInfoBar};
  animation-duration: 200ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;
`;

export const SearchResultButtonContainer = styled.div`
  display: flex;
`;

export const SearchResult = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  width: auto;
  padding: 5px;
  font-size: 0.8rem;
  margin: 2px 2px 0px 2px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.CardHeader};
  }
`;
