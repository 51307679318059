import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { setFilterSettings } from '../../../../../store/storeSlices/settings';

import { FilterContainer, FilterBox, FilterRow, FilterGroup, FilterGroup2, LabelContainer, FilterLabel } from './FilterBar.styled';

function FilterBar({ hideFilter }) {
  const dispatch = useDispatch();
  const chartStatusList = useSelector((state) => state.dataList.chartStatus).map((status) => status.status);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const { statusList, doctorFilter, isToday, dateFrom, dateTo } = useSelector((state) => state.settings.filterSettings);
  const [tempStatusList, setTempStatusList] = useState(statusList);
  const [tempSelectedDoctor, setTempSelectedDoctor] = useState(doctorFilter);
  const [tempIsToday, setTempIsToday] = useState(isToday);
  const [tempDateFrom, setTempDateFrom] = useState(dateFrom);
  const [tempDateTo, setTempDateTo] = useState(dateTo);

  useEffect(() => {
    dispatch(setFilterSettings({ statusList: tempStatusList, doctorFilter: tempSelectedDoctor, isToday: tempIsToday, dateFrom: tempDateFrom, dateTo: tempDateTo, filterIsHidden: hideFilter }));
  }, [dispatch, tempStatusList, tempSelectedDoctor, tempIsToday, tempDateFrom, tempDateTo, hideFilter]);

  const statusListHandler = (name, bol) => {
    if (bol) {
      setTempStatusList((prevList) => [...prevList, name]);
    } else {
      setTempStatusList((prevList) => prevList.filter((status) => status !== name));
    }
  };

  const onIsTodayHandler = (e) => {
    setTempIsToday(e.target.checked);
    setTempDateFrom(moment().startOf('day'));
    setTempDateTo(moment().endOf('day'));
  };

  return (
    <FilterContainer style={{ maxHeight: `${hideFilter ? '0px' : '300px'}` }} hide={hideFilter}>
      <FilterBox style={{ padding: `${hideFilter ? '0px' : '5px 10px'}` }}>
        <FilterRow>
          <FilterGroup isToday={true}>
            <LabelContainer>
              <FilterLabel htmlFor="isToday">Today:</FilterLabel>
            </LabelContainer>
            <input type="checkbox" id="isToday" name="isToday" checked={tempIsToday} onChange={onIsTodayHandler} />
          </FilterGroup>
          <FilterGroup>
            <LabelContainer>
              <FilterLabel htmlFor="dateFrom">Date From:</FilterLabel>
            </LabelContainer>
            <input
              type="date"
              id="dateFrom"
              name="dateFrom"
              disabled={tempIsToday}
              value={moment(tempDateFrom).format('yyyy-MM-DD')}
              onChange={(e) => setTempDateFrom(moment(e.target.value).startOf('day'))}
              max={moment(tempDateTo).format('yyyy-MM-DD')}
            />
          </FilterGroup>
          <FilterGroup>
            <LabelContainer>
              <FilterLabel htmlFor="dateTo">Date To: </FilterLabel>
            </LabelContainer>
            <input type="date" id="dateTo" name="dateTo" disabled={tempIsToday} value={moment(tempDateTo).format('yyyy-MM-DD')} onChange={(e) => setTempDateTo(moment(e.target.value).endOf('day'))} min={moment(tempDateFrom).format('yyyy-MM-DD')} />
          </FilterGroup>
          <FilterGroup>
            <LabelContainer>
              <FilterLabel htmlFor="doctor">Doctor:</FilterLabel>
            </LabelContainer>
            <select type="text" id="doctor" name="doctor" value={tempSelectedDoctor} onChange={(e) => setTempSelectedDoctor(e.target.value)}>
              <option value="all">All doctors</option>
              {doctorList &&
                doctorList.map((doctor, index) => {
                  return (
                    <option key={index} value={doctor._id}>
                      {doctor.personalInfo.firstName} {doctor.personalInfo.lastName}
                    </option>
                  );
                })}
            </select>
          </FilterGroup>
        </FilterRow>
        <FilterRow>
          {chartStatusList.map((status, index) => {
            return (
              <FilterGroup2 key={index}>
                <input type="checkbox" id={status} name={status} onChange={(e) => statusListHandler(e.target.name, e.target.checked)} checked={tempStatusList.includes(status)} />
                <FilterLabel htmlFor={status}>{status}</FilterLabel>
              </FilterGroup2>
            );
          })}
        </FilterRow>
      </FilterBox>
    </FilterContainer>
  );
}

export default FilterBar;
