import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../../store/storeSlices/settings';

import { NavigationContainer, TopNav, TopNavMobile } from './TopNavigation.styled';
import { ChartsIconWhite, PatientsIconWhite } from '../../../../assets/icons';

function TopNavigation() {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.settings.currentPage);
  return (
    <NavigationContainer>
      <TopNav
        active={currentPage === 'ChartList' ? 'true' : false}
        onClick={() => {
          dispatch(setCurrentPage('ChartList'));
        }}
      >
        Charts
      </TopNav>
      <TopNav
        active={currentPage === 'PatientList' ? 'true' : false}
        onClick={() => {
          dispatch(setCurrentPage('PatientList'));
        }}
      >
        Patients
      </TopNav>
      <TopNavMobile
        title="charts"
        active={currentPage === 'ChartList'}
        onClick={() => {
          dispatch(setCurrentPage('ChartList'));
        }}
      >
        <ChartsIconWhite />
      </TopNavMobile>
      <TopNavMobile
        title="patients"
        active={currentPage === 'PatientList'}
        onClick={() => {
          dispatch(setCurrentPage('PatientList'));
        }}
      >
        <PatientsIconWhite />
      </TopNavMobile>
    </NavigationContainer>
  );
}

export default TopNavigation;
