import React, { Fragment } from 'react';

import { InputContainer } from './GenericCard.styled';

const EditGenericCard = ({ cardData, values, handleChange }) => {
  return (
    <Fragment>
      {cardData.map((data) => {
        return (
          <InputContainer key={data.id}>
            <label htmlFor={data.id}>{data.label}: </label>
            <input value={values[data.id]} onChange={handleChange} name={data.id} id={data.id} type="text" />
          </InputContainer>
        );
      })}
    </Fragment>
  );
};

export default EditGenericCard;
