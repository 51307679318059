import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';
import { CardDataContainer, Label, MedCertDataContent, RemarksContainer } from './MedCertCard.styled';
import RichText from '../CardResources/RichText/RichText';

const ViewMedCertCard = ({ cardData, isHidden }) => {
  return (
    <CardBodyContainer isHidden={isHidden}>
      <CardDataContainer>
        <Label>Diagnosis:</Label>
        {cardData &&
          cardData.diagnosis.map((diag, index) => {
            return <MedCertDataContent key={index}>{`${index + 1}) ${diag.icd10 ? diag.icd10 + ': ' : ''} ${diag.diagnosis} - ${diag.eye} `}</MedCertDataContent>;
          })}
      </CardDataContainer>

      <CardDataContainer>
        <Label>Remarks:</Label>
        <RemarksContainer>
          <RichText data={cardData.remarks} isView={true} isEdit={false} isNew={false} />
        </RemarksContainer>
      </CardDataContainer>
    </CardBodyContainer>
  );
};

export default ViewMedCertCard;
