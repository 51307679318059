import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reassignChart } from '../../../../store/storeSlices/charts';

import { SelectDoctorContainer, AssignedDoctorContainer, SelectDoctor, SelectInputOption, IconContainer } from './SelectDoctorList.styled';
import { CancelIcon } from '../../../../assets/icons';

const SelectDoctorList = ({ chart, index, removeBar }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [itemEdit, setItemEdit] = useState('');
  const doctorList = useSelector((state) => state.dataList.doctorList);
  const { userAccess, userId, userToken } = useSelector((state) => state.user.userData);

  const onEditHandler = (id, index) => {
    if (id === 'Select Doctor' || userAccess === 'admin' || userAccess === 'owner' || userId === id) {
      setIsEdit(true);
      setItemEdit(index);
    }
  };

  const onCancelHandler = () => {
    setIsEdit(false);
    setItemEdit('');
  };

  const onChangeHandler = (e) => {
    dispatch(reassignChart({ method: 'patch', url: `chart/reassign/${chart._id}`, token: userToken, data: { doctorId: e.target.value } }));
    setIsEdit(false);
    setItemEdit('');
  };

  return (
    <SelectDoctorContainer removeBar={removeBar}>
      {isEdit && itemEdit === index ? (
        <>
          <SelectDoctor defaultValue="" onChange={onChangeHandler}>
            <SelectInputOption value="" disabled>
              Select Doctor
            </SelectInputOption>
            {doctorList.map((doctor, index) => {
              return (
                <SelectInputOption key={index} value={`${doctor._id}`}>
                  {`${doctor.personalInfo.firstName} ${doctor.personalInfo.lastName}`}
                </SelectInputOption>
              );
            })}
          </SelectDoctor>
          <IconContainer onClick={onCancelHandler}>
            <CancelIcon />
          </IconContainer>
        </>
      ) : (
        <AssignedDoctorContainer removeBar={removeBar} onClick={() => onEditHandler(chart.doctorId, index)} isWarning={chart.doctorId === 'Select Doctor'}>
          {chart.doctorId === 'Select Doctor' ? (
            'Select Doctor'
          ) : (
            <>
              {doctorList.map((doctor) => {
                if (doctor._id === chart.doctorId) {
                  return `${doctor.personalInfo.firstName} ${doctor.personalInfo.lastName}`;
                } else {
                  return null;
                }
              })}
            </>
          )}
        </AssignedDoctorContainer>
      )}
    </SelectDoctorContainer>
  );
};

export default SelectDoctorList;
