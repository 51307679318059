import styled from 'styled-components/macro';

export const CardDataContainer = styled.div`
  display: flex;
  padding: 5px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  width: 25%;
  font-weight: bold;
  padding-top: 5px;
`;

export const MedCertDataContent = styled.div`
  margin: 5px 40px;
`;

export const RemarksContainer = styled.div`
  padding: 10px 15px 0px 15px;
`;

export const CertifyText = styled.div`
  font-size: 0.8rem;
  text-align: center;
  padding: 5px 20px;
`;
