import styled from 'styled-components/macro';

export const ChartInfoLabelEditable = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.8rem;
  padding: 0px 0px 0px 10px;
  width: ${(props) => props.width};
  display: flex;

  &:hover {
    color: ${(props) => props.theme.FontColor};
  }
`;

export const ChartInfoLabelEditableText = styled.div`
  width: 229px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  padding: 8px;
  margin: 2px;
  text-align: left;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  height: 33px;

  &:hover {
    color: ${(props) => props.theme.MainNav};
    background-color: ${({ theme }) => theme.CardBorder};
  }
`;

export const DateInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 7px 15px;
  font-size: 0.8rem;
  margin: 2px;
  width: 160px;
  border-radius: 3px;
`;

export const ViewChartButtonContainer = styled.div`
  display: block;
  padding-top: 5px;
`;

export const IconsContainer = styled.div`
  display: flex;
  padding: 2px;
`;

export const IconContainer = styled.div`
  display: block;
`;
