import * as yup from 'yup';

const PatientFormSchema = yup.object().shape({
  personalInfo: yup.object().shape({
    firstName: yup.string().required('Required'),
    middleName: yup.string(),
    lastName: yup.string().required('Required'),
    sex: yup.string().required('Required'),
    dateOfBirth: yup.date().required('Required'),
    birthPlace: yup.string(),
    civilStatus: yup.string(),
    nationality: yup.string(),
    bloodType: yup.string(),
    image: yup.string(),
  }),
  contactInfo: yup.object().shape({
    emailAddress: yup.string().email('Invalid'),
    mobileNumber: yup.string(),
    currentAddress: yup.object().shape({
      houseNumberAndStreet: yup.string(),
      subdivision: yup.string(),
      barangay: yup.string(),
      cityOrMunicipality: yup.string(),
      province: yup.string(),
      zip: yup.number().min(1000, 'Min value is 1000').max(9999, 'Max value of 9999'),
    }),
    provincialAddress: yup.object().shape({
      houseNumberAndStreet: yup.string(),
      subdivision: yup.string(),
      barangay: yup.string(),
      cityOrMunicipality: yup.string(),
      province: yup.string(),
      zip: yup.number().min(1000, 'Min value is 1000').max(9999, 'Max value of 9999'),
    }),
  }),
  emergencyContact: yup.object().shape({
    name: yup.string(),
    relationship: yup.string(),
    emailAddress: yup.string(),
    mobileNumber: yup.string(),
  }),
  otherInfo: yup.object().shape({
    hmo: yup.string(),
    hmoNumber: yup.string(),
    philhealthNumber: yup.string(),
    sssNumber: yup.string(),
    tinNumber: yup.string(),
  }),
});

export default PatientFormSchema;
