import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CardContainer } from '../CardResources/Card.styled';
import CardHeaderBar from '../CardResources/CardHeaderBar';

import { updateCardInChart, setIsCardEdit, addCardToChart, setIsNewCard, setNewCardType, setNewCardData as setNewCardData2 } from '../../../../../../../store/storeSlices/cards';

import ViewOphthaCard from './ViewOphthaCard';
import EditOpthaCard from './EditOpthaCard';
import EditCardModal from '../CardResources/EditCardModal';

const OphthaCard = ({ cardData, cardIndex, isNew }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const chartId = useSelector((state) => state.chart.chartData._id);
  const currentUser = useSelector((state) => state.user.userData);
  const [isEdit, setIsEdit] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [plan, setPlan] = useState(cardData.cardData.plan);
  const [newCardData, setNewCardData] = useState(cardData.cardData);
  const [diagnosisList, setDiagnosisList] = useState(cardData.cardData.diagnosisList);

  const updateContentHandler = (content) => {
    setPlan(content);
  };

  const onUpdateDiagnosisListHandler = (updatedList) => {
    setDiagnosisList(updatedList);
  };

  const onChangeHandler = (name, data) => {
    setNewCardData({ ...newCardData, [name]: data });
  };

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const clearAddNewHandler = () => {
    dispatch(setIsNewCard(false));
    dispatch(setNewCardType(''));
    dispatch(setNewCardData2({}));
  };

  const onEditHandler = () => {
    dispatch(setIsCardEdit(true));
    setIsEdit(true);
    clearAddNewHandler();
  };

  const onCloseModalHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };

  const onSubmitHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    isNew
      ? dispatch(
          addCardToChart({
            method: 'post',
            url: `card/add/${chartId}`,
            token,
            data: { cardType: cardData.cardType, cardData: { ...newCardData, diagnosisList, plan }, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` },
          })
        )
      : dispatch(updateCardInChart({ method: 'patch', url: `card/update/${chartId}?index=${cardIndex}`, token, data: { cardData: { ...newCardData, diagnosisList, plan } } }));
    clearAddNewHandler();
  };

  return (
    <CardContainer>
      <CardHeaderBar title={'Ophthalmologist Card'} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
      {isEdit || isNew ? (
        <EditCardModal onCloseModal={onCloseModalHandler} title={'Opthalmologist Card'} handleSubmit={onSubmitHandler}>
          <EditOpthaCard diagnosisList={diagnosisList} onUpdateDiagnosisList={onUpdateDiagnosisListHandler} plan={plan} onUpdateContent={updateContentHandler} newCardData={newCardData} onChange={onChangeHandler} />
        </EditCardModal>
      ) : (
        <ViewOphthaCard cardData={cardData.cardData} isHidden={isHidden} />
      )}
    </CardContainer>
  );
};

export default OphthaCard;
