import { configureStore } from '@reduxjs/toolkit';

import settingsReducer from './storeSlices/settings';
import dataListReducer from './storeSlices/dataList';
import userReducer from './storeSlices/user';
import chartReducer from './storeSlices/charts';
import cardReducer from './storeSlices/cards';
import patientReducer from './storeSlices/patients';
import medicineReducer from './storeSlices/medicine';

const store = configureStore({
  reducer: {
    chart: chartReducer,
    card: cardReducer,
    user: userReducer,
    settings: settingsReducer,
    dataList: dataListReducer,
    patient: patientReducer,
    medicine: medicineReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
