import styled from 'styled-components/macro';

export const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const HeaderLogoContainer = styled.div`
  background-color: ${(props) => props.backgroundColor} !important;
  padding: 2px 10px 0px 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    padding: 1px 4px 1px 4px;
  }
`;

export const SmallLogoImage = styled.img`
  margin: 0px 10px;
  height: 38px;
  padding: 2px;

  @media (max-width: 576px) {
    height: 35px;
  }
`;

export const CompanyName = styled.p`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor} !important;
  font-family: ${(props) => props.fontStyle} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: 1.5rem;
  margin: 0px 10px 0px 0px;

  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

export const CompanyText = styled.p`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor} !important;
  font-family: ${(props) => props.fontStyle} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: 0.9rem;
  padding-top: 4px;
  margin: 0px;

  @media (max-width: 768px) {
    display: none;
  }
`;
