import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MainHeader, Container, RightSide, UserName, AvatarContainer, Avatar, NoAvatar, MainNavContainer } from './Header.styled';

import CompanyInfo from './HeaderComponents/CompanyInfo';
import TopNavigation from './HeaderComponents/TopNavigation';
import MainNav from './HeaderComponents/MainNav';

function Header() {
  const [userActionHidden, setUserActionHidden] = useState(true);
  const currentUser = useSelector((state) => state.user && state.user.userData);

  const hideNav = () => {
    setUserActionHidden(true);
  };

  return (
    <MainHeader>
      <Container>
        <CompanyInfo />
        <RightSide>
          <TopNavigation />
          <UserName>{currentUser ? currentUser.userAlias : ''}</UserName>
          <AvatarContainer
            onMouseOver={() => {
              setUserActionHidden(false);
            }}
            onMouseLeave={() => {
              setUserActionHidden(true);
            }}
          >
            {currentUser && (currentUser.userAvatar ? <Avatar src={currentUser.userAvatar} alt="user avatar" /> : <NoAvatar>{currentUser.userInitials}</NoAvatar>)}
          </AvatarContainer>
          <MainNavContainer
            maxHeight={userActionHidden ? '0px' : '300px'}
            onMouseOver={() => {
              setUserActionHidden(false);
            }}
            onMouseLeave={() => {
              setUserActionHidden(true);
            }}
          >
            <MainNav currentUser={currentUser} hideNav={hideNav} />
          </MainNavContainer>
        </RightSide>
      </Container>
    </MainHeader>
  );
}

export default Header;
