import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';
import { CardDataContainer, Label, Value, Table, TableHeader, TableData, DiagnosisList, PlanContainer } from './OphthaCard.styled';

import RichText from '../CardResources/RichText/RichText';

const ViewOphthaCard = ({ cardData, isHidden }) => {
  return (
    <CardBodyContainer isHidden={isHidden}>
      <CardDataContainer>
        <Label>History of Present Illness (HPI):</Label>
        <Value>{cardData.hpi}</Value>
      </CardDataContainer>
      <CardDataContainer>
        <Label>Past Medical History (PMH):</Label>
        <Value>{cardData.pmh}</Value>
      </CardDataContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader noLabel={true}></TableHeader>
            <TableHeader>Right Eye</TableHeader>
            <TableHeader>Left Eye</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableData bold={true}>Intraocular Pressure (IOP):</TableData>
            <TableData>{cardData.iop_right}</TableData>
            <TableData>{cardData.iop_left}</TableData>
          </tr>
          <tr>
            <TableData bold={true}>Extraocular Muscle (EOM):</TableData>
            <TableData>{cardData.eom_right}</TableData>
            <TableData>{cardData.eom_left}</TableData>
          </tr>
          <tr>
            <TableData bold={true}>Biomicroscopy Examination:</TableData>
            <TableData>{cardData.oa_right}</TableData>
            <TableData>{cardData.oa_left}</TableData>
          </tr>
          <tr>
            <TableData bold={true}>Funduscopy:</TableData>
            <TableData>{cardData.fundus_right}</TableData>
            <TableData>{cardData.fundus_left}</TableData>
          </tr>
        </tbody>
      </Table>
      <CardDataContainer>
        <Label>Assessment and Diagnosis:</Label>
        {cardData &&
          cardData.diagnosisList &&
          cardData.diagnosisList.map((diagnosis, index) => {
            return <DiagnosisList key={index}>{`[${diagnosis.icd10}] ${diagnosis.diagnosis} - ${diagnosis.eye}`}</DiagnosisList>;
          })}
      </CardDataContainer>
      <CardDataContainer>
        <Label>Management and Plan:</Label>
        <PlanContainer>
          <RichText data={cardData.plan} isView={true} />
        </PlanContainer>
      </CardDataContainer>
    </CardBodyContainer>
  );
};

export default ViewOphthaCard;
