import styled from 'styled-components/macro';

export const ClinicInfoContainer = styled.div`
  display: flex;
  padding: 5px;
  height: 90px;
  border-bottom: 1px solid black;
`;

export const ClinicLogoContainer = styled.div`
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClinicLogo = styled.img`
  width: auto;
  height: 60px;
`;

export const ClinicCompanyNameContainer = styled.div`
  padding: 10px;
  width: 420px;
`;

export const ClinicName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
`;

export const ClinicAddress = styled.div`
  font-size: 0.7rem;
`;
