import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';

import { updateCardInChart, addCardToChart, setIsCardEdit, setIsNewCard, setNewCardType, setNewCardData } from '../../../../../../../store/storeSlices/cards';

import { CardContainer } from '../CardResources/Card.styled';
import CardHeaderBar from '../CardResources/CardHeaderBar';

import ViewProcedureCard from './ViewProcedureCard';
import Printer from '../CardResources/Printer/Printer';
import EditCardModal from '../CardResources/EditCardModal';
import ProcedureList from '../CardResources/ProcedureList';

const ProcedureCard = ({ cardData, cardIndex, isNew }) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const chartId = useSelector((state) => state.chart.chartData._id);
  const currentUser = useSelector((state) => state.user.userData);
  const [isEdit, setIsEdit] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [newProcedure, setNewProcedure] = useState(cardData.cardData);

  const handleBeforeGetContent = () => {
    setIsPrint(true);
    return Promise.resolve();
  };

  const onPrintHandler = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `patient_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const clearAddNewHandler = () => {
    dispatch(setIsNewCard(false));
    dispatch(setNewCardType(''));
    dispatch(setNewCardData({}));
  };

  const onEditHandler = () => {
    dispatch(setIsCardEdit(true));
    setIsEdit(true);
    clearAddNewHandler();
  };

  const onCloseModalHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };

  const onupdateHandler = (data) => {
    setNewProcedure(data);
  };

  const onSubmitHandler = () => {
    setIsEdit(false);
    isNew
      ? dispatch(addCardToChart({ method: 'post', url: `card/add/${chartId}`, token, data: { cardType: cardData.cardType, cardData: newProcedure, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` } }))
      : dispatch(updateCardInChart({ method: 'patch', url: `card/update/${chartId}?index=${cardIndex}`, token, data: { cardData: newProcedure } }));
    clearAddNewHandler();
  };

  return (
    <CardContainer>
      <CardHeaderBar title={'Procedure Card'} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isPrint={true} onPrint={onPrintHandler} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
      {isEdit || isNew ? (
        <EditCardModal onCloseModal={onCloseModalHandler} title={'Procedure Card'} handleSubmit={onSubmitHandler}>
          <ProcedureList procedureList={newProcedure} onUpdate={onupdateHandler} />
        </EditCardModal>
      ) : (
        <>
          {isPrint ? (
            <div ref={componentRef}>
              <Printer title={'PROCEDURE'} includeDoctor={true}>
                <ViewProcedureCard cardData={cardData.cardData} />
              </Printer>
            </div>
          ) : (
            <ViewProcedureCard cardData={cardData.cardData} isHidden={isHidden} />
          )}
        </>
      )}
    </CardContainer>
  );
};

export default ProcedureCard;
