import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios/axios';
import { toast } from 'react-toastify';

export const searchPatient = createAsyncThunk('patient/searchPatient', async (action) => {
  return await apiRequest(action);
});

export const updatePatientListSilently = createAsyncThunk('patient/updatePatientListSilently', async (action) => {
  return await apiRequest(action);
});

export const createNewPatient = createAsyncThunk('patient/createNewPatient', async (action) => {
  return await apiRequest(action);
});

export const getPatientById = createAsyncThunk('patient/getPatientById', async (action) => {
  return await apiRequest(action);
});

export const getPatientByIdSilently = createAsyncThunk('patient/getPatientByIdSilently', async (action) => {
  return await apiRequest(action);
});

export const updatePatientById = createAsyncThunk('patient/updatePatientById', async (action) => {
  console.log(action);
  return await apiRequest(action);
});

const initialState = {
  tokenExpired: false,
  searchResult: [],
  patientData: {},
  status: 'idle',
  numberOfPages: 0,
  triggerChartListRefresh: null,
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    clearPatientData: (state) => {
      state.patientData = {};
    },
    setPatientTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // search patient
      .addCase(searchPatient.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchPatient.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let numberOfPages = action.payload.data.numberOfPages;
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.searchResult = data;
          state.numberOfPages = numberOfPages;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // update patients list silently
      .addCase(updatePatientListSilently.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.searchResult = data;
          state.numberOfPages = data.numberOfPages;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get patient data by Id
      .addCase(getPatientById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.patientData = data;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get patient data by Id silently
      .addCase(getPatientByIdSilently.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.patientData = data;
        } else if (status === 401) {
          toast.error(message);
          state.tokenExpired = true;
        } else {
          toast.error(message);
          console.log(action.payload);
        }
      })

      // update patient by id
      .addCase(updatePatientById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePatientById.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          toast.success(message);
          state.patientData = data;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // create new patient
      .addCase(createNewPatient.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createNewPatient.fulfilled, (state, action) => {
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          toast.success(message);
          state.triggerChartListRefresh = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      });
  },
});

export const { clearPatientData, setPatientTokenExpired } = patientSlice.actions;
export default patientSlice.reducer;
