import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChartDataContainer, ChartDataHeader, ChartDataBody } from './ChartData.styled';

import GenericCard from './Cards/GenericCard/GenericCard';
import GenericRichTextCard from './Cards/GenericRichTextCard/GenericRichTextCard';
import OphthaCard from './Cards/OphthaCard/OphthaCard';
import ProcedureCard from './Cards/ProcedureCard/ProcedureCard';
import PrescriptionCard from './Cards/PrescriptionCard/PrescriptionCard';
import BillingCard from './Cards/BillingCard/BillingCard';
import MedCertCard from './Cards/MedCertCard/MedCertCard';
import PatientCard from './Cards/PatientCard/PatientCard';
import { ModalLoader } from '../../../../../assets/loader';

const ViewChartData = () => {
  const chartData = useSelector((state) => state.chart.chartData);
  const status = useSelector((state) => state.chart.status);
  const isNewCard = useSelector((state) => state.card.isNewCard);
  const newCardType = useSelector((state) => state.card.newCardType);
  const newCardData = useSelector((state) => state.card.newCardData);
  const [medCertDiagnosis, setMedCertDiagnosis] = useState([]);
  const [medCertRemarks, setMedCertRemarks] = useState({ blocks: [{ key: 'jv6k', text: ' ', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }], entityMap: {} });

  useEffect(() => {
    chartData &&
      chartData.cards &&
      chartData.cards.forEach((card) => {
        card.cardType === 'ophtha_card' && setMedCertDiagnosis(card.cardData.diagnosisList);
        card.cardType === 'remarks_card' && setMedCertRemarks(card.cardData);
      });
  }, [isNewCard, newCardType, chartData]);

  return (
    <ChartDataContainer>
      <ChartDataHeader>Patient's Chart Data</ChartDataHeader>
      <ChartDataBody>
        {status === 'loading' ? (
          <ModalLoader />
        ) : (
          <>
            <PatientCard cardData={chartData} />
            {chartData &&
              chartData.cards &&
              chartData.cards.map((card, index) => {
                return <div key={index}>{card.cardType === 'opto_card' && <GenericCard cardIndex={index} cardData={card} title={'Optometrist Card'} />}</div>;
              })}
            {chartData &&
              chartData.cards &&
              chartData.cards.map((card, index) => {
                return (
                  <div key={index}>
                    {card.cardType === 'nurse_card' && <GenericCard cardIndex={index} cardData={card} title={'Nurse Card'} />}
                    {card.cardType === 'refrac_card' && <GenericCard cardIndex={index} cardData={card} title={'Refraction Card'} />}
                    {card.cardType === 'lab_request_card' && <GenericRichTextCard cardIndex={index} cardData={card} title={'Laboratory Request Card'} showPrint={true} printTitle={'LABORATORY REQUEST'} includeDoctor={true} />}
                    {card.cardType === 'referral_card' && <GenericRichTextCard cardIndex={index} cardData={card} title={'Referral Card'} showPrint={true} printTitle={'REFERRAL'} includeDoctor={true} />}
                    {card.cardType === 'remarks_card' && <GenericRichTextCard cardIndex={index} cardData={card} title={'Remarks Card'} />}
                    {card.cardType === 'ophtha_card' && <OphthaCard cardIndex={index} cardData={card} />}
                    {card.cardType === 'procedure_card' && <ProcedureCard cardIndex={index} cardData={card} />}
                    {card.cardType === 'prescription_card' && <PrescriptionCard cardIndex={index} cardData={card} />}
                    {card.cardType === 'med_cert_card' && <MedCertCard cardIndex={index} cardData={card} />}
                    {card.cardType === 'billing_card' && <BillingCard cardIndex={index} cardData={card} />}
                  </div>
                );
              })}
            {isNewCard && newCardType === 'nurse_card' && <GenericCard cardData={newCardData} title={'Nurse Card'} isNew={isNewCard} />}
            {isNewCard && newCardType === 'opto_card' && <GenericCard cardData={newCardData} title={'Optometrist Card'} isNew={isNewCard} />}
            {isNewCard && newCardType === 'refrac_card' && <GenericCard cardData={newCardData} title={'Refraction Card'} isNew={isNewCard} />}

            {isNewCard && newCardType === 'lab_request_card' && <GenericRichTextCard cardData={newCardData} title={'Laboratory Request Card'} isNew={isNewCard} />}
            {isNewCard && newCardType === 'referral_card' && <GenericRichTextCard cardData={newCardData} title={'Referral Card'} isNew={isNewCard} />}
            {isNewCard && newCardType === 'remarks_card' && <GenericRichTextCard cardData={newCardData} title={'Remarks Card'} isNew={isNewCard} />}

            {isNewCard && newCardType === 'ophtha_card' && <OphthaCard cardData={newCardData} isNew={isNewCard} />}
            {isNewCard && newCardType === 'procedure_card' && <ProcedureCard cardData={newCardData} isNew={isNewCard} />}
            {isNewCard && newCardType === 'prescription_card' && <PrescriptionCard cardData={newCardData} isNew={isNewCard} />}
            {isNewCard && newCardType === 'med_cert_card' && <MedCertCard cardData={{ cardData: { diagnosis: medCertDiagnosis, remarks: medCertRemarks }, cardType: newCardType }} isNew={isNewCard} />}
          </>
        )}
      </ChartDataBody>
    </ChartDataContainer>
  );
};

export default ViewChartData;
