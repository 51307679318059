import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { verifyToken } from './store/storeSlices/user';

import { ThemeProvider } from 'styled-components';

import LoggedOut from './AppModule/LoggedOut/LoggedOut';
import LoggedIn from './AppModule/LoggedIn/LoggedIn';

function App() {
  const dispatch = useDispatch();

  const userIsLoggedIn = useSelector((state) => state.user.isLoggedIn && state.user.isLoggedIn);
  const activeTheme = useSelector((state) => state.settings && state.settings.activeTheme && state.settings.activeTheme);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('clinicAppUserData'));
    if (userData && userData.userToken && userData.userId) {
      dispatch(verifyToken({ method: 'post', url: `user/verifyToken/${userData.userId}`, token: userData.userToken }));
    }
  }, [dispatch]);

  return <ThemeProvider theme={activeTheme}>{userIsLoggedIn ? <LoggedIn /> : <LoggedOut />}</ThemeProvider>;
}

export default App;
