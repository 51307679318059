import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios/axios';
import { toast } from 'react-toastify';

export const updateCardInChart = createAsyncThunk('chart/updateCardInChart', async (action) => {
  return await apiRequest(action);
});

export const addCardToChart = createAsyncThunk('chart/addCardToChart', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  tokenExpired: false,
  isCardEdit: false,
  isNewCard: false,
  newCardType: '',
  newCardData: {},
  status: 'idle',
  triggerRefreshChartData: 'idle',
};

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setIsCardEdit: (state, action) => {
      state.isCardEdit = action.payload;
    },
    setIsNewCard: (state, action) => {
      state.isNewCard = action.payload;
    },
    setNewCardType: (state, action) => {
      state.newCardType = action.payload;
    },
    setNewCardData: (state, action) => {
      state.newCardData = action.payload;
    },
    setCardTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // update Card in Chart
      .addCase(updateCardInChart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCardInChart.fulfilled, (state, action) => {
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'success';
          toast.success(message);
          state.triggerRefreshChartData = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // update Card in Chart
      .addCase(addCardToChart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCardToChart.fulfilled, (state, action) => {
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'success';
          toast.success(message);
          state.triggerRefreshChartData = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      });
  },
});

export const { setIsCardEdit, setIsNewCard, setNewCardType, setNewCardData, setCardTokenExpired } = cardSlice.actions;
export default cardSlice.reducer;
