import React from 'react';
import { useSelector } from 'react-redux';

import { ClinicInfoContainer, ClinicLogoContainer, ClinicCompanyNameContainer, ClinicLogo, ClinicName, ClinicAddress } from './ClinicInfo.styled';

function ClinicInfo() {
  const clinicSiteList = useSelector((state) => state.dataList.dataList.clinicSiteList);
  const clinicSite = useSelector((state) => state.user.clinicSite);
  const clinicSiteData = clinicSiteList.filter((site) => site.id === clinicSite)[0];

  return (
    <ClinicInfoContainer>
      <ClinicLogoContainer>
        <ClinicLogo src={`${process.env.REACT_APP_FILES_URL}mainLogo.png`} />
      </ClinicLogoContainer>
      <ClinicCompanyNameContainer>
        <ClinicName>{clinicSiteData && clinicSiteData.name}</ClinicName>
        <ClinicAddress>{clinicSiteData && clinicSiteData.address1}</ClinicAddress>
        <ClinicAddress>{clinicSiteData && clinicSiteData.address2}</ClinicAddress>
        <ClinicAddress>{clinicSiteData && clinicSiteData.contact}</ClinicAddress>
      </ClinicCompanyNameContainer>
    </ClinicInfoContainer>
  );
}

export default ClinicInfo;
