import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios/axios';
import { toast } from 'react-toastify';

export const getAllCharts = createAsyncThunk('chart/getAllCharts', async (action) => {
  return await apiRequest(action);
});

export const getAllChartsSilently = createAsyncThunk('chart/getAllChartsSilently', async (action) => {
  return await apiRequest(action);
});

export const getChartListByPatientId = createAsyncThunk('chart/getChartListByPatientId', async (action) => {
  return await apiRequest(action);
});

export const getChartListByPatientIdSilently = createAsyncThunk('chart/getChartListByPatientIdSilently', async (action) => {
  return await apiRequest(action);
});

export const updateChartStatus = createAsyncThunk('chart/updateChartStatus', async (action) => {
  return await apiRequest(action);
});

export const reassignChart = createAsyncThunk('chart/reassignChart', async (action) => {
  return await apiRequest(action);
});

export const rescheduleChart = createAsyncThunk('chart/rescheduleChart', async (action) => {
  return await apiRequest(action);
});

export const createNewChart = createAsyncThunk('chart/createNewChart', async (action) => {
  return await apiRequest(action);
});

export const getChartDataById = createAsyncThunk('chart/getChartDataById', async (action) => {
  return await apiRequest(action);
});

export const getChartDataByIdSilently = createAsyncThunk('chart/getChartDataByIdSilently', async (action) => {
  return await apiRequest(action);
});

export const updateCardInChart = createAsyncThunk('chart/updateCardInChart', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  tokenExpired: false,
  allChartsData: [],
  chartList: [],
  chartData: {},
  assignedDoctor: '',
  triggerRefreshChartList: 'idle',
  status: 'idle',
  listStatus: 'idle',
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    clearChartData: (state) => {
      state.chartData = {};
      state.assignedDoctor = '';
    },
    setChartTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // get all charts
      .addCase(getAllCharts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCharts.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.allChartsData = data;
        } else if (status === 401) {
          state.status = 'failed';
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get all charts Silently
      .addCase(getAllChartsSilently.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.allChartsData = data;
        } else if (status === 401) {
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get chart list by patient id
      .addCase(getChartListByPatientId.pending, (state) => {
        state.listStatus = 'loading';
      })
      .addCase(getChartListByPatientId.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.listStatus = 'succeeded';
          state.chartList = data;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.listStatus = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get chart list by patient id silently
      .addCase(getChartListByPatientIdSilently.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];

        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.chartList = data;
        } else if (status === 401) {
          toast.error(message);
          state.tokenExpired = true;
        } else {
          toast.error(message);
          console.log(action.payload);
        }
      })

      // update chart status
      .addCase(updateChartStatus.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          toast.success(message);
          state.chartData = data;
          state.triggerRefreshChartList = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // reassign chart
      .addCase(reassignChart.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          toast.success(message);
          state.chartData = data;
          state.triggerRefreshChartList = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // reschedule chart
      .addCase(rescheduleChart.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          toast.success(message);
          state.chartData = data;
          state.triggerRefreshChartList = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // create new chart
      .addCase(createNewChart.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          toast.success(message);
          state.chartData = data;
          state.triggerRefreshChartList = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get chart data by id
      .addCase(getChartDataById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChartDataById.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.chartData = data;
          state.assignedDoctor = data.doctorId;
          state.triggerRefreshChartList = new Date();
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })

      // get chart data by id silently
      .addCase(getChartDataByIdSilently.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : {};
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.chartData = data;
          state.assignedDoctor = data.doctorId;
        } else if (status === 401) {
          toast.error(message);
          state.tokenExpired = true;
        } else {
          toast.error(message);
          console.log(action.payload);
        }
      });
  },
});

export const { clearChartData, setChartTokenExpired } = chartSlice.actions;
export default chartSlice.reducer;
