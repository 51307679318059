import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios/axios';
import { toast } from 'react-toastify';

export const getMedicineList = createAsyncThunk('inventory/getMedicineList', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  tokenExpired: false,
  medicineList: [],
  status: 'idle',
};

export const medicineSlice = createSlice({
  name: 'medicine',
  initialState,
  reducers: {
    setMedicineTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // Get medicine list
      .addCase(getMedicineList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getMedicineList.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.medicineList = data;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      })
      .addCase(getMedicineList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      });
  },
});

export const { setMedicineTokenExpired } = medicineSlice.actions;
export default medicineSlice.reducer;
