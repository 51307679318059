import styled, { keyframes } from 'styled-components/macro';

const loadInfoBar = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

export const PatientInfoBar = styled.li`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  border-left: 5px solid ${({ theme }) => theme.CardHeader};
  transition: all 0.2s ease-in-out;
  animation-name: ${loadInfoBar};
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;

  &:hover {
    border: 1px solid ${({ theme }) => theme.CardHeader};
    border-left: 5px solid ${({ theme }) => theme.CardHeader};
  }
`;

export const PatientInfoBarContainer = styled.ul`
  max-height: calc(100vh - 180px);
  padding: 0px 5px 0px 0px;
  margin: 0px;
  overflow-y: scroll;
`;

export const PatientInfoContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const PatientInfoLabel = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 0px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }
  @media (max-width: 1000px) {
    display: ${(props) => props.label === 'PREFFEREDNAME' && 'none'};
  }

  @media (max-width: 800px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
  }

  @media (max-width: 600px) {
    display: ${(props) => props.label === 'BIRTHDATE' && 'none'};
  }

  @media (max-width: 460px) {
    display: ${(props) => props.label === 'SEX' && 'none'};
  }
`;

export const PatientAvatarContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  width: 45px;
  padding-top: 2px;

  @media (max-width: 1195px) {
    display: ${(props) => props.label === 'IMAGE' && 'none'};
  }
`;

export const PatientAvatar = styled.img`
  width: 35px;
  height: 35px;
  padding: 0px;
  margin: 0px 4px 0px 4px;
`;

export const PatientNameContainer = styled.div`
  padding: 2px 5px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  display: flex;
  align-items: center;

  & div {
    cursor: pointer;
    color: ${({ theme }) => theme.FontColor};
    font-size: 0.8rem;
    padding: 8px;
    width: 290px;
    border: 1px solid ${({ theme }) => theme.CardBorder};
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    &:hover {
      color: ${(props) => props.theme.MainNav};
      background-color: white;
    }
  }
`;
