import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DiagnosisListContainer, DiagnosisDataContainer, InlineInputContainer, InlineInput, InlineSelect, InfoData } from './DiagnosisList.styled';
import { DiagnosisAddDataContainer, AddOthersContainer, ChartCardInputContainer, InfoLabel, InfoInput, DiagnosisContainer, SelectInputDiagnosis } from './DiagnosisList.styled';
import { IconsContainer, EditIcon, SaveIcon, DeleteIcon, CancelIcon } from '../../../../../../../assets/icons';
import { ButtonsContainer, Button1, Button2, Button3, Button4 } from '../../../../../../../assets/buttons';

const DiagnosisList = ({ diagnosisList, onUpdateDiagnosisList }) => {
  const options = useSelector((state) => state.dataList && state.dataList.dataList.diagnosisList);
  const [list, setList] = useState(diagnosisList ? diagnosisList : []);
  const [lineIsEdit, setLineIsEdit] = useState(false);
  const [lineEditIndex, setLineEditIndex] = useState(null);
  const [lineIsEditIcd10, setLineIsEditIcd10] = useState('');
  const [lineIsEditDiagnosis, setLineIsEditDiagnosis] = useState('');
  const [lineIsEditEyes, setLineIsEditEyes] = useState('');
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('');
  const [otherDiagnosis, setOtherDiagnosis] = useState('');
  const [otherIcd10, setOtherIcd10] = useState('');
  const [addOthers, setAddOthers] = useState(false);

  const handleAddDiagnosis = (option, eye) => {
    setList([...list, { icd10: option.icd10, diagnosis: option.diagnosis, eye }]);
    setSelectedDiagnosis([]);
    setOtherDiagnosis('');
    setOtherIcd10('');
  };

  const handleEditLine = (index) => {
    setLineIsEdit(true);
    setLineEditIndex(index);
    setLineIsEditIcd10(list[index].icd10);
    setLineIsEditDiagnosis(list[index].diagnosis);
    setLineIsEditEyes(list[index].eye);
  };

  const handleSaveLine = (index) => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    let newData = list.map((data, i) => {
      if (i === index) {
        return { diagnosis: lineIsEditDiagnosis, icd10: lineIsEditIcd10, eye: lineIsEditEyes };
      } else {
        return data;
      }
    });
    setList(newData);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  const handleCancelLine = () => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  const handleDeleteDiagnosis = (diagnosis) => {
    setList(list.filter((item) => item.diagnosis !== diagnosis));
  };

  useEffect(() => {
    selectedDiagnosis === 'others' ? setAddOthers(true) : setAddOthers(false);
  }, [selectedDiagnosis]);

  useEffect(() => {
    onUpdateDiagnosisList(list);
  }, [onUpdateDiagnosisList, list]);

  return (
    <DiagnosisListContainer>
      {list.map((diagnosis, index) => {
        return (
          <DiagnosisDataContainer key={index}>
            {lineIsEdit && lineEditIndex === index ? (
              <InlineInputContainer>
                <InlineInput value={lineIsEditIcd10} onChange={(e) => setLineIsEditIcd10(e.target.value)} />
                <InlineInput value={lineIsEditDiagnosis} onChange={(e) => setLineIsEditDiagnosis(e.target.value)} width={'70%'} />
                <InlineSelect value={lineIsEditEyes} onChange={(e) => setLineIsEditEyes(e.target.value)}>
                  <option>Left Eye</option>
                  <option>Right Eye</option>
                  <option>Both Eyes</option>
                </InlineSelect>
              </InlineInputContainer>
            ) : (
              <InfoData border={'none'}>
                {`[ ${diagnosis.icd10} ] ${diagnosis.diagnosis} -`} <b>{diagnosis.eye}</b>
              </InfoData>
            )}

            <IconsContainer>
              {lineIsEdit && lineEditIndex === index ? (
                <>
                  <div onClick={() => handleSaveLine(index)}>
                    <SaveIcon />
                  </div>
                  <div onClick={() => handleCancelLine()}>
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <>
                  <div onClick={() => handleEditLine(index)}>
                    <EditIcon />
                  </div>
                  <div onClick={() => handleDeleteDiagnosis(diagnosis.diagnosis)}>
                    <DeleteIcon />
                  </div>
                </>
              )}
            </IconsContainer>
          </DiagnosisDataContainer>
        );
      })}
      <DiagnosisAddDataContainer>
        {addOthers ? (
          <AddOthersContainer>
            <ChartCardInputContainer display={'flex'} width={'90%'}>
              <InfoLabel width={'90px'} padding={'5px 10px 0px 0px'}>
                ICD 10:{' '}
              </InfoLabel>
              <InfoInput width={'80%'} value={otherIcd10} onChange={(e) => setOtherIcd10(e.target.value)} />
            </ChartCardInputContainer>
            <ChartCardInputContainer display={'flex'} width={'90%'}>
              <InfoLabel width={'90px'} padding={'5px 10px 0px 0px'}>
                Diagnosis:{' '}
              </InfoLabel>
              <InfoInput width={'80%'} rows={'3'} value={otherDiagnosis} onChange={(e) => setOtherDiagnosis(e.target.value)} />
            </ChartCardInputContainer>
            <ButtonsContainer>
              <Button1 onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Left Eye')}>Left</Button1>
              <Button2 onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Right Eye')}>Right</Button2>
              <Button3 onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Both Eyes')}>Both</Button3>
              <Button4 onClick={() => setSelectedDiagnosis('')}>Cancel</Button4>
            </ButtonsContainer>
          </AddOthersContainer>
        ) : (
          <DiagnosisContainer>
            <SelectInputDiagnosis value={selectedDiagnosis} onChange={(e) => setSelectedDiagnosis(e.target.value)}>
              <option disabled value="">
                -- select diagnosis --
              </option>
              {options &&
                options.map((data, index) => {
                  return (
                    <option key={index} value={index}>
                      {data.diagnosis}
                    </option>
                  );
                })}
              <option value="others">-- others --</option>
            </SelectInputDiagnosis>
            <ButtonsContainer>
              <Button1 onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Left Eye')}>Left</Button1>
              <Button2 onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Right Eye')}>Right</Button2>
              <Button3 onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Both Eyes')}> Both</Button3>
            </ButtonsContainer>
          </DiagnosisContainer>
        )}
      </DiagnosisAddDataContainer>
    </DiagnosisListContainer>
  );
};

export default DiagnosisList;
