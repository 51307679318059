import styled from 'styled-components/macro';

export const DoctorInfoContainer = styled.div`
  display: flex;
  justify-content: end;
  align-self: baseline;
  margin: 20px 0px;
`;

export const DoctorInfoDetails = styled.div`
  padding: 5px;
  width: 300px;
  margin: 5px 40px;
  text-align: center;
  justify-content: end;
`;

export const DoctorName = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  border-bottom: 1px solid black;
`;

export const DoctorInfo = styled.div`
  font-size: 0.7rem;
`;
