import styled, { keyframes } from 'styled-components/macro';

const loadCardAnimation = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin-bottom: 5px;
  background-color: white;
  box-shadow: 1px 1px 5px lightgray;
  border-radius: 3px;
  animation-name: ${loadCardAnimation};
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;
`;

export const CardBodyContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.isHidden ? '0px' : '10px')};
  border-radius: 3px;
  max-height: ${(props) => (props.isHidden ? '0px' : '2000px')};
  transition: all 0.5s ease-in-out;
  overflow: hidden;
`;
