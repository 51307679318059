import moment from 'moment';

const InitialValues = (patientData) => {
  let initialValues = {
    personalInfo: {
      firstName: patientData && patientData.personalInfo && patientData.personalInfo.firstName ? patientData.personalInfo.firstName : '',
      middleName: patientData && patientData.personalInfo && patientData.personalInfo.middleName ? patientData.personalInfo.middleName : '',
      lastName: patientData && patientData.personalInfo && patientData.personalInfo.lastName ? patientData.personalInfo.lastName : '',
      image: patientData && patientData.personalInfo && patientData.personalInfo.image ? patientData.personalInfo.image : '',
      sex: patientData && patientData.personalInfo && patientData.personalInfo.sex ? patientData.personalInfo.sex : '',
      dateOfBirth: patientData && patientData.personalInfo && patientData.personalInfo.dateOfBirth ? moment(patientData.personalInfo.dateOfBirth).format('yyyy-MM-DD') : '',
      birthPlace: patientData && patientData.personalInfo && patientData.personalInfo.birthPlace ? patientData.personalInfo.birthPlace : '',
      bloodType: patientData && patientData.personalInfo && patientData.personalInfo.bloodType ? patientData.personalInfo.bloodType : '',
      civilStatus: patientData && patientData.personalInfo && patientData.personalInfo.civilStatus ? patientData.personalInfo.civilStatus : '',
      nationality: patientData && patientData.personalInfo && patientData.personalInfo.nationality ? patientData.personalInfo.nationality : '',
    },
    contactInfo: {
      emailAddress: patientData && patientData.contactInfo && patientData.contactInfo.emailAddress ? patientData.contactInfo.emailAddress : '',
      mobileNumber: patientData && patientData.contactInfo && patientData.contactInfo.mobileNumber ? patientData.contactInfo.mobileNumber : '',
      currentAddress: {
        houseNumberAndStreet: patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress.houseNumberAndStreet ? patientData.contactInfo.currentAddress.houseNumberAndStreet : '',
        subdivision: patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress.subdivision ? patientData.contactInfo.currentAddress.subdivision : '',
        barangay: patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress.barangay ? patientData.contactInfo.currentAddress.barangay : '',
        cityOrMunicipality: patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress.cityOrMunicipality ? patientData.contactInfo.currentAddress.cityOrMunicipality : '',
        province: patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress.province ? patientData.contactInfo.currentAddress.province : '',
        zip: patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress.zip ? patientData.contactInfo.currentAddress.zip : '',
      },
      provincialAddress: {
        houseNumberAndStreet: patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress.houseNumberAndStreet ? patientData.contactInfo.provincialAddress.houseNumberAndStreet : '',
        subdivision: patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress.subdivision ? patientData.contactInfo.provincialAddress.subdivision : '',
        barangay: patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress.barangay ? patientData.contactInfo.provincialAddress.barangay : '',
        cityOrMunicipality: patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress.cityOrMunicipality ? patientData.contactInfo.provincialAddress.cityOrMunicipality : '',
        province: patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress.province ? patientData.contactInfo.provincialAddress.province : '',
        zip: patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress.zip ? patientData.contactInfo.provincialAddress.zip : '',
      },
    },
    emergencyContact: {
      name: patientData.emergencyContact && patientData.emergencyContact.name ? patientData.emergencyContact.name : '',
      relationship: patientData.emergencyContact && patientData.emergencyContact.relationship ? patientData.emergencyContact.relationship : '',
      mobileNumber: patientData.emergencyContact && patientData.emergencyContact.mobileNumber ? patientData.emergencyContact.mobileNumber : '',
      emailAddress: patientData.emergencyContact && patientData.emergencyContact.emailAddress ? patientData.emergencyContact.emailAddress : '',
    },
    otherInfo: {
      hmo: patientData.otherInfo && patientData.otherInfo.hmo ? patientData.otherInfo.hmo : '',
      hmoNumber: patientData.otherInfo && patientData.otherInfo.hmoNumber ? patientData.otherInfo.hmoNumber : '',
      philhealthNumber: patientData.otherInfo && patientData.otherInfo.philhealthNumber ? patientData.otherInfo.philhealthNumber : '',
      sssNumber: patientData.otherInfo && patientData.otherInfo.sssNumber ? patientData.otherInfo.sssNumber : '',
      tinNumber: patientData.otherInfo && patientData.otherInfo.tinNumber ? patientData.otherInfo.tinNumber : '',
    },
  };

  return initialValues;
};

export default InitialValues;
