import React from 'react';
import moment from 'moment';

import { CardHeaderBarContainer, TextContainer, TitleText, CreatorText } from './CardHeaderBar.styled';
import { IconsContainer, PrintIcon, HideCardIcon, UnhideCardIcon, EditIcon } from '../../../../../../../assets/icons';

const CardHeaderBar = ({ title, createdBy, createdDate, isPrint, onPrint, isHidden, onHide, onEdit }) => {
  return (
    <CardHeaderBarContainer>
      <TextContainer>
        <TitleText>{title}</TitleText>
        <CreatorText>
          Added by: <b>{createdBy}</b>
        </CreatorText>
        <CreatorText>on {moment(createdDate).format('MMM DD, yyyy')}</CreatorText>
      </TextContainer>
      <IconsContainer>
        {isHidden ? (
          <div onClick={onHide} title={'show'}>
            <UnhideCardIcon />
          </div>
        ) : (
          <>
            {isPrint && (
              <div onClick={onPrint} title={'print'}>
                <PrintIcon />
              </div>
            )}
            <div onClick={onHide} title={'hide'}>
              <HideCardIcon />
            </div>
            <div onClick={onEdit} title={'edit'}>
              <EditIcon />
            </div>
          </>
        )}
      </IconsContainer>
    </CardHeaderBarContainer>
  );
};

export default CardHeaderBar;
