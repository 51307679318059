import React from 'react';

import { ChartInfoLabelHeader } from './ChartInfoBarHeader.styled';
import { SecondaryBar } from '../../../../../assets/containers';

const ChartInfoBarHeader = () => {
  return (
    <SecondaryBar>
      <ChartInfoLabelHeader width={'50px'} label={'NUMBER'}>
        No.
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'70px'} label={'DATE'}>
        Date
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'90px'} label={'TIMEIN'}>
        Time In
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'45px'} label={'IMAGE'}>
        Img
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'240px'} label={'NAME'}>
        Patient's Name
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'70px'} label={'SEX'}>
        Sex
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'130px'} label={'AGE'}>
        Age
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'240px'} label={'DOCTOR'}>
        Doctor
      </ChartInfoLabelHeader>
      <ChartInfoLabelHeader width={'180px'} label={'STATUS'}>
        Status
      </ChartInfoLabelHeader>
    </SecondaryBar>
  );
};

export default ChartInfoBarHeader;
