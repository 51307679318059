import styled from 'styled-components/macro';

export const PatientPaginatedBarContainer = styled.div`
  margin-top: 4px;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
`;

export const PaginationTextContainer = styled.div`
  display: flex;
`;

export const PaginationText = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  padding-right: 10px;
  font-size: 0.8rem;
`;

export const PaginationClickableText = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? 'orange' : props.theme.CardFontLight)};
  padding-right: 10px;
  font-size: 0.8rem;

  &:hover {
    color: orange;
  }
`;

export const PaginationLimitContainer = styled.div`
  display: flex;
`;

export const PaginationSelectLimit = styled.select`
  width: 50px;
`;
