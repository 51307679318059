import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateChartStatus } from '../../../../store/storeSlices/charts';

import { ChartInfoLabelEditable, SelectInputStatus, SelectInputOption, ChartInfoLabelEditableText, IconsContainer, IconContainer } from './ChartListStatus.styled';
import { CancelIcon } from '../../../../assets/icons';

const ChartListStatus = ({ chartStatus, chart, index, removeBar }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [itemEdit, setItemEdit] = useState('');
  const [newStatus, setNewStatus] = useState('');

  const handleEdit = (status, index) => {
    setIsEditStatus(true);
    setNewStatus(status);
    setItemEdit(index);
  };

  const onChangeHandler = (e) => {
    dispatch(updateChartStatus({ method: 'patch', url: `chart/updatestatus/${chart._id}`, token: token, data: { status: e.target.value } }));
    setIsEditStatus(false);
    setNewStatus('');
    setItemEdit('');
  };

  return (
    <ChartInfoLabelEditable removeBar={removeBar}>
      {isEditStatus && itemEdit === index ? (
        <SelectInputStatus value={newStatus} onChange={onChangeHandler}>
          {chartStatus &&
            chartStatus.map((a, index) => {
              return (
                <SelectInputOption key={index} value={`${a.status}`}>
                  {a.status}
                </SelectInputOption>
              );
            })}
        </SelectInputStatus>
      ) : (
        <ChartInfoLabelEditableText
          removeBar={removeBar}
          onClick={() => {
            handleEdit(chart.status, index);
          }}
        >
          {chart.status}
        </ChartInfoLabelEditableText>
      )}
      {isEditStatus && itemEdit === index && (
        <IconsContainer>
          <IconContainer
            onClick={() => {
              setIsEditStatus(false);
            }}
          >
            <CancelIcon />
          </IconContainer>
        </IconsContainer>
      )}
    </ChartInfoLabelEditable>
  );
};

export default ChartListStatus;
