import styled from 'styled-components/macro';

export const PrintPageContainer = styled.table`
  padding: 5px 10px 20px;
`;

export const PrintTitle = styled.div`
  color: white;
  background-color: darkgray;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 10px;
  padding: 1px;
  border-bottom: 1px solid black;
`;

export const PrintBody = styled.div`
  padding: 5px;
`;

export const TablePrintFooter = styled.tfoot``;
