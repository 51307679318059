import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getChartListByPatientId, getChartDataById, getChartDataByIdSilently, clearChartData, getChartListByPatientIdSilently } from '../../../../store/storeSlices/charts';
import { getPatientById, getPatientByIdSilently, clearPatientData } from '../../../../store/storeSlices/patients';

import PatientsChartList from './ViewChartComponents/PatientsChartList';
import ChartData from './ViewChartComponents/ChartData';
import ViewChartActions from './ViewChartComponents/ViewChartActions';

import { ViewChartBody } from './ViewChart.styled';

const ViewChart = ({ patientId, chartId, setChartId, onClose, onViewPatient }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const triggerRefreshChartData = useSelector((state) => state.card.triggerRefreshChartData);
  const refreshRate = useSelector((state) => state.settings.refreshRate);
  const isCardEdit = useSelector((state) => state.card.isCardEdit);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const chartList = useSelector((state) => state.chart.chartList);

  useEffect(() => {
    patientId && dispatch(getChartListByPatientId({ method: 'get', url: `chartlist/patient/${patientId}`, token }));
    patientId && dispatch(getPatientById({ method: 'get', url: `patient/${patientId}`, token }));
    chartId && dispatch(getChartDataById({ method: 'get', url: `chart/${chartId}`, token }));
    return () => {
      dispatch(clearPatientData());
      dispatch(clearChartData());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !isCardEdit && chartId && dispatch(getChartDataByIdSilently({ method: 'get', url: `chart/${chartId}`, token }));
    // eslint-disable-next-line
  }, [triggerRefreshChartData]);

  useEffect(() => {
    !chartId && setChartId(chartList[0]._id);
    chartList && chartList[0] && !chartId && dispatch(getChartDataById({ method: 'get', url: `chart/${chartList[0]._id}`, token }));
  }, [dispatch, chartId, chartList, token, setChartId]);

  useEffect(() => {
    dispatch(getChartDataById({ method: 'get', url: `chart/${chartId}`, token }));
  }, [dispatch, chartId, token]);

  useEffect(() => {
    let refreshTimer = setTimeout(() => {
      !isCardEdit && chartId && dispatch(getChartDataByIdSilently({ method: 'get', url: `chart/${chartId}`, token }));
      dispatch(getChartListByPatientIdSilently({ method: 'get', url: `chartlist/patient/${patientId}`, token }));
      dispatch(getPatientByIdSilently({ method: 'get', url: `patient/${patientId}`, token }));
      setRefreshCounter((prevValue) => prevValue + 1);
    }, refreshRate);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, [refreshCounter, triggerRefreshChartData, refreshRate, dispatch, chartId, chartList, setChartId, token, patientId, isCardEdit]);

  return (
    <ViewChartBody>
      <PatientsChartList activeChartId={chartId} setActiveChartId={setChartId} />
      <ChartData />
      <ViewChartActions onClose={onClose} onViewPatient={onViewPatient} patientId={patientId} />
    </ViewChartBody>
  );
};

export default ViewChart;
