import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';
import { ProcedureContainer } from './ProcedureCard.styled';

const ViewProcedureCard = ({ cardData, isHidden }) => {
  return (
    <CardBodyContainer isHidden={isHidden}>
      {cardData &&
        cardData.map((data, index) => {
          return (
            <ProcedureContainer key={index}>
              <div>{`[${data.RVScode}] ${data.Description} - ${data.eye}`}</div>
            </ProcedureContainer>
          );
        })}
    </CardBodyContainer>
  );
};

export default ViewProcedureCard;
