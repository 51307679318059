import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MainBodyContainer, BackDrop, Modal } from '../../../assets/containers';

import { setCurrentPage } from '../../../store/storeSlices/settings';
import ChartList from './ChartList/ChartList';
import PatientList from './PatientList/PatientList';
import ViewPatient from './ViewPatient/ViewPatient';
import SearchPatient from './SearchPatient/SearchPatient';
import ViewChart from './ViewChart/ViewChart';

function MainBody() {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.settings.currentPage);
  const [enableModal, setEnableModal] = useState(false);
  const [chartId, setChartId] = useState('');
  const [patientId, setPatientId] = useState('');
  const [viewPatient, setViewPatient] = useState(false);
  const [searchPatient, setSearchPatient] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const createNewPatientHandler = () => {
    setEnableModal(true);
    setSearchPatient(false);
    setViewPatient(true);
    setIsNew(true);
  };

  const viewPatientHandler = (id) => {
    setPatientId(id);
    setIsEdit(false);
    setEnableModal(true);
    setViewPatient(true);
  };

  const closeModalHandler = () => {
    setEnableModal(false);
    setViewPatient(false);
    setSearchPatient(false);
    setIsNew(false);
  };

  const searchPatientHandler = () => {
    setEnableModal(true);
    setSearchPatient(true);
  };

  const viewChartHandler = (chartId, patientId) => {
    setChartId(chartId);
    setPatientId(patientId);
    dispatch(setCurrentPage('ViewChart'));
  };

  const closePageHandler = () => {
    closeModalHandler();
    dispatch(setCurrentPage('ChartList'));
  };

  const editPatientHandler = () => {
    setEnableModal(true);
    setIsEdit(true);
    setViewPatient(true);
  };

  return (
    <MainBodyContainer>
      {enableModal && createPortal(<BackDrop onClick={closeModalHandler}></BackDrop>, document.getElementById('backdrop-root'))}
      {enableModal &&
        createPortal(
          <Modal>
            {viewPatient && <ViewPatient patientId={patientId} onCloseModal={closeModalHandler} isNew={isNew} onViewPatient={viewPatientHandler} isEdit={isEdit} setIsEdit={setIsEdit} />}
            {searchPatient && <SearchPatient onCloseModal={closeModalHandler} onCreateNewPatient={createNewPatientHandler} onViewChart={viewChartHandler} />}
          </Modal>,
          document.getElementById('modal-root')
        )}
      {currentPage === 'ChartList' && <ChartList onViewPatient={viewPatientHandler} onSearchPatient={searchPatientHandler} onViewChart={viewChartHandler} />}
      {currentPage === 'PatientList' && <PatientList onCreateNewPatient={createNewPatientHandler} onSearchPatient={searchPatientHandler} onViewPatient={viewPatientHandler} onViewChart={viewChartHandler} />}
      {currentPage === 'ViewChart' && <ViewChart chartId={chartId} setChartId={setChartId} patientId={patientId} onClose={closePageHandler} onEditPatient={editPatientHandler} onViewPatient={viewPatientHandler} />}
    </MainBodyContainer>
  );
}

export default MainBody;
