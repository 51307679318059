import styled from 'styled-components/macro';

export const EditMedicineContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 5px 2px 10px 2px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoLabelMain = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  font-size: 0.9rem;
`;

export const InfoLabel = styled.div`
  padding: 5px;
  margin-bottom: 5px;
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  padding-left: 6px;
  width: 220px;
`;

export const InfoInputTextArea = styled.textarea`
  width: 80%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 3px;
`;

export const SearchMedicineContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 5px 0px;
  border-radius: 3px;
`;

export const SearchInfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  padding-left: 6px;
  width: 400px;
`;

export const SearchResultContainer = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  margin-top: 10px;
  width: 100%;
  height: auto;
`;

export const SearchResultHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.TopBar};
  display: flex;
`;

export const SearchResultHeader = styled.div`
  width: ${(props) => props.width};
  text-align: center;
  background-color: ${({ theme }) => theme.TopBar};
  color: ${({ theme }) => theme.CardFontLight};
  font-size: 0.8rem;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: ${(props) => (props.last ? 'none' : `1px solid ${props.theme.CardFontLight}`)};
`;

export const SearchResultListContainer = styled.div`
  display: flex;
  padding: 0px;
`;

export const SearchResultItem = styled.div`
  width: ${(props) => props.width};
  text-align: ${(props) => (props.type === 'text' ? 'left' : 'center')};
  font-size: 0.8rem;
  padding: ${(props) => (props.type === 'action' ? '2px' : '10px')};
  border-right: ${(props) => (props.type === 'action' ? 'none' : `1px solid ${props.theme.TopBar}`)};
  border-bottom: 1px solid ${(props) => props.theme.TopBar};
  color: ${(props) => (props.shortage ? 'red' : 'black')};
  font-weight: ${(props) => (props.shortage ? 'bold' : 'normal')};
`;

export const AddOtherMedicineContainer = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.CardBorder};
  padding: 10px;
  border-radius: 5px;
`;

export const AddOtherMedicineHeader = styled.div`
  background-color: ${({ theme }) => theme.TopBar};
  text-align: center;
  color: white;
  font-size: 0.8rem;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

export const AddMedicineInfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  padding-left: 6px;
  width: 100%;
`;

export const ChartCardInputContainer = styled.div`
  background-color: ${(props) => (props.background ? props.background : props.theme.CardBackground)};
  margin: 2px;
  padding: 5px;
  width: ${(props) => (props.width ? props.width : '24%')};
  display: ${(props) => (props.display ? props.display : 'block')};
  box-shadow: 2px 2px 3px #ccc;
  border: 1px solid ${({ theme }) => theme.CardBorder};
`;
