import styled from 'styled-components/macro';

export const NavButtons = styled.div`
  text-align: right;
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 10px 15px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  border-right: 5px solid ${(props) => (props.active ? 'orange' : props.theme.MainNav)};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;
