import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #2283c9; // #72b9be;
  /* background-image: url(${(props) => props.backgroundImage && props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
`;

export const Modal = styled.div`
  padding: 20px;
  background-color: white;
  width: 500px;
  border: 1px solid #bbcef2;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 300px;
    padding-bottom: 20px;
  }

  @media (max-width: 576px) {
    width: 90%;
    padding-bottom: 20px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
`;

export const Logo = styled.img`
  width: auto;
  height: 40px;
  margin: 5px 5px 0px 5px;

  @media (max-width: 768px) {
    height: 60px;
    margin: 5px 0px 0px 0px;
  }
`;

export const CompanyName = styled.h1`
  color: #2283c9;
  font-family: Verdana;
  font-weight: bold;
  font-size: 2.2rem;
  margin: 0px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  min-height: 340px;

  @media (max-width: 768px) {
    width: 250px;
    padding: 10px;
  }

  @media (max-width: 576px) {
    width: 300px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  & label {
    font-size: 0.8rem;
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      margin-top: 5px;
      margin-bottom: 2px;
    }
  }

  & input {
    margin-bottom: 10px;
    border: 1px solid #2283c9;
    padding: 5px 10px;
    height: 40px;

    @media (max-width: 768px) {
      padding: 2px 10px;
      height: 30px;
    }
  }

  & select {
    margin-bottom: 10px;
    border-color: #2283c9;
    background-color: #e4edfd;
    padding: 5px 10px;
    height: 40px;

    @media (max-width: 768px) {
      padding: 2px 10px;
      height: 30px;
    }
  }
`;

export const LoginBtn = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #2283c9;
  border-color: transparent;
  border-radius: 20px;
  font-size: 1rem;
  padding: 10px;
  margin-top: 20px;

  &:hover {
    background-color: #1a76b8;
  }

  &:disabled {
    color: #282829;
    background-color: #ababac;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  padding: 5px;
  right: 0px;
  top: 4px;
  border-radius: 5px 5px 0px 0px;
  animation: slide-down 0.5s;

  @keyframes slide-down {
    from {
      opacity: 1;
      max-height: 0px;
      padding: 0px 5px;
      top: 30px;
    }
    to {
      opacity: 1;
      max-height: 30px;
      padding: 5px;
      top: 4px;
    }
  }
`;

export const FetchText = styled.p`
  text-align: center;
  font-size: 1.2rem;
`;

export const FetchTextError = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: red;
`;
