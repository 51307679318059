import styled from 'styled-components/macro';

export const BillingCardHeader = styled.div`
  display: flex;
`;

export const BillingCardHeaderItem = styled.div`
  display: flex;
  width: ${(props) => props.width};
  font-size: 0.8rem;
  font-weight: bold;
  justify-content: center;
  border: 1px solid gray;
  padding: 5px;
`;

export const BillingCardItemRow = styled.div`
  display: flex;
  padding: 5px 0px;
`;

export const BillingCardRowItem = styled.div`
  display: flex;
  width: ${(props) => props.width};
  font-size: 0.8rem;
  font-family: monospace;
  padding: 5px;
`;

export const BillingCardItemTotal = styled.div`
  border-top: 2px solid black;
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  & span {
    width: 150px;
    padding: 5px;
  }
`;
