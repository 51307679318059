import styled from 'styled-components/macro';

export const DataContainer = styled.div`
  display: flex;
  padding: 3px;
  width: 50%;
  float: left;
`;
export const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
  width: 190px;
`;

export const Value = styled.div``;

export const InputContainer = styled.div`
  display: flex;

  & label {
    font-size: 0.8rem;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: right;
  }

  & input {
    margin-bottom: 10px;
    padding: 5px 10px;
    height: 40px;
    width: 600px;
  }
`;
