import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';
import { CardDataContainer, Label, TextAreaInput, Table, TableHeader, TableData, PlanContainer } from './OphthaCard.styled';

import RichText from '../CardResources/RichText/RichText';
import DiagnosisList from '../CardResources/DiagnosisList';

const EditOpthaCard = ({ plan, onUpdateContent, newCardData, onChange, diagnosisList, onUpdateDiagnosisList }) => {
  return (
    <CardBodyContainer>
      <CardDataContainer>
        <Label>History of Present Illness (HPI):</Label>
        <TextAreaInput defaultValue={newCardData.hpi} name={'hpi'} onChange={(e) => onChange(e.target.name, e.target.value)} />
      </CardDataContainer>
      <CardDataContainer>
        <Label>Past Medical History (PMH):</Label>
        <TextAreaInput defaultValue={newCardData.pmh} name={'pmh'} onChange={(e) => onChange(e.target.name, e.target.value)} />
      </CardDataContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader noLabel={true}></TableHeader>
            <TableHeader>Right Eye</TableHeader>
            <TableHeader>Left Eye</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableData bold={true}>Intraocular Pressure (IOP):</TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.iop_right} name={'iop_right'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.iop_left} name={'iop_left'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
          </tr>
          <tr>
            <TableData bold={true}>Extraocular Muscle (EOM):</TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.eom_right} name={'eom_right'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.eom_left} name={'eom_left'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
          </tr>
          <tr>
            <TableData bold={true}>Biomicroscopy Examination:</TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.oa_right} name={'oa_right'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.oa_left} name={'oa_left'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
          </tr>
          <tr>
            <TableData bold={true}>Funduscopy:</TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.fundus_right} name={'fundus_right'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
            <TableData>
              <TextAreaInput defaultValue={newCardData.fundus_left} name={'fundus_left'} onChange={(e) => onChange(e.target.name, e.target.value)} />
            </TableData>
          </tr>
        </tbody>
      </Table>
      <CardDataContainer>
        <Label>Assessment and Diagnosis:</Label>
        <DiagnosisList diagnosisList={diagnosisList} onUpdateDiagnosisList={onUpdateDiagnosisList} />
      </CardDataContainer>

      <CardDataContainer>
        <Label>Management and Plan:</Label>
        <PlanContainer>
          <RichText data={plan} isView={false} isEdit={true} isNew={false} onUpdateContent={onUpdateContent} />
        </PlanContainer>
      </CardDataContainer>
    </CardBodyContainer>
  );
};

export default EditOpthaCard;
