import styled from 'styled-components/macro';

export const CardBodyContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.isHidden ? '0px' : '10px')};
  border-radius: 3px;
  max-height: ${(props) => (props.isHidden ? '0px' : '2000px')};
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  display: flex;
`;

export const PersonalProfileContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBorder};
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  margin: 5px;
  z-index: 10;
  margin-top: 80px;
`;

export const PatientName = styled.h1`
  margin: 5px;
`;

export const PersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  padding: 5px;
  padding-left: 135px;
  margin-top: 5px;
`;

export const PersonalInfo = styled.div`
  margin: 5px;
  font-size: 0.9rem;
`;

export const CardDataContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  padding: 5px 10px;
  margin-left: 5px;
`;

export const CardDataRow = styled.div`
  font-size: 0.8rem;
  margin: 2px;
  display: flex;
  align-items: center;

  & b {
    width: 100px;
  }
`;

export const ChartIdContainer = styled.div`
  padding: 8px;
  display: flex;
  box-sizing: border-box;
  color: ${(props) => props.theme.FontColor};
  font-size: 0.8rem;
  width: 229px;
  border: 1px solid ${(props) => props.theme.CardBorder};
  border-radius: 3px;
  box-sizing: border-box;
  margin: 2px 12px;
  height: 33px;
`;
