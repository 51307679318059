import React from 'react';
import { useSelector } from 'react-redux';

import { CompanyInfoContainer, HeaderLogoContainer, SmallLogoImage, CompanyName, CompanyText } from './CompanyInfo.styled';

function CompanyInfo() {
  const backgroundColor = useSelector((state) => state.settings.backgroundColor);
  const companyName = useSelector((state) => state.settings.companyName);
  const companyText = useSelector((state) => state.settings.companyText);

  return (
    <CompanyInfoContainer>
      <HeaderLogoContainer backgroundColor={backgroundColor}>
        <SmallLogoImage src={`${process.env.REACT_APP_FILES_URL}headerLogo.png`} alt="company logo" />
        {companyName && companyName.name && (
          <CompanyName fontUrl={companyName.url} fontStyle={companyName.font} fontColor={companyName.color} fontWeight={companyName.fontWeight}>
            {companyName.name}
          </CompanyName>
        )}
        {companyName && companyText.text && (
          <CompanyText fontUrl={companyText.url} fontStyle={companyText.font} fontColor={companyText.color} fontWeight={companyText.fontWeight}>
            {companyText.text}
          </CompanyText>
        )}
      </HeaderLogoContainer>
    </CompanyInfoContainer>
  );
}

export default CompanyInfo;
