import React from 'react';
import styled from 'styled-components/macro';

import loaderGif from './images/loading-gif.gif';

const LoaderContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
`;

const ProfileLoaderContainer = styled.div`
  background-color: transparent;
  z-index: 10;
  border: none;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  width: 100%;
`;

export const MainLoader = () => {
  return (
    <LoaderContainer>
      <img src={loaderGif} height="80" width="80" alt={'loading gif'} />
    </LoaderContainer>
  );
};

export const ModalLoader = () => {
  return (
    <ProfileLoaderContainer>
      <img src={loaderGif} height="80" width="80" alt={'loading gif'} />
    </ProfileLoaderContainer>
  );
};
