import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { updateCardInChart, addCardToChart, setIsCardEdit, setIsNewCard, setNewCardType, setNewCardData } from '../../../../../../../store/storeSlices/cards';

import { CardContainer } from '../CardResources/Card.styled';
import { CertifyText, CardDataContainer, Label } from './MedCertCard.styled';

import CardHeaderBar from '../CardResources/CardHeaderBar';
import ViewMedCertCard from './ViewMedCertCard';
import Printer from '../CardResources/Printer/Printer';
import EditCardModal from '../CardResources/EditCardModal';
import DiagnosisList from '../CardResources/DiagnosisList';
import RichText from '../CardResources/RichText/RichText';

const MedCertCard = ({ cardData, cardIndex, isNew }) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const token = useSelector((state) => state.user.userData.userToken);
  const chartId = useSelector((state) => state.chart.chartData._id);
  const patientData = useSelector((state) => state.patient.patientData.personalInfo);
  const date = useSelector((state) => state.chart.chartData.appointmentDate);
  const currentUser = useSelector((state) => state.user.userData);
  const [diagnosis, setDiagnosis] = useState(cardData.cardData.diagnosis);
  const [remarks, setRemarks] = useState(cardData.cardData.remarks);
  let name = patientData ? (patientData.middleName ? (patientData.firstName + ' ' + patientData.middleName + ' ' + patientData.lastName).toUpperCase() : (patientData.firstName + ' ' + patientData.lastName).toUpperCase()) : '';

  const handleBeforeGetContent = () => {
    setIsPrint(true);
    return Promise.resolve();
  };

  const onPrintHandler = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `patient_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const clearAddNewHandler = () => {
    dispatch(setIsNewCard(false));
    dispatch(setNewCardType(''));
    dispatch(setNewCardData({}));
  };

  const onSubmitHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    isNew
      ? dispatch(
          addCardToChart({ method: 'post', url: `card/add/${chartId}`, token, data: { cardType: cardData.cardType, cardData: { remarks, diagnosis }, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` } })
        )
      : dispatch(updateCardInChart({ method: 'patch', url: `card/update/${chartId}?index=${cardIndex}`, token, data: { cardData: { remarks, diagnosis } } }));
    clearAddNewHandler();
  };

  const onEditHandler = () => {
    dispatch(setIsCardEdit(true));
    setIsEdit(true);
    clearAddNewHandler();
  };

  const onCloseModalHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };
  const updateContentHandler = (content) => {
    setRemarks(content);
  };

  const onUpdateDiagnosisListHandler = (updatedList) => {
    setDiagnosis(updatedList);
  };

  return (
    <CardContainer>
      <CardHeaderBar title={'Medical Certificate Card'} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isPrint={true} onPrint={onPrintHandler} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
      {isEdit || isNew ? (
        <EditCardModal onCloseModal={onCloseModalHandler} title={'Medical Certificate Card'} handleSubmit={onSubmitHandler}>
          <CardDataContainer>
            <Label>Diagnosis:</Label>
            <DiagnosisList diagnosisList={diagnosis} onUpdateDiagnosisList={onUpdateDiagnosisListHandler} />
          </CardDataContainer>
          <CardDataContainer>
            <Label>Remarks:</Label>
            <RichText data={remarks} isView={false} isEdit={true} isNew={false} onUpdateContent={updateContentHandler} />
          </CardDataContainer>
        </EditCardModal>
      ) : (
        <>
          {isPrint ? (
            <div ref={componentRef}>
              <Printer title={'MEDICAL CERTIFICATE'} includeDoctor={true}>
                <CertifyText>
                  This is to certify that <b>{name}</b> was examined on <b>{moment(date).format('MMM DD, yyyy')}</b> with the following findings and diagnosis.
                </CertifyText>
                <ViewMedCertCard cardData={cardData.cardData} />
              </Printer>
            </div>
          ) : (
            <ViewMedCertCard cardData={cardData.cardData} isHidden={isHidden} />
          )}
        </>
      )}
    </CardContainer>
  );
};

export default MedCertCard;
