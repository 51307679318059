import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCharts, getAllChartsSilently } from '../../../../store/storeSlices/charts';
import moment from 'moment';

import ChartListMainBar from './ChartListComponents/ChartListMainBar';
import FilterBar from './ChartListComponents/FilterBar';
import ChartInfoBarHeader from './ChartListComponents/ChartInfoBarHeader';
import PatientChartList from './ChartListComponents/PatientChartList';

const ChartList = ({ onViewPatient, onSearchPatient, onViewChart }) => {
  const dispatch = useDispatch();
  const { dateFrom, dateTo, statusList, doctorFilter, isToday, filterIsHidden } = useSelector((state) => state.settings.filterSettings);
  const token = useSelector((state) => state.user.userData.userToken);
  const clinicSite = useSelector((state) => state.user.clinicSite);
  const chartList = useSelector((state) => state.chart.allChartsData);
  const triggerRefreshChartList = useSelector((state) => state.chart.triggerRefreshChartList);
  const triggerChartListRefresh = useSelector((state) => state.patient.triggerChartListRefresh);
  const refreshRate = useSelector((state) => state.settings.refreshRate);
  const [hideFilter, setHideFilter] = useState(filterIsHidden);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    console.log('THIS HAS BEEN TRIGGERED!!!');
    dispatch(
      getAllChartsSilently({
        method: 'post',
        url: 'chartlist/search',
        token: token,
        data: { dateFrom: isToday ? moment().startOf('day') : dateFrom, dateTo: isToday ? moment().endOf('day') : dateTo, statusList, doctorFilter, clinicSite },
      })
    );
    // eslint-disable-next-line
  }, [triggerRefreshChartList, triggerChartListRefresh]);

  useEffect(() => {
    let refreshTimer = setTimeout(() => {
      dispatch(
        getAllChartsSilently({
          method: 'post',
          url: 'chartlist/search',
          token: token,
          data: { dateFrom: isToday ? moment().startOf('day') : dateFrom, dateTo: isToday ? moment().endOf('day') : dateTo, statusList, doctorFilter, clinicSite },
        })
      );
      setRefreshCounter((prevVal) => prevVal + 1);
    }, refreshRate);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, [dateFrom, dateTo, statusList, doctorFilter, clinicSite, dispatch, refreshRate, token, refreshTrigger, refreshCounter, isToday]);

  useEffect(() => {
    dispatch(
      getAllCharts({
        method: 'post',
        url: 'chartlist/search',
        token: token,
        data: { dateFrom: isToday ? moment().startOf('day') : dateFrom, dateTo: isToday ? moment().endOf('day') : dateTo, statusList, doctorFilter, clinicSite },
      })
    );
    setRefreshCounter((prevVal) => prevVal + 1);
  }, [dispatch, dateFrom, dateTo, statusList, doctorFilter, clinicSite, token, refreshTrigger, isToday]);

  const onHideFilterHandler = () => {
    setHideFilter((prevState) => !prevState);
  };

  const onRefreshDataHandler = () => {
    setRefreshTrigger((prevVal) => prevVal + 1);
  };

  return (
    <Fragment>
      <ChartListMainBar onHideFilter={onHideFilterHandler} onRefreshData={onRefreshDataHandler} onSearchPatient={onSearchPatient} hideFilter={hideFilter} />
      <FilterBar hideFilter={hideFilter} />
      <ChartInfoBarHeader dateFrom={dateFrom} dateTo={dateTo} statusList={statusList} doctorFilter={doctorFilter} />
      <PatientChartList chartList={chartList} onViewPatient={onViewPatient} onViewChart={onViewChart} hideFilter={hideFilter} />
    </Fragment>
  );
};

export default ChartList;
