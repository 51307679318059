import styled from 'styled-components/macro';

export const ChartInfoLabelEditable = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.8rem;
  padding: 0px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: ${(props) => (props.removeBar ? 'none' : `1px solid black`)};
  display: flex;

  @media (max-width: 550px) {
    display: none;
  }

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }
`;

export const ChartInfoLabelEditableText = styled.div`
  cursor: pointer;
  width: ${(props) => (props.removeBar ? '229px' : '120px')};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  padding: 8px;
  margin: 2px;
  text-align: ${(props) => (props.removeBar ? 'left' : 'center')};
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  height: 33px;

  &:hover {
    color: ${(props) => props.theme.MainNav};
    background-color: ${(props) => (props.removeBar ? props.theme.CardBorder : 'white')};
  }
`;

export const SelectInputStatus = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 1px 15px;
  font-size: 0.8rem;
  margin: 2px;
  width: 120px;
  border-radius: 3px;
  text-align: center;
`;

export const SelectInputOption = styled.option`
  color: ${({ theme }) => theme.FontColor};
  padding: 2px 5px;
  font-size: 0.8rem;
  margin-right: 3px;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
  text-align: left;
`;

export const ViewChartButtonContainer = styled.div`
  display: block;
  padding-top: 5px;
`;

export const IconContainer = styled.div`
  display: block;
`;

export const IconsContainer = styled.div`
  display: flex;
  padding: 2px;
`;
