import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios/axios';
import { toast } from 'react-toastify';

export const getClinicSiteList = createAsyncThunk('users/getClinicSiteList', async (action) => {
  return await apiRequest(action);
});

export const getDataList = createAsyncThunk('users/getDataList', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  tokenExpired: false,
  dataList: [],
  clinicSiteList: [],
  status: 'idle',
  cardTypes: [{ name: '', id: '' }],
  chartStatus: [{ status: '', color: '#fff' }],
  diagnosisList: [{ icd10: '', diagnosis: '' }],
  doctorList: [{ _id: '', personalInfo: { firstName: 'Ben', middleName: 'Vergara', lastName: 'Gican' } }],
  nurseCardData: [{}],
  optoCardData: [{}],
  procedureList: [{}],
  refractionCard: [{}],
  userAccess: ['admin', 'supervisor', 'user'],
  userDepartment: ['Ophthalmology'],
  userPosition: [''],
  userRoles: [''],
};

export const dataListSlice = createSlice({
  name: 'dataList',
  initialState,
  reducers: {
    setDataListTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // Get clinic site list
      .addCase(getClinicSiteList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getClinicSiteList.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.clinicSiteList = data;
          localStorage.setItem('clinicAppClinicSiteList', JSON.stringify(data));
        } else {
          state.status = 'failed';
          toast.error(message);
          state.clinicSiteList = localStorage.getItem('clinicAppClinicSiteList') ? JSON.parse(localStorage.getItem('clinicAppClinicSiteList')) : [];
          console.log(action.payload);
        }
      })

      // Get data list
      .addCase(getDataList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        let data = action.payload.data.data ? action.payload.data.data[0] : [];
        let message = action.payload.data.message;
        let status = action.payload.status;
        if (status === 200) {
          state.status = 'succeeded';
          state.dataList = data;
          state.dataList = data;
          state.cardTypes = data.cardTypes;
          state.doctorList = data.doctorList;
          state.procedureList = data.procedureList;
          state.chartStatus = data.chartStatus;
        } else if (status === 401) {
          state.status = 'failed';
          toast.error(message);
          state.tokenExpired = true;
        } else {
          state.status = 'failed';
          toast.error(message);
          console.log(action.payload);
        }
      });
  },
});

export const { setDataListTokenExpired } = dataListSlice.actions;
export default dataListSlice.reducer;
