import styled from 'styled-components/macro';

export const CardHeaderBarContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.CardBorder};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  padding: 0px 5px 2px 5px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  background-color: ${({ theme }) => theme.CardBorder};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  padding: 0px 5px;
`;

export const TitleText = styled.div`
  font-size: 1rem;
  margin: 5px;
  width: 250px;
`;

export const CreatorText = styled.div`
  font-size: 0.8rem;
  margin: 5px;
  font-style: italic;
`;
