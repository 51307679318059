import styled from 'styled-components/macro';

export const CardDataContainer = styled.div`
  display: flex;
  padding: 10px 5px;
  width: 100%;
  flex-direction: column;
`;

export const Label = styled.div`
  width: 25%;
  font-weight: bold;
  padding-top: 5px;
`;

export const Value = styled.div`
  margin: 0px;
  width: 75%;
  white-space: pre-wrap;
  padding: 5px 10px;
`;

export const TextAreaInput = styled.textarea`
  margin: 5px;
  padding: 10px;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 98%;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 5px;
  background-color: ${({ theme }) => theme.CardBorder};
`;

export const TableHeader = styled.th`
  width: ${(props) => (props.noLabel ? '25%' : '37.5%')};
  background-color: ${({ theme }) => theme.CardHeader};
  padding: 5px;
`;

export const TableData = styled.td`
  white-space: ${(props) => (props.bold ? 'normal' : 'pre-wrap')};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  padding: 5px;
  background-color: white;
`;

export const DiagnosisList = styled.div`
  padding: 5px 10px;
  margin: 3px;
`;

export const PlanContainer = styled.div`
  padding: 10px 15px;
`;
