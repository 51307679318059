import styled, { keyframes } from 'styled-components/macro';

const loadViewContainer = keyframes`
   0% {
      opacity: 0;
      transform: translateY(-10%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

export const TopBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ProfileDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  animation-name: ${loadViewContainer};
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PersonalProfile = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    padding: 2px;
  }
`;

export const OtherProfileData = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-top: 5px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2px;
  }
`;

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  z-index: 10;
`;

export const PatientName = styled.h1`
  margin: 10px;
`;

export const PatientContactDetails = styled.h3`
  margin: 3px;
`;

export const PatientIdContainer = styled.h3`
  display: block;
  margin: 3px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const OtherPersonalInfoContainer = styled.div`
  width: auto;
  margin-top: 5px;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  background-color: white;

  @media (max-width: 768px) {
    padding: 0px;
    margin: 5px 0px;
  }
`;

export const OtherPersonalInfoHeader = styled.div`
  background-color: ${({ theme }) => theme.CardBorder};
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
`;

export const PersonalInfoContainer = styled.div`
  padding: 5px;
  margin: 0px;
`;

export const PersonalInfo = styled.p`
  margin: 3px;
  font-size: 0.9rem;
`;

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBorder};
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const ContactContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.CardBorder};
  border-top: none;
  width: 100%;
  padding: 100px 5px 15px 5px;
  min-height: 210px;
  background-color: white;
  text-align: center;
`;
