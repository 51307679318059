import React from 'react';

import {
  FormContainer,
  TopBarContainer,
  PatientIdContainer,
  PatientDataContainer,
  LeftColumnContainer,
  RightColumnContainer,
  ProfileImage,
  CardFormContainer,
  CardFormHeader,
  CardFormInputContainer,
  InputContainer,
  ImageContainer,
  ContactContainer,
  ErrorMessage,
} from './PatientForm.styled';

import { ButtonsContainer, Button1, Button2 } from '../../../../../assets/buttons';
import femaleImagePlaceholder from '../../../../../assets/images/femaleImagePlaceholder.jpeg';
import maleImagePlaceholder from '../../../../../assets/images/maleImagePlaceholder.png';

const PatientForm = ({ values, handleChange, handleBlur, handleSubmit, errors, touched, isEdit, isNew, setIsEdit, onCloseModal, patientData }) => {
  return (
    <FormContainer autoComplete="off" onSubmit={handleSubmit}>
      <TopBarContainer>
        <PatientIdContainer>Patient ID: {patientData && patientData._id}</PatientIdContainer>
        <ButtonsContainer>
          <Button1 type="submit">{isEdit ? 'Update' : 'Save'}</Button1>
          <Button2 type="button" onClick={() => (isNew ? onCloseModal() : setIsEdit(false))}>
            Cancel
          </Button2>
        </ButtonsContainer>
      </TopBarContainer>
      <PatientDataContainer>
        <LeftColumnContainer>
          <ImageContainer>
            <ProfileImage
              src={
                patientData && patientData.personalInfo && patientData.personalInfo.image
                  ? patientData.personalInfo.image
                  : patientData && patientData.personalInfo && patientData.personalInfo.sex && patientData.personalInfo.sex === 'Male'
                  ? maleImagePlaceholder
                  : femaleImagePlaceholder
              }
            />
          </ImageContainer>
          <ContactContainer>
            <CardFormInputContainer>
              <InputContainer isError={errors.personalInfo && errors.personalInfo.firstName && touched.personalInfo && touched.personalInfo.firstName}>
                <label>First Name: </label>
                <input name="personalInfo.firstName" value={values.personalInfo.firstName} onChange={handleChange} onBlur={handleBlur}></input>
                {errors.personalInfo && errors.personalInfo.firstName && touched.personalInfo && touched.personalInfo.firstName && <ErrorMessage>{errors.personalInfo.firstName}</ErrorMessage>}
              </InputContainer>
              <InputContainer isError={errors.personalInfo && errors.personalInfo.middleName && touched.personalInfo && touched.personalInfo.middleName}>
                <label>Middle Name: </label>
                <input name="personalInfo.middleName" value={values.personalInfo.middleName} onChange={handleChange} onBlur={handleBlur}></input>
                {errors.personalInfo && errors.personalInfo.middleName && touched.personalInfo && touched.personalInfo.middleName && <ErrorMessage>{errors.personalInfo.middleName}</ErrorMessage>}
              </InputContainer>
              <InputContainer isError={errors.personalInfo && errors.personalInfo.lastName && touched.personalInfo && touched.personalInfo.lastName}>
                <label>Last Name: </label> <input name="personalInfo.lastName" value={values.personalInfo.lastName} onChange={handleChange} onBlur={handleBlur}></input>
                {errors.personalInfo && errors.personalInfo.lastName && touched.personalInfo && touched.personalInfo.lastName && <ErrorMessage>{errors.personalInfo.lastName}</ErrorMessage>}
              </InputContainer>
              <InputContainer isError={errors.contactInfo && errors.contactInfo.emailAddress && touched.contactInfo && touched.contactInfo.emailAddress}>
                <label>Email Address: </label>
                <input name="contactInfo.emailAddress" value={values.contactInfo.emailAddress} onChange={handleChange} onBlur={handleBlur}></input>
                {errors.contactInfo && errors.contactInfo.emailAddress && touched.contactInfo && touched.contactInfo.emailAddress && <ErrorMessage>{errors.contactInfo.emailAddress}</ErrorMessage>}
              </InputContainer>
              <InputContainer>
                <label>Phone Number: </label> <input name="contactInfo.mobileNumber" value={values.contactInfo.mobileNumber} onChange={handleChange} onBlur={handleBlur}></input>
              </InputContainer>
            </CardFormInputContainer>
          </ContactContainer>
          <CardFormContainer>
            <CardFormHeader>Personal Details:</CardFormHeader>
            <CardFormInputContainer>
              <InputContainer isError={errors.personalInfo && errors.personalInfo.sex && touched.personalInfo && touched.personalInfo.sex}>
                <label>Sex: </label>
                <select name="personalInfo.sex" value={values.personalInfo.sex} onChange={handleChange} onBlur={handleBlur}>
                  <option value="" disabled>
                    -- Please select --
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errors.personalInfo && errors.personalInfo.sex && touched.personalInfo && touched.personalInfo.sex && <ErrorMessage>{errors.personalInfo.sex}</ErrorMessage>}
              </InputContainer>
              <InputContainer isError={errors.personalInfo && errors.personalInfo.dateOfBirth && touched.personalInfo && touched.personalInfo.dateOfBirth}>
                <label>Date of Birth: </label>
                <input type="date" name="personalInfo.dateOfBirth" value={values.personalInfo.dateOfBirth} onChange={handleChange} onBlur={handleBlur} />
                {errors.personalInfo && errors.personalInfo.dateOfBirth && touched.personalInfo && touched.personalInfo.dateOfBirth && <ErrorMessage>{errors.personalInfo.dateOfBirth}</ErrorMessage>}
              </InputContainer>
              <InputContainer>
                <label>Place of Birth: </label> <input name="personalInfo.birthPlace" value={values.personalInfo.birthPlace} onChange={handleChange} onBlur={handleBlur}></input>
              </InputContainer>
              <InputContainer>
                <label>Blood Type: </label>
                <select name="personalInfo.bloodType" value={values.personalInfo.bloodType} onChange={handleChange} onBlur={handleBlur}>
                  <option value="" disabled>
                    -- Please select --
                  </option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </select>
              </InputContainer>
              <InputContainer>
                <label>Civil Status: </label>
                <select name="personalInfo.civilStatus" value={values.personalInfo.civilStatus} onChange={handleChange} onBlur={handleBlur}>
                  <option value="" disabled>
                    -- Please select --
                  </option>
                  <option value="Single">Single</option>
                  <option value="Maried">Maried</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Separated">Separated</option>
                </select>
              </InputContainer>

              <InputContainer>
                <label>Nationality: </label> <input name="personalInfo.nationality" value={values.personalInfo.nationality} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
            </CardFormInputContainer>
          </CardFormContainer>
          <CardFormContainer>
            <CardFormHeader>Other Info:</CardFormHeader>
            <CardFormInputContainer>
              <InputContainer>
                <label>HMO: </label>
                <input name="otherInfo.hmo" value={values.otherInfo.hmo} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>HMO Number: </label>
                <input name="otherInfo.hmoNumber" value={values.otherInfo.hmoNumber} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>SSS Number: </label>
                <input name="otherInfo.sssNumber" value={values.otherInfo.sssNumber} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>PhilHealth Number: </label>
                <input name="otherInfo.philhealthNumber" value={values.otherInfo.philhealthNumber} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
            </CardFormInputContainer>
          </CardFormContainer>
        </LeftColumnContainer>
        <RightColumnContainer>
          <CardFormContainer>
            <CardFormHeader>Current Address:</CardFormHeader>
            <CardFormInputContainer>
              <InputContainer>
                <label>House no/Street: </label>
                <input name="contactInfo.currentAddress.houseNumberAndStreet" value={values.contactInfo.currentAddress.houseNumberAndStreet} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Subdivision: </label>
                <input name="contactInfo.currentAddress.subdivision" value={values.contactInfo.currentAddress.subdivision} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Barangay: </label>
                <input name="contactInfo.currentAddress.barangay" value={values.contactInfo.currentAddress.barangay} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>City/Municipality: </label>
                <input name="contactInfo.currentAddress.cityOrMunicipality" value={values.contactInfo.currentAddress.cityOrMunicipality} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Province: </label>
                <input name="contactInfo.currentAddress.province" value={values.contactInfo.currentAddress.province} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer isError={errors.contactInfo && errors.contactInfo.currentAddress && errors.contactInfo.currentAddress.zip && touched.contactInfo && touched.contactInfo.currentAddress && touched.contactInfo.currentAddress.zip}>
                <label>Zip: </label>
                <input type="number" name="contactInfo.currentAddress.zip" value={values.contactInfo.currentAddress.zip} onChange={handleChange} onBlur={handleBlur} />
                {errors.contactInfo && errors.contactInfo.currentAddress && errors.contactInfo.currentAddress.zip && touched.contactInfo && touched.contactInfo.currentAddress && touched.contactInfo.currentAddress.zip && (
                  <ErrorMessage>{errors.contactInfo.currentAddress.zip}</ErrorMessage>
                )}
              </InputContainer>
            </CardFormInputContainer>
          </CardFormContainer>
          <CardFormContainer>
            <CardFormHeader>Provincial Address:</CardFormHeader>
            <CardFormInputContainer>
              <InputContainer>
                <label>House no/Street: </label>
                <input name="contactInfo.provincialAddress.houseNumberAndStreet" value={values.contactInfo.provincialAddress.houseNumberAndStreet} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Subdivision: </label>
                <input name="contactInfo.provincialAddress.subdivision" value={values.contactInfo.provincialAddress.subdivision} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Barangay: </label>
                <input name="contactInfo.provincialAddress.barangay" value={values.contactInfo.provincialAddress.barangay} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>City/Municipality: </label>
                <input name="contactInfo.provincialAddress.cityOrMunicipality" value={values.contactInfo.provincialAddress.cityOrMunicipality} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Province: </label>
                <input name="contactInfo.provincialAddress.province" value={values.contactInfo.provincialAddress.province} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer
                isError={errors.contactInfo && errors.contactInfo.provincialAddress && errors.contactInfo.provincialAddress.zip && touched.contactInfo && touched.contactInfo.provincialAddress && touched.contactInfo.provincialAddress.zip}
              >
                <label>Zip: </label>
                <input type="number" name="contactInfo.provincialAddress.zip" value={values.contactInfo.provincialAddress.zip} onChange={handleChange} onBlur={handleBlur} />
                {errors.contactInfo && errors.contactInfo.provincialAddress && errors.contactInfo.provincialAddress.zip && touched.contactInfo && touched.contactInfo.provincialAddress && touched.contactInfo.provincialAddress.zip && (
                  <ErrorMessage>{errors.contactInfo.provincialAddress.zip}</ErrorMessage>
                )}
              </InputContainer>
            </CardFormInputContainer>
          </CardFormContainer>
          <CardFormContainer>
            <CardFormHeader>Emergency Contact:</CardFormHeader>
            <CardFormInputContainer>
              <InputContainer>
                <label>Name: </label> <input name="emergencyContact.name" value={values.emergencyContact.name} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Relationship: </label>
                <input name="emergencyContact.relationship" value={values.emergencyContact.relationship} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Email Address: </label>
                <input name="emergencyContact.emailAddress" value={values.emergencyContact.emailAddress} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
              <InputContainer>
                <label>Mobile Number: </label>
                <input name="emergencyContact.mobileNumber" value={values.emergencyContact.mobileNumber} onChange={handleChange} onBlur={handleBlur} />
              </InputContainer>
            </CardFormInputContainer>
          </CardFormContainer>
        </RightColumnContainer>
      </PatientDataContainer>
    </FormContainer>
  );
};

export default PatientForm;
