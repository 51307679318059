import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { createNewChart } from '../../../../../store/storeSlices/charts';
import { setCurrentPage } from '../../../../../store/storeSlices/settings';

import { PatientInfoBarContainer, PatientInfoBar, PatientInfoContainer, PatientAvatarContainer, PatientAvatar, PatientInfoLabel, PatientNameContainer } from './PatientsListBar.styled';
import { ButtonsContainer, Button1, Button2 } from '../../../../../assets/buttons';
import { MainLoader } from '../../../../../assets/loader';

import femaleImage from '../../../../../assets/images/femaleImagePlaceholder.jpeg';
import maleImage from '../../../../../assets/images/maleImagePlaceholder.png';

const PatientsListBar = ({ patientsList, onViewPatient, onViewChart }) => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector((state) => state.patient.status);
  const token = useSelector((state) => state.user.userData.userToken);
  const userId = useSelector((state) => state.user.userData.userId);
  const clinicSite = useSelector((state) => state.user.clinicSite);

  const getAge = (dob) => {
    let dateNow = moment();
    let dateOfBirth = moment(dob);
    let years = moment.duration(dateNow.diff(dateOfBirth)).years();
    let months = moment.duration(dateNow.diff(dateOfBirth)).months();
    let days = moment.duration(dateNow.diff(dateOfBirth)).days();
    return `${years} years, ${months} months, ${days} days`;
  };

  const onCreateNewChart = (id) => {
    const data = {
      patientId: id,
      appointmentDate: moment(),
      createdBy: userId,
      doctorId: 'Select Doctor',
      clinicSite: clinicSite,
      isLocked: false,
      status: 'Registered',
      cards: [],
    };
    dispatch(createNewChart({ method: 'post', url: 'chart/create', token, data }));
    dispatch(setCurrentPage('ChartList'));
  };

  return (
    <PatientInfoBarContainer>
      {loadingStatus === 'loading' ? (
        <MainLoader />
      ) : (
        <>
          {patientsList.map((patient, index) => (
            <PatientInfoBar key={index}>
              <PatientInfoContainer>
                <PatientAvatarContainer>
                  {patient.personalInfo.image ? <PatientAvatar src={patient.personalInfo.image} /> : <>{patient.personalInfo.sex === 'Female' ? <PatientAvatar src={femaleImage} /> : <PatientAvatar src={maleImage} />}</>}
                </PatientAvatarContainer>
                <PatientNameContainer width={'300px'} onClick={() => onViewPatient(patient._id)}>
                  <div>{`${patient.personalInfo.firstName} ${patient.personalInfo.middleName} ${patient.personalInfo.lastName}`}</div>
                </PatientNameContainer>

                <PatientInfoLabel width={'120px'} label={'BIRTHDATE'}>
                  {moment(patient.personalInfo.dateOfBirth).format('MMM DD, YYYY')}
                </PatientInfoLabel>
                <PatientInfoLabel width={'80px'} label={'SEX'}>
                  {patient.personalInfo.sex}
                </PatientInfoLabel>
                <PatientInfoLabel width={'200px'} label={'AGE'}>
                  {getAge(patient.personalInfo.dateOfBirth)}
                </PatientInfoLabel>
              </PatientInfoContainer>
              <ButtonsContainer>
                <Button1 onClick={() => onCreateNewChart(patient._id)}>Create Chart</Button1>
                <Button2 onClick={() => onViewChart('', patient._id)}>View Chart</Button2>
              </ButtonsContainer>
            </PatientInfoBar>
          ))}
        </>
      )}
    </PatientInfoBarContainer>
  );
};

export default PatientsListBar;
