import React from 'react';
import { useSelector } from 'react-redux';

import { MainBar, TitleContainer, HeaderTitle } from '../../../../../assets/containers';
import { ButtonsContainer, Button1, Button2 } from '../../../../../assets/buttons';
import { PatientSearchContainer, PatientSearchLabel, PatientSearchInput } from './PatientsListMainBar.styled';

const PatientsListMainBar = ({ onCreateNewPatient, onRefreshData, setFirstName, setLastName }) => {
  const status = useSelector((state) => state.patient.status);
  let delay;

  const onChangeHandler = (e) => {
    clearTimeout(delay);
    delay = setTimeout(() => {
      e.target.name === 'firstName' && setFirstName(e.target.value);
      e.target.name === 'lastName' && setLastName(e.target.value);
    }, 500);
  };
  return (
    <MainBar>
      <TitleContainer>
        <HeaderTitle>Patients</HeaderTitle>
      </TitleContainer>
      <PatientSearchContainer>
        <PatientSearchLabel>First Name: </PatientSearchLabel>
        <PatientSearchInput type={'text'} name={'firstName'} onChange={onChangeHandler} />
        <PatientSearchLabel>Last Name: </PatientSearchLabel>
        <PatientSearchInput type={'text'} name={'lastName'} onChange={onChangeHandler} />
      </PatientSearchContainer>

      <ButtonsContainer>
        <Button1 onClick={onRefreshData} disabled={status === 'loading'}>
          {status === 'loading' ? 'Fetching Data...' : 'Refresh Data'}
        </Button1>
        <Button2 onClick={onCreateNewPatient}>Create Patient</Button2>
      </ButtonsContainer>
    </MainBar>
  );
};

export default PatientsListMainBar;
