import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';
import { ItemContainer, NoteHeader, Note, Item, PrescriptionInlineEntry } from './PrescriptionCard.styled';

const ViewPrescriptionCard = ({ cardData, isHidden }) => {
  return (
    <CardBodyContainer isHidden={isHidden}>
      {cardData &&
        cardData.map((data, index) => {
          return (
            <ItemContainer key={index}>
              {data.id === 'note' ? (
                <>
                  <NoteHeader>Notes:</NoteHeader>
                  <Note>{data.note}</Note>
                </>
              ) : (
                <>
                  <PrescriptionInlineEntry>
                    <Item>
                      <b>
                        {data.name} {data.generic}
                      </b>{' '}
                      {data.medDosage} {data.preparation}
                    </Item>
                    <Item># {data.quantity}</Item>
                  </PrescriptionInlineEntry>
                  <Item>
                    sig: {data.dosage} {data.frequency}
                  </Item>
                </>
              )}
            </ItemContainer>
          );
        })}
    </CardBodyContainer>
  );
};

export default ViewPrescriptionCard;
