import styled from 'styled-components/macro';

export const FooterContainer = styled.footer`
  text-align: center;
  background-color: ${({ theme }) => theme.TopBar};
  padding: 2px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 20px;
  left: 0px;
`;

export const FooterText = styled.p`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.CardBorder};
  padding: 0px;
  margin: 0px;
`;
