import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { searchPatient } from '../../../../store/storeSlices/patients';
import { createNewChart } from '../../../../store/storeSlices/charts';

import { ModalLoader } from '../../../../assets/loader';

import {
  SearchPatientContainer,
  TopContainer,
  SearchInputContainer,
  InputContainer,
  ButtonContainer,
  SearchResultContainer,
  HeaderBarContainer,
  HeaderBarInnerContainer,
  HeaderBarLabel,
  PatientInfoBar,
  PatientInfoContainer,
  PatientInfoLabel,
} from './SearchPatient.styled';
import { ButtonsContainer, Button1, Button2 } from '../../../../assets/buttons';

const SearchPatientModal = ({ onCreateNewPatient, onCloseModal, onViewChart }) => {
  const dispatch = useDispatch();
  const patientsList = useSelector((state) => state.patient.searchResult);
  const loadingStatus = useSelector((state) => state.patient.status);
  const token = useSelector((state) => state.user.userData.userToken);
  const userId = useSelector((state) => state.user.userData.userId);
  const clinicSite = useSelector((state) => state.user.clinicSite);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  let delay;

  useEffect(() => {
    (firstName.length > 1 || lastName.length > 1) && dispatch(searchPatient({ method: 'get', url: `patient/search?page=&limit=&firstName=${firstName}&middleName=&lastName=${lastName}`, token }));
  }, [dispatch, firstName, lastName, token]);

  const onChangeHandler = (e) => {
    clearTimeout(delay);
    delay = setTimeout(() => {
      e.target.name === 'firstName' && setFirstName(e.target.value);
      e.target.name === 'lastName' && setLastName(e.target.value);
    }, 700);
  };

  const onCreateNewChartHandler = (id) => {
    const data = {
      patientId: id,
      appointmentDate: moment(),
      createdBy: userId,
      doctorId: 'Select Doctor',
      clinicSite: clinicSite,
      isLocked: false,
      status: 'Registered',
      cards: [],
    };
    dispatch(createNewChart({ method: 'post', url: 'chart/create', token, data }));
    onCloseModal();
  };

  const onViewChartHandler = (id) => {
    onViewChart('', id);
    onCloseModal();
  };
  return (
    <SearchPatientContainer>
      <TopContainer>
        <SearchInputContainer>
          <InputContainer>
            <label>First Name: </label>
            <input name={'firstName'} onChange={onChangeHandler} />
          </InputContainer>
          <InputContainer>
            <label>Last Name: </label>
            <input name={'lastName'} onChange={onChangeHandler} />
          </InputContainer>
        </SearchInputContainer>
        <ButtonContainer>
          <Button1 onClick={onCreateNewPatient}>Create Patient</Button1>
          <Button2 onClick={onCloseModal}>Close</Button2>
        </ButtonContainer>
      </TopContainer>
      <SearchResultContainer>
        <HeaderBarContainer>
          <HeaderBarInnerContainer>
            <HeaderBarLabel width={'300px'}>Complete Name</HeaderBarLabel>
            <HeaderBarLabel width={'120px'} label={'BIRTHDATE'}>
              Birth Date
            </HeaderBarLabel>
            <HeaderBarLabel width={'80px'} label={'SEX'}>
              Sex
            </HeaderBarLabel>
          </HeaderBarInnerContainer>
        </HeaderBarContainer>
        {loadingStatus === 'loading' ? (
          <ModalLoader />
        ) : (
          patientsList &&
          (firstName.length > 1 || lastName.length > 1) &&
          patientsList.map((patient, index) => {
            return (
              <PatientInfoBar key={index}>
                <PatientInfoContainer>
                  <PatientInfoLabel width={'300px'}>
                    <div>{`${patient.personalInfo.firstName} ${patient.personalInfo.middleName} ${patient.personalInfo.lastName}`}</div>
                  </PatientInfoLabel>
                  <PatientInfoLabel width={'120px'} label={'BIRTHDATE'}>
                    {moment(patient.personalInfo.dateOfBirth).format('MMM DD, YYYY')}
                  </PatientInfoLabel>
                  <PatientInfoLabel width={'80px'} label={'SEX'}>
                    {patient.personalInfo.sex}
                  </PatientInfoLabel>
                </PatientInfoContainer>
                <ButtonsContainer>
                  <Button1 onClick={() => onCreateNewChartHandler(patient._id)}>Create Chart</Button1>
                  <Button2 onClick={() => onViewChartHandler(patient._id)}>View Chart</Button2>
                </ButtonsContainer>
              </PatientInfoBar>
            );
          })
        )}
      </SearchResultContainer>
    </SearchPatientContainer>
  );
};

export default SearchPatientModal;
