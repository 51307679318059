import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box
    }
    body {
        height: 100vh;
        margin: 0;
        padding: 0;
        font-family: "Lucida Grande",Verdana,Arial,Helvetica,sans-serif;
        font-size: 0.8rem;
        overflow: hidden;
    }
    html {
        height: 100vh;
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;
    }

    p {
        line-height: 1.5;
    }
    @media print {
        @page {
            size: A5;
            height: 100%; 
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden;
        }
    }
`;

export default GlobalStyles;
