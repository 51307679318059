import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { updateCardInChart, addCardToChart, setIsCardEdit, setIsNewCard, setNewCardType, setNewCardData } from '../../../../../../../store/storeSlices/cards';

import { CardContainer } from '../CardResources/Card.styled';
import CardHeaderBar from '../CardResources/CardHeaderBar';
import ViewGenericCard from './ViewGenericCard';
import EditGenericCard from './EditGenericCard';
import EditCardModal from '../CardResources/EditCardModal';

const GenericCard = ({ cardData, title, cardIndex, isNew }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const currentUser = useSelector((state) => state.user.userData);
  const chartId = useSelector((state) => state.chart.chartData._id);
  const [isHidden, setIsHidden] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const clearAddNewHandler = () => {
    dispatch(setIsNewCard(false));
    dispatch(setNewCardType(''));
    dispatch(setNewCardData({}));
  };

  const onSubmit = (values) => {
    let updateCardData = cardData.cardData.map((data) => {
      return { ...data, value: values[data.id] };
    });
    isNew
      ? dispatch(addCardToChart({ method: 'post', url: `card/add/${chartId}`, token, data: { cardType: cardData.cardType, cardData: updateCardData, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` } }))
      : dispatch(updateCardInChart({ method: 'patch', url: `card/update/${chartId}?index=${cardIndex}`, token, data: { cardData: updateCardData } }));

    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const onEditHandler = () => {
    dispatch(setIsCardEdit(true));
    setIsEdit(true);
    clearAddNewHandler();
  };

  const onCloseModalHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };

  const getInitialValues = () => {
    let initialValues = {};
    cardData.cardData.forEach((data) => {
      initialValues[data.id] = data.value ? data.value : '';
    });
    return initialValues;
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
  });

  return (
    <CardContainer>
      <form onSubmit={handleSubmit}>
        <CardHeaderBar title={title} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
        {isEdit || isNew ? (
          <EditCardModal onCloseModal={onCloseModalHandler} title={title}>
            <EditGenericCard cardData={cardData.cardData} values={values} handleChange={handleChange} />
          </EditCardModal>
        ) : (
          <ViewGenericCard cardData={cardData.cardData} isHidden={isHidden} />
        )}
      </form>
    </CardContainer>
  );
};

export default GenericCard;
