import React, { Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';

import { getMedicineList } from '../../../../../../../store/storeSlices/medicine';

import { EditMedicineContainer, InputContainer, InfoLabelMain, InfoLabel, InfoInput, InfoInputTextArea } from './MedicineList.styled';
import { SearchMedicineContainer, SearchInfoInput, SearchResultContainer, SearchResultHeader, SearchResultHeaderContainer, SearchResultListContainer, SearchResultItem } from './MedicineList.styled';
import { AddOtherMedicineHeader, AddOtherMedicineContainer, AddMedicineInfoInput } from './MedicineList.styled';
import { Button1, Button2, Button3, ButtonsContainer } from '../../../../../../../assets/buttons';

const MedicineList = ({ prescriptList, onUpdate }) => {
  const ListContainer = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const medicineList = useSelector((state) => state.medicine.medicineList);
  const clinicSite = useSelector((state) => state.user.clinicSite);
  const [name, setName] = useState('');
  const [generic, setGeneric] = useState('');
  const [addOtherMedicine, setAddOtherMedicine] = useState(false);
  const [otherMedicineData, setOtherMedicineData] = useState({ _id: 'otherMedicine', name: '', generic: '', preparation: '', dosage: '' });
  const [filteredMedicineList, setFilteredMedicineList] = useState(medicineList);

  let Header = [
    { name: 'Medicine Name', id: 'name', type: 'text', width: '30%' },
    { name: 'Generic Name', id: 'generic', type: 'text', width: '30%' },
    { name: 'ExpDate', id: 'expDate', type: 'date', width: '10%' },
    { name: 'On Hand', id: 'onHand', type: 'number', width: '10%' },
    { name: 'Action', id: 'action', type: 'action', width: '20%' },
  ];

  useEffect(() => {
    dispatch(getMedicineList({ method: 'get', url: `medicine/${clinicSite}`, token }));
  }, [dispatch, clinicSite, token]);

  const updateMedicineHandler = (index, name, value) => {
    onUpdate(prescriptList.map((item, i) => (index === i ? { ...item, [name]: value } : item)));
  };

  const removeMedicineHandler = (index) => {
    onUpdate(prescriptList.filter((med, i) => i !== index));
  };

  const addMedicineHandler = (medicine) => {
    ListContainer.current.scrollIntoView({ behavior: 'smooth' });
    let tempMedicine = { id: medicine._id, name: medicine.name, generic: medicine.generic, medDosage: medicine.dosage, preparation: medicine.preparation, quantity: '', dosage: '', frequency: '' };
    onUpdate(prescriptList.length === 0 ? [tempMedicine] : [...prescriptList, tempMedicine]);
  };

  const onAddOtherMedicineChangeHandler = (name, value) => {
    setOtherMedicineData({ ...otherMedicineData, [name]: value });
  };

  const addOtherMedicineHandler = () => {
    onUpdate([...prescriptList, otherMedicineData]);
    setOtherMedicineData({ _id: 'otherMedicine', name: '', generic: '', preparation: '', dosage: '' });
    setAddOtherMedicine(false);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setFilteredMedicineList(
        medicineList.filter((medicine) => {
          return medicine.name.toLowerCase().includes(name.toLowerCase()) && medicine.generic.toLowerCase().includes(generic.toLowerCase());
        })
      );
    }, 150);

    return () => clearTimeout(delay);
  }, [name, generic, medicineList]);

  const onAddNotesHandler = () => {
    onUpdate([...prescriptList, { id: 'note', note: '' }]);
  };

  return (
    <Fragment>
      <div ref={ListContainer}></div>
      {prescriptList.map((medicine, index) => {
        return (
          <div key={index}>
            {medicine.id === 'note' ? (
              <EditMedicineContainer>
                <InfoLabelMain>Note: </InfoLabelMain>
                <InputContainer>
                  <InfoInputTextArea rows={'6'} width={'100%'} value={medicine.note} name={'note'} onChange={(e) => updateMedicineHandler(index, e.target.name, e.target.value)} />
                  <Button2 onClick={() => removeMedicineHandler(index)}>Remove</Button2>
                </InputContainer>
              </EditMedicineContainer>
            ) : (
              <EditMedicineContainer>
                <InfoLabelMain>
                  <b>{`${medicine.name} (${medicine.generic})`}</b>
                  {` ${medicine.medDosage} ${medicine.preparation}`}
                </InfoLabelMain>
                <InputContainer>
                  <InfoLabel>Quantity: </InfoLabel>
                  <InfoInput width={'100%'} value={medicine.quantity} name={'quantity'} onChange={(e) => updateMedicineHandler(index, e.target.name, e.target.value)} />
                  <InfoLabel>Dosage: </InfoLabel>
                  <InfoInput width={'100%'} value={medicine.dosage} name={'dosage'} onChange={(e) => updateMedicineHandler(index, e.target.name, e.target.value)} />
                  <InfoLabel>Frequency: </InfoLabel>
                  <InfoInput width={'100%'} value={medicine.frequency} name={'frequency'} onChange={(e) => updateMedicineHandler(index, e.target.name, e.target.value)} />
                  <Button2 onClick={() => removeMedicineHandler(index)}>Remove</Button2>
                </InputContainer>
              </EditMedicineContainer>
            )}
          </div>
        );
      })}
      {addOtherMedicine ? (
        <>
          <SearchMedicineContainer>
            <ButtonsContainer>
              <Button1 onClick={() => addOtherMedicineHandler()}>Add</Button1>
              <Button2 onClick={() => setAddOtherMedicine(false)}>Cancel</Button2>
            </ButtonsContainer>
          </SearchMedicineContainer>
          <AddOtherMedicineContainer>
            <AddOtherMedicineHeader>Add Other Medicine</AddOtherMedicineHeader>
            <InfoLabel>Medicine: </InfoLabel>
            <AddMedicineInfoInput value={otherMedicineData.name} name={'name'} onChange={(e) => onAddOtherMedicineChangeHandler(e.target.name, e.target.value)} />
            <InfoLabel>Generic: </InfoLabel>
            <AddMedicineInfoInput value={otherMedicineData.generic} name={'generic'} onChange={(e) => onAddOtherMedicineChangeHandler(e.target.name, e.target.value)} />
            <InfoLabel>Preparation: </InfoLabel>
            <AddMedicineInfoInput value={otherMedicineData.preparation} name={'preparation'} onChange={(e) => onAddOtherMedicineChangeHandler(e.target.name, e.target.value)} />
            <InfoLabel>Medicine Dosage: </InfoLabel>
            <AddMedicineInfoInput value={otherMedicineData.medDosage} name={'medDosage'} onChange={(e) => onAddOtherMedicineChangeHandler(e.target.name, e.target.value)} />
          </AddOtherMedicineContainer>
        </>
      ) : (
        <>
          <SearchMedicineContainer>
            <InputContainer>
              <SearchInfoInput value={name} onChange={(e) => setName(e.target.value)} placeholder={'Search medicine by name'} />
            </InputContainer>
            <InputContainer>
              <SearchInfoInput value={generic} onChange={(e) => setGeneric(e.target.value)} placeholder={'Search medicine by generic name'} />
            </InputContainer>
            <ButtonsContainer>
              <Button1 onClick={() => setAddOtherMedicine(true)}>Add Medicine</Button1>
              <Button3 onClick={onAddNotesHandler}>Add Notes</Button3>
            </ButtonsContainer>
          </SearchMedicineContainer>
          <SearchResultContainer>
            <SearchResultHeaderContainer>
              {Header.map((item, index) => {
                return (
                  <SearchResultHeader key={index} width={item.width} last={item.type === 'action'}>
                    {item.name}
                  </SearchResultHeader>
                );
              })}
            </SearchResultHeaderContainer>

            {filteredMedicineList.map((medicine, index) => {
              return (
                <SearchResultListContainer key={index}>
                  {Header.map((item, i) => {
                    return (
                      <SearchResultItem key={i} width={item.width} type={item.type} shortage={item.id === 'onHand' && medicine.onHand < medicine.minQty}>
                        {item.type === 'action' ? <Button1 onClick={() => addMedicineHandler(medicine)}>Add Medicine</Button1> : <>{item.type === 'date' ? moment(medicine[item.id]).format('MMM yyyy') : medicine[item.id]}</>}
                      </SearchResultItem>
                    );
                  })}
                </SearchResultListContainer>
              );
            })}
          </SearchResultContainer>
        </>
      )}
    </Fragment>
  );
};

export default MedicineList;
