import React, { Fragment } from 'react';
import moment from 'moment';

import { ButtonsContainer, Button1, Button2 } from '../../../../../assets/buttons';
import femaleImagePlaceholder from '../../../../../assets/images/femaleImagePlaceholder.jpeg';
import maleImagePlaceholder from '../../../../../assets/images/maleImagePlaceholder.png';

import {
  TopBarContainer,
  ProfileDataContainer,
  PersonalProfile,
  OtherProfileData,
  ProfileImage,
  PatientName,
  PatientContactDetails,
  PatientIdContainer,
  OtherPersonalInfoContainer,
  OtherPersonalInfoHeader,
  PersonalInfoContainer,
  PersonalInfo,
  ImageContainer,
  ContactContainer,
} from './ViewPatientData.styled';

const ViewPatientData = ({ patientData, onCloseModal, setIsEdit, viewChart }) => {
  const getAge = (dob) => {
    let dateNow = moment();
    let dateOfBirth = moment(dob);
    let years = moment.duration(dateNow.diff(dateOfBirth)).years();
    let months = moment.duration(dateNow.diff(dateOfBirth)).months();
    let days = moment.duration(dateNow.diff(dateOfBirth)).days();
    return `${years} years, ${months} months, ${days} days`;
  };

  return (
    <Fragment>
      <TopBarContainer>
        <PatientIdContainer>Patient ID: {patientData && patientData._id}</PatientIdContainer>
        <ButtonsContainer>
          <Button1 onClick={() => setIsEdit(true)}>Edit</Button1>
          <Button2 onClick={onCloseModal}>Close</Button2>
        </ButtonsContainer>
      </TopBarContainer>
      <ProfileDataContainer>
        <PersonalProfile>
          <ImageContainer>
            <ProfileImage
              src={
                patientData && patientData.personalInfo && patientData.personalInfo.image
                  ? patientData.personalInfo.image
                  : patientData && patientData.personalInfo && patientData.personalInfo.sex && patientData.personalInfo.sex === 'Male'
                  ? maleImagePlaceholder
                  : femaleImagePlaceholder
              }
            />
          </ImageContainer>
          <ContactContainer>
            <PatientName>
              {patientData && patientData.personalInfo && patientData.personalInfo.firstName && patientData.personalInfo.firstName}{' '}
              {patientData && patientData.personalInfo && patientData.personalInfo.middleName && patientData.personalInfo.middleName}{' '}
              {patientData && patientData.personalInfo && patientData.personalInfo.lastName && patientData.personalInfo.lastName && patientData.personalInfo.lastName}
            </PatientName>
            <PatientContactDetails>{patientData && patientData.contactInfo && patientData.contactInfo.emailAddress && patientData.contactInfo.emailAddress}</PatientContactDetails>
            <PatientContactDetails>{patientData && patientData.contactInfo && patientData.contactInfo.mobileNumber && patientData.contactInfo.mobileNumber}</PatientContactDetails>
          </ContactContainer>
          <OtherPersonalInfoContainer>
            <OtherPersonalInfoHeader>Personal Details:</OtherPersonalInfoHeader>
            <PersonalInfoContainer>
              <PersonalInfo>
                AGE: <b>{patientData && patientData.personalInfo && patientData.personalInfo.dateOfBirth && getAge(patientData.personalInfo.dateOfBirth)}</b>
              </PersonalInfo>
              <PersonalInfo>
                SEX: <b>{patientData && patientData.personalInfo && patientData.personalInfo.sex && patientData.personalInfo.sex}</b>
              </PersonalInfo>
              <PersonalInfo>
                CIVIL STATUS: <b>{patientData && patientData.personalInfo && patientData.personalInfo.civilStatus && patientData.personalInfo.civilStatus}</b>
              </PersonalInfo>
              <PersonalInfo>
                BIRTH PLACE: <b>{patientData && patientData.personalInfo && patientData.personalInfo.birthPlace && patientData.personalInfo.birthPlace}</b>
              </PersonalInfo>
              <PersonalInfo>
                BIRTH DATE: <b>{patientData && patientData.personalInfo && patientData.personalInfo.dateOfBirth && moment(patientData.personalInfo.dateOfBirth).format('MMMM DD, yyyy')}</b>
              </PersonalInfo>
              <PersonalInfo>
                BLOOD TYPE: <b>{patientData && patientData.personalInfo && patientData.personalInfo.bloodType && patientData.personalInfo.bloodType}</b>
              </PersonalInfo>
            </PersonalInfoContainer>
          </OtherPersonalInfoContainer>
        </PersonalProfile>
        <OtherProfileData>
          <OtherPersonalInfoContainer>
            <OtherPersonalInfoHeader>Current Address:</OtherPersonalInfoHeader>
            {patientData.contactInfo && patientData.contactInfo.currentAddress && patientData.contactInfo.currentAddress && (
              <PersonalInfoContainer>
                <PersonalInfo>
                  {patientData.contactInfo.currentAddress.houseNumberAndStreet && `${patientData.contactInfo.currentAddress.houseNumberAndStreet}, `}
                  {patientData.contactInfo.currentAddress.subdivision && patientData.contactInfo.currentAddress.subdivision}
                </PersonalInfo>
                <PersonalInfo>
                  {patientData.contactInfo.currentAddress.barangay && `${patientData.contactInfo.currentAddress.barangay}, `}
                  {patientData.contactInfo.currentAddress.cityOrMunicipality && patientData.contactInfo.currentAddress.cityOrMunicipality}
                </PersonalInfo>
                <PersonalInfo>
                  {patientData.contactInfo.currentAddress.province && patientData.contactInfo.currentAddress.province} {patientData.contactInfo.currentAddress.zip && patientData.contactInfo.currentAddress.zip}
                </PersonalInfo>
              </PersonalInfoContainer>
            )}
          </OtherPersonalInfoContainer>
          <OtherPersonalInfoContainer>
            <OtherPersonalInfoHeader>Provincial Address:</OtherPersonalInfoHeader>
            {patientData.contactInfo && patientData.contactInfo.provincialAddress && patientData.contactInfo.provincialAddress && (
              <PersonalInfoContainer>
                <PersonalInfo>
                  {patientData.contactInfo.provincialAddress.houseNumberAndStreet && `${patientData.contactInfo.provincialAddress.houseNumberAndStreet}, `}
                  {patientData.contactInfo.provincialAddress.subdivision && patientData.contactInfo.provincialAddress.subdivision}
                </PersonalInfo>
                <PersonalInfo>
                  {patientData.contactInfo.provincialAddress.barangay && `${patientData.contactInfo.provincialAddress.barangay}, `}
                  {patientData.contactInfo.provincialAddress.cityOrMunicipality && patientData.contactInfo.provincialAddress.cityOrMunicipality}
                </PersonalInfo>
                <PersonalInfo>
                  {patientData.contactInfo.provincialAddress.province && patientData.contactInfo.provincialAddress.province} {patientData.contactInfo.provincialAddress.zip && patientData.contactInfo.provincialAddress.zip}
                </PersonalInfo>
              </PersonalInfoContainer>
            )}
          </OtherPersonalInfoContainer>
          <OtherPersonalInfoContainer>
            <OtherPersonalInfoHeader>Emergency Contact:</OtherPersonalInfoHeader>
            {patientData && patientData.emergencyContact && (
              <PersonalInfoContainer>
                <PersonalInfo>Name: {patientData.emergencyContact.name && patientData.emergencyContact.name}</PersonalInfo>
                <PersonalInfo>Relationship: {patientData.emergencyContact.relationship && patientData.emergencyContact.relationship}</PersonalInfo>
                <PersonalInfo>Email Address: {patientData.emergencyContact.emailAddress && patientData.emergencyContact.emailAddress}</PersonalInfo>
                <PersonalInfo>Mobile Number: {patientData.emergencyContact.mobileNumber && patientData.emergencyContact.mobileNumber}</PersonalInfo>
              </PersonalInfoContainer>
            )}
          </OtherPersonalInfoContainer>
          <OtherPersonalInfoContainer>
            <OtherPersonalInfoHeader>Other Info:</OtherPersonalInfoHeader>
            {patientData && patientData.otherInfo && (
              <PersonalInfoContainer>
                <PersonalInfo>HMO: {patientData.otherInfo.hmo && patientData.otherInfo.hmo}</PersonalInfo>
                <PersonalInfo>HMO Number: {patientData.otherInfo.hmoNumber && patientData.otherInfo.hmoNumber}</PersonalInfo>
                <PersonalInfo>SSS Number: {patientData.otherInfo.sssNumber && patientData.otherInfo.sssNumber}</PersonalInfo>
                <PersonalInfo>PhilHealth Number: {patientData.otherInfo.philhealthNumber && patientData.otherInfo.philhealthNumber}</PersonalInfo>
              </PersonalInfoContainer>
            )}
          </OtherPersonalInfoContainer>
        </OtherProfileData>
      </ProfileDataContainer>
    </Fragment>
  );
};

export default ViewPatientData;
