import styled from 'styled-components/macro';

export const NoteHeader = styled.div`
  font-weight: bold;
`;
export const Note = styled.div`
  padding: 10px;
  width: 100%;
  white-space: pre-wrap;
`;

export const ItemContainer = styled.div`
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid lightgray;
`;

export const Item = styled.div`
  padding: 2px;
`;

export const PrescriptionInlineEntry = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding-right: 20px;
`;
