import React from 'react';

import { PrintPageContainer, PrintTitle, PrintBody, TablePrintFooter } from './Printer.styled';

import ClinicInfo from './PrinterComponents/ClinicInfo';
import PatientsInfo from './PrinterComponents/PatientInfo';
import DoctorDetails from './PrinterComponents/DoctorDetails';
import Footer from './PrinterComponents/Footer';

const Printer = ({ title, children, includeDoctor }) => {
  return (
    <PrintPageContainer>
      <thead>
        <tr>
          <td>
            <ClinicInfo />
            <PrintTitle>{title}</PrintTitle>
            <PatientsInfo />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <PrintBody>{children}</PrintBody>
          </td>
        </tr>
      </tbody>
      <TablePrintFooter>
        <tr>
          <td>
            {includeDoctor && <DoctorDetails />}
            <Footer />
          </td>
        </tr>
      </TablePrintFooter>
    </PrintPageContainer>
  );
};

export default Printer;
