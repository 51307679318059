import React from 'react';

import { CardBodyContainer } from '../CardResources/Card.styled';
import { DataContainer, Label, Value } from './GenericCard.styled';

const ViewGenericCard = ({ cardData, isHidden }) => {
  return (
    <CardBodyContainer isHidden={isHidden}>
      {cardData &&
        cardData.map((data) => {
          return (
            <DataContainer key={data.id}>
              <Label>{data.label}:</Label>
              <Value>{data.value}</Value>
            </DataContainer>
          );
        })}
    </CardBodyContainer>
  );
};

export default ViewGenericCard;
