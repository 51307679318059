import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { PatientsChartListContainer, ChartListHeader, PatientChartListScrollableContainer, DateList, DateListContainer } from './PatientsChartList.styled';
import { ModalLoader } from '../../../../../assets/loader';

const PatientsChartList = ({ setActiveChartId, activeChartId }) => {
  const status = useSelector((state) => state.chart.listStatus);
  const chartList = useSelector((state) => state.chart.chartList);

  return (
    <PatientsChartListContainer>
      <ChartListHeader>Chart History</ChartListHeader>
      <PatientChartListScrollableContainer>
        {status === 'loading' ? (
          <ModalLoader />
        ) : (
          <DateListContainer>
            {chartList.map((chart, index) => {
              return (
                <DateList key={index} onClick={() => setActiveChartId(chart._id)} active={activeChartId === chart._id}>
                  {moment(chart.createdAt).format('MMM DD, yyyy')}
                </DateList>
              );
            })}
          </DateListContainer>
        )}
      </PatientChartListScrollableContainer>
    </PatientsChartListContainer>
  );
};

export default PatientsChartList;
