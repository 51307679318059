import React from 'react';
import styled from 'styled-components/macro';

import { FiUsers } from 'react-icons/fi';
import { IoDocumentsOutline } from 'react-icons/io5';
import { MdOutlineCancel } from 'react-icons/md';
import { BsPrinter } from 'react-icons/bs';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { RiEdit2Line, RiSaveLine, RiDeleteBin4Line } from 'react-icons/ri';

export const IconsContainer = styled.div`
  display: flex;
  margin-top: 2px;

  @media (max-width: 576px) {
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 5px 0px 5px;
  color: ${(props) => (props.isWhite ? props.theme.CardFontLight : props.theme.CardFont)};
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: ${({ theme }) => theme.CardFontLight};
  }
`;

export const ChartsIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <IoDocumentsOutline />
    </IconContainer>
  );
};

export const PatientsIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <FiUsers />
    </IconContainer>
  );
};

export const CancelIcon = () => {
  return (
    <IconContainer>
      <MdOutlineCancel />
    </IconContainer>
  );
};

export const PrintIcon = () => {
  return (
    <IconContainer>
      <BsPrinter />
    </IconContainer>
  );
};

export const HideCardIcon = () => {
  return (
    <IconContainer>
      <AiOutlineEyeInvisible />
    </IconContainer>
  );
};

export const UnhideCardIcon = () => {
  return (
    <IconContainer>
      <AiOutlineEye />
    </IconContainer>
  );
};

export const EditIcon = () => {
  return (
    <IconContainer>
      <RiEdit2Line />
    </IconContainer>
  );
};

export const SaveIcon = () => {
  return (
    <IconContainer>
      <RiSaveLine />
    </IconContainer>
  );
};

export const DeleteIcon = () => {
  return (
    <IconContainer>
      <RiDeleteBin4Line />
    </IconContainer>
  );
};
