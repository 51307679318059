import styled from 'styled-components/macro';

export const SelectDoctorContainer = styled.div`
  padding: ${(props) => (props.removeBar ? '2px 0px 2px 12px;' : '2px 5px')};
  width: ${(props) => props.width};
  border-left: ${(props) => (props.removeBar ? 'none' : `1px solid black`)};
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 1050px) {
    display: ${(props) => props.label === 'DOCTOR' && 'none'};
  }

  @media (max-width: 500px) {
    width: ${(props) => props.label === 'NAME' && '200px'};
  }
`;

export const AssignedDoctorContainer = styled.div`
  cursor: pointer;
  color: ${(props) => (props.isWarning ? 'red' : props.theme.FontColor)};
  font-size: 0.8rem;
  padding: 8px;
  width: 229px;
  border: 1px solid ${(props) => (props.isWarning ? 'red' : props.theme.CardBorder)};
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  box-sizing: border-box;
  height: 33px;

  &:hover {
    color: ${(props) => props.theme.MainNav};
    background-color: ${(props) => (props.removeBar ? props.theme.CardBorder : 'white')};
  }
`;

export const SelectDoctor = styled.select`
  cursor: pointer;
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.8rem;
  padding: 7px;
  width: 189px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.MainNav};
    background-color: white;
  }
`;

export const SelectInputOption = styled.option`
  color: ${({ theme }) => theme.FontColor};
  padding: 2px 5px;
  font-size: 0.8rem;
  margin-right: 3px;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
  text-align: left;
`;

export const IconContainer = styled.div`
  width: 40px;
  padding: 0px 0px 0px 5px;
`;
