import styled from 'styled-components/macro';

export const PrintFooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid gray;
  width: 510px;
  bottom: 0;
  position: fixed;
`;

export const PoweredByContainer = styled.div`
  padding: 5px;
  color: gray;
  font-size: 0.6rem;
`;

export const DatePrintedContaier = styled.div`
  padding: 5px;
  font-size: 0.6rem;
  color: gray;
`;
