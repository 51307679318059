import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';

import { updateCardInChart, addCardToChart, setIsCardEdit, setIsNewCard, setNewCardType, setNewCardData } from '../../../../../../../store/storeSlices/cards';

import { CardContainer, CardBodyContainer } from '../CardResources/Card.styled';
import CardHeaderBar from '../CardResources/CardHeaderBar';

import Printer from '../CardResources/Printer/Printer';
import EditCardModal from '../CardResources/EditCardModal';
import RichText from '../CardResources/RichText/RichText';

const GenericRichTextCard = ({ cardData, title, showPrint, includeDoctor, printTitle, cardIndex, isNew }) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userData.userToken);
  const chartId = useSelector((state) => state.chart.chartData._id);
  const currentUser = useSelector((state) => state.user.userData);
  const [isHidden, setIsHidden] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(isNew ? cardData.cardData[0].value : cardData.cardData);

  function handleBeforeGetContent() {
    setIsPrint(true);
    return Promise.resolve();
  }

  const onPrintHandler = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `patient_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const clearAddNewHandler = () => {
    dispatch(setIsNewCard(false));
    dispatch(setNewCardType(''));
    dispatch(setNewCardData({}));
  };

  const onHideHandler = () => {
    setIsHidden(!isHidden);
  };

  const updateContentHandler = (content) => {
    setContent(content);
  };

  const onSubmitHandler = () => {
    setIsEdit(false);
    isNew
      ? dispatch(addCardToChart({ method: 'post', url: `card/add/${chartId}`, token, data: { cardType: cardData.cardType, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}`, cardData: content } }))
      : dispatch(updateCardInChart({ method: 'patch', url: `card/update/${chartId}?index=${cardIndex}`, token, data: { cardData: content } }));
    clearAddNewHandler();
  };

  const onEditHandler = () => {
    dispatch(setIsCardEdit(true));
    setIsEdit(true);
    clearAddNewHandler();
  };

  const onCloseModalHandler = () => {
    dispatch(setIsCardEdit(false));
    setIsEdit(false);
    clearAddNewHandler();
  };

  return (
    <CardContainer>
      <CardHeaderBar title={title} createdBy={cardData.addedByName} createdDate={cardData.addedOn} isPrint={showPrint} onPrint={onPrintHandler} isHidden={isHidden} onHide={onHideHandler} onEdit={onEditHandler} />
      {isEdit || isNew ? (
        <EditCardModal onCloseModal={onCloseModalHandler} title={title} handleSubmit={onSubmitHandler}>
          <RichText data={content} isView={false} isEdit={true} isNew={false} onUpdateContent={updateContentHandler} />
        </EditCardModal>
      ) : (
        <>
          {isPrint ? (
            <div ref={componentRef}>
              <Printer title={printTitle} includeDoctor={includeDoctor}>
                <CardBodyContainer isHidden={isHidden}>
                  <RichText data={cardData.cardData} isView={true} isEdit={false} isNew={false} />
                </CardBodyContainer>
              </Printer>
            </div>
          ) : (
            <CardBodyContainer isHidden={isHidden}>
              <RichText data={cardData.cardData} isView={true} isEdit={false} isNew={false} />
            </CardBodyContainer>
          )}
        </>
      )}
    </CardContainer>
  );
};

export default GenericRichTextCard;
