import styled from 'styled-components/macro';

export const PatientsChartListContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.CardBorder};

  @media (max-width: 1100px) {
    width: 15%;
  }
`;

export const ChartListHeader = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  background-color: ${({ theme }) => theme.CardHeader};
`;

export const PatientChartListScrollableContainer = styled.div`
  overflow-y: auto;
`;
export const DateListContainer = styled.div`
  padding: 4px;
`;

export const DateList = styled.div`
  text-align: left;
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  padding: 10px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 5px solid ${(props) => (props.active ? 'orange' : props.theme.MainNav)};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;
