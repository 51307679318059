import styled, { keyframes } from 'styled-components/macro';

const loadEditContainer = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-10%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

export const FormContainer = styled.form``;

export const PatientDataContainer = styled.div`
  display: flex;
  animation-name: ${loadEditContainer};
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-iteration-count: initial;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TopBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const PatientIdContainer = styled.h3`
  display: block;
  margin: 3px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LeftColumnContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  border-right: 2px solid ${({ theme }) => theme.TopBar};

  @media (max-width: 768px) {
    width: 98%;
    border-right: none;
    padding: 2px;
    margin: auto;
  }
`;

export const RightColumnContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;

  @media (max-width: 768px) {
    width: 98%;
    padding: 2px;
    margin: auto;
  }
`;

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBorder};
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2px;
`;
export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  z-index: 10;
`;

export const ContactContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.CardBorder};
  border-top: none;
  width: 100%;
  padding: 21px 5px 5px 5px;
  margin-bottom: 5px;
  background-color: white;
`;

export const CardFormContainer = styled.div`
  width: 100%;
  margin: 0px 5px 5px 5px;
  border: 2px solid ${({ theme }) => theme.CardBorder};
  background-color: white;
`;

export const CardFormHeader = styled.div`
  background-color: ${({ theme }) => theme.CardBorder};
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
`;

export const CardFormInputContainer = styled.div`
  padding: 5px;
  margin: 0px;
`;

export const InputContainer = styled.div`
  margin: 3px;
  font-size: 0.9rem;
  display: flex;
  position: relative;

  & label {
    width: 35%;
    padding: 2px 5px;
  }

  & input {
    width: 65%;
    padding: 3px 7px;
    border: 1px solid ${(props) => (props.isError ? 'red' : 'black')};
    border-radius: 2px;
  }

  & select {
    width: 65%;
    padding: 2px 5px;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  color: red;
  font-size: 0.8rem;
  padding: 5px;
  right: 20px;
  top: -2px;
  border-radius: 5px 5px 0px 0px;
  animation: slide-down 0.5s;

  @keyframes slide-down {
    from {
      opacity: 1;
      max-height: 0px;
      padding: 0px 5px;
      top: 30px;
    }
    to {
      opacity: 1;
      max-height: 30px;
      padding: 5px;
      top: 4px;
    }
  }
`;
